import React from 'react';
import { Modal } from '@shopify/polaris';
import '../../assets/modal.css';
import { dummyImage } from '../../common/Helper';

export default function PreviewModal(props) {
    const { fields, open, product } = props;
    const productImage = product?.images[0]?.originalSrc;

    const titleStyle = () => {
        let style = {};
        if (fields.offerTitle) {
            style = {
                fontSize: props.mode === 'desktop' ? fields.offerTitleFontSize : fields.offerTitleMobileFontSize,
                color: props.mode === 'desktop' ? fields.offerTitleColor : fields.offerTitleMobileColor,
                textAlign: fields.titleTextAlign
            }
        }
        return style;
    };

    const descpStyle = () => {
        let style = {};
        if (fields.offerDescriptionDesktop) {
            style = {
                fontSize: props.mode === 'desktop' ? fields.offerDescriptionFontSize : fields.offerDescriptionMobileFontSize,
                color: props.mode === 'desktop' ? fields.offerDescriptionColor : fields.offerDescriptionMobileColor,
                lineHeight: props.mode === 'desktop' ? fields.offerDescriptionFontSize + 'px' : fields.offerDescriptionMobileFontSize + 'px',
                textAlign: fields.descpTextAlign
            }
        }
        return style;
    };

    const buttonStyle = () => {
        let style = {};
        if (fields.offerButtonText) {
            style = {
                backgroundColor: props.mode === 'desktop' ? fields.offerButtonColor : fields.offerButtonMobileColor,
                fontSize: props.mode === 'desktop' ? fields.offerButtonFontSize : fields.offerButtonMobileFontSize,
                borderColor: props.mode === 'desktop' ? fields.offerButtonColor : fields.offerButtonMobileColor,
                color: props.mode === 'desktop' ? fields.offerButtonTextColor : fields.offerButtonMobileTextColor
            }
        }
        return style;
    };

    const continueButtonStyle = () => {
        let style = {};
        if (fields.continueButtonText) {
            style = {
                fontSize: props.mode === 'desktop' ? fields.continueButtonFontSize : fields.continueButtonMobileFontSize,
                color: props.mode === 'desktop' ? fields.continueButtonTextColor : fields.continueButtonMobileTextColor
            }
        }
        if (fields.continueShadow === true && props.mode === 'desktop') {
            style.backgroundColor = fields.conitnueButtonColor;
            style.borderColor = fields.conitnueButtonColor;
        } else if (fields.continueShadowMobile === true && props.mode === 'mobile') {
            style.backgroundColor = fields.conitnueButtonMobileColor;
            style.borderColor = fields.conitnueButtonMobileColor;
        }
        return style;
    };

    return (
        <Modal
            open={open}
            onClose={props.handleModalChange}
        >
            <div className={props.mode === 'mobile' ? 'popup-mobile-preview' : ''}>
                <div className="cust-popu-outer">
                    <div className="cust-popup-inner">
                        <div className="cust-popup-head">
                            <h3 className={`apply-font-${props.mode === 'desktop' ? 'pcTitleFont' : 'mobileTitleFont'}`} style={titleStyle()}>
                                {fields.offerTitle}
                            </h3>
                            <p className={`apply-font-${props.mode === 'desktop' ? 'pcDespFont' : 'mobileDespFont'}`} style={descpStyle()}>
                                {props.mode === 'desktop' ? fields.offerDescriptionDesktop : fields.offerDescriptionMobile}
                            </p>
                        </div>
                        {fields.offerTriggerType === 'discount-code' &&
                            <div className="cust-popup-discount">Discount Code: {fields.discountCode}</div>
                        }
                        {fields.offerTriggerType === 'product' &&
                            <div className="cust-popup-body">
                                <div className="popup-image-box">
                                    <img src={productImage ? productImage : dummyImage} alt="product" />
                                </div>
                                <div className="popup-detail-box">
                                    {fields.showProductTitle && <h4>{product ? product.title : ''}</h4>}
                                    {fields.showProductPrice && <p>${product ? product?.variants[0]?.price : ''}</p>}
                                <button type="button" className={`cust-addtocart apply-font-${props.mode === 'desktop' ? 'pcBtnFont' : 'mobileBtnFont'}`} style={buttonStyle()}>
                                        {props.mode === 'desktop' ? fields.offerButtonText : fields.offerButtonMobileText}
                                    </button>
                                </div>
                            </div>
                        }
                        <div className="cust-popup-footer">
                            {((fields.continueShadow === true && props.mode === 'desktop') || (fields.continueShadowMobile === true && props.mode === 'mobile')) ? (
                                <button type="button" className={`cust-btn apply-font-${props.mode === 'desktop' ? 'pcFinalBtnFont' : 'mobileFinalBtnFont'}`} onClick={props.handleModalChange} style={continueButtonStyle()}>
                                    {props.mode === 'desktop' ? fields.continueButtonText : fields.continueButtonMobileText}
                                </button>
                            ) : (
                                    <span href="void(0);" className={`footer-text apply-font-${props.mode === 'desktop' ? 'pcFinalBtnFont' : 'mobileFinalBtnFont'}`} onClick={props.handleModalChange} style={continueButtonStyle()}>
                                        {props.mode === 'desktop' ? fields.continueButtonText : fields.continueButtonMobileText}
                                    </span>
                                )}
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
}
