import React, { useCallback, useState, useEffect } from 'react';
import { Card, Page, Layout, DisplayText, Stack, Heading, Button, ButtonGroup, Popover, Icon, DatePicker, Form, FormLayout, Select, TextField } from '@shopify/polaris';
import {
	CalendarMinor,
	RefreshMinor
} from '@shopify/polaris-icons';
import { format, subDays } from 'date-fns'
import TabHeader from '../../common/TabHeader';
import API from '../../service/Api';
import { useParams } from "react-router-dom";
import BackButton from '../../common/BackButton';
import { formatInTimezone, getStoreName } from '../../common/Helper';

const today = formatInTimezone(new Date());

const dateRanges = [
	{
		label: 'Today',
		value: 'today',
		start: today,
		end: today
	},
	{
		label: 'Yesterday',
		value: 'yesterday',
		start: subDays(today, 1),
		end: subDays(today, 1)
	},
	{
		label: 'Last 7 days',
		value: 'last7days',
		start: subDays(today, 7),
		end: subDays(today, 1)
	},
	{
		label: 'Last 30 days',
		value: 'last30days',
		dates: [subDays(today, 30), subDays(today, 1)],
		start: subDays(today, 30),
		end: subDays(today, 1)
	},
	{
		label: 'Custom',
		value: 'custom'
	},
];

export default function TabsExample() {
	const { id } = useParams();
	const shop = getStoreName();
	const [title, setTitle] = useState("");
	const [analytics, setAnalytics] = useState({
		click : 0,
		conversion: 0,
		view: 0
	});
	const [isLoading, setLoading] = useState(false);
	const [dateLabel, setDateLabel] = useState('Today');
	const [popoverActive, setPopoverActive] = useState(false);
	const [selectedDateRange, setDateRange] = useState('today');
	const [{ month, year }, setDate] = useState({
		month: (format(today, 'M') - 1),
		year: format(today, 'yyyy')
	});
	const [selectedDates, setSelectedDates] = useState({
		start: today,
		end: today,
	});
	const togglePopoverActive = useCallback(
		() => setPopoverActive((popoverActive) => !popoverActive),
		[],
	);
	const handleMonthChange = useCallback(
		(month, year) => setDate({ month, year }),
		[],
	);

	const getAnalytics = () => {
		setLoading(true);
		setPopoverActive(false);
		const dateSelected = dateRanges.find(x => x.value === selectedDateRange);
		setDateLabel(dateSelected.label);
		const startRange = format(new Date(selectedDates.start), 'yyyy-MM-dd');
		const endRange = format(new Date(selectedDates.end), 'yyyy-MM-dd');
		API.get(`analytics/fetch/${id}?start_range=${startRange} 00:00:02&end_range=${endRange} 23:59:59&upsell_type=incart-upsells`).then(response => {
			setAnalytics(response.data.data);
			setLoading(false);
		}).catch(err => {
			setLoading(false);
			console.log('err: ', err);
		});
	};

	useEffect(() => {
		const startRange = format(new Date(today), 'yyyy-MM-dd');
		const endRange = format(new Date(today), 'yyyy-MM-dd');
		API.get(`analytics/fetch/${id}?start_range=${startRange} 00:00:02&end_range=${endRange} 23:59:59&upsell_type=incart-upsells`).then(response => {
			setAnalytics(response.data.data);
			setLoading(false);
		}).catch(err => {
			setLoading(false);
			console.log('err: ', err);
		});
		// getAnalytics();
		if (id) {
			API.get(`incart-upsell/${id}`).then(response => { 
				setTitle(response.data.data.upsell.upsellName);
			}).catch(err => {
				console.log('err: ', err);
			});	
		}
	}, [id, shop]);

	const activator = (
		<ButtonGroup>
			<Button onClick={togglePopoverActive} loading={isLoading} icon={<Icon source={CalendarMinor} />}>
				{dateLabel}
			</Button>
			<Button primary icon={<Icon source={RefreshMinor} />} onClick={getAnalytics}>Refresh</Button>
		</ButtonGroup>
	);

	const handleDateRange = (value) => {
		setDateRange(value);
		if (value !== 'custom') {
			const range = dateRanges.find(x => x.value === value);
			setSelectedDates({
				start: range.start,
				end: range.end
			})
		}
	}

	return (
		<div className='edit-incart-upsells'>
			<TabHeader />
			<Page
				title={title}
				primaryAction={<BackButton disable={false} url="incart-upsells" />}
			>
				<div>
					<Popover
						active={popoverActive}
						activator={activator}
						preferredAlignment="left"
						onClose={togglePopoverActive}
					>
						<div className="date-range-wrapper">
							<Form>
								<FormLayout>
									<Select
										label="Date Range"
										options={dateRanges}
										value={selectedDateRange}
										onChange={handleDateRange}
									/>
									<FormLayout.Group condensed>
										<TextField
											label="Starting"
											type="text"
											value={format(new Date(selectedDates.start), 'yyyy-MM-dd')}
										/>
										<TextField
											label="Ending"
											type="text"
											value={format(new Date(selectedDates.end), 'yyyy-MM-dd')}
										/>
									</FormLayout.Group>
									{selectedDateRange === 'custom' &&
										<DatePicker
											month={month}
											year={year}
											onChange={setSelectedDates}
											onMonthChange={handleMonthChange}
											selected={selectedDates}
											disableDatesAfter={today}
											multiMonth
											allowRange
										/>}
									<hr />
									<Button primary onClick={() => getAnalytics()}>Apply</Button>
								</FormLayout>
							</Form>
						</div>
					</Popover>
				</div>
				<div className="m-t-30">
					<Layout>
						<Layout.Section oneThird>
							<Card>
								<Card.Section>
									<Stack>
										<Stack.Item fill>
											<Heading size="large">Views</Heading>
										</Stack.Item>
										<Stack.Item>
											<DisplayText size="large">{analytics.view}</DisplayText>
										</Stack.Item>
									</Stack>
								</Card.Section>
							</Card>
						</Layout.Section>
						<Layout.Section oneThird>
							<Card>
								<Card.Section>
									<Stack>
										<Stack.Item fill>
											<Heading size="large">Add to Cart</Heading>
										</Stack.Item>
										<Stack.Item>
											<DisplayText size="large">{analytics.click}</DisplayText>
										</Stack.Item>
									</Stack>
								</Card.Section>
							</Card>
						</Layout.Section>
						<Layout.Section oneThird>
							<Card>
								<Card.Section>
									<Stack>
										<Stack.Item fill>
											<Heading size="large">Conversions</Heading>
										</Stack.Item>
										<Stack.Item>
											<DisplayText size="large">{analytics.conversion}</DisplayText>
										</Stack.Item>
									</Stack>
								</Card.Section>
							</Card>
						</Layout.Section>
					</Layout>
				</div>
			</Page>
		</div>
	);
}