export const fontFamilyOptions = [
    { label: 'Roboto', value: 'roboto' },
    { label: 'Poppins', value: 'poppins' },
    { label: 'Open Sans', value: 'opensans' },
    { label: 'SF Pro', value: 'SF Pro Text' }
];

export const textAlignmentOptions = [
    { label: 'Left', value: 'left' },
    { label: 'Center', value: 'center' },
    { label: 'Right', value: 'right' },
];

export function colorWithoutHash (color) {
    return color?.replace('#', '');
}

export const barPointerOptions = [
    { label: 'Circle', value: 'circle' },
    { label: 'Icon', value: 'icon' },
    { label: 'None', value: 'none' },
];

export const offerTypeOptions = [
    { label: 'Cart Limit', value: 'cart-limit' },
    { label: 'Specific Product', value: 'specific-product' },
    // { label: 'Specific Collection', value: 'collection-only' },
];

export const TextPlacementOptions = [
    { label: 'Up', value: 'up' },
    { label: 'Middle', value: 'middle' },
    { label: 'Down', value: 'down' },
];

export const upsellPlacementOptions = [
    { label: 'Above Cart', value: 'above-items' },
    { label: 'Below Cart', value: 'below-items' },
    { label: 'Custom Position', value: 'custom-positon' },
];

export const cartBarOptions = [
    { label: 'Above Cart', value: 'above-items' },
    { label: 'Custom Position', value: 'custom-positon' },
];

export const buttonOptions = [
    { label: 'Add to Cart', value: 'Add To Cart' },
    { label: 'Buy Now', value: 'Buy Now' },
    { label: 'Add Today', value: 'Add Today' },
    { label: 'Save Today', value: 'Save Today' },
    { label: 'Give Me My Goodies', value: 'Give Me My Goodies' },
    { label: 'Other', value: 'other' },
];

export const productPlacementOptions = [
    { label: 'In CartBar', value: 'in-cartbar' },
    { label: 'Below Cart Items', value: 'below-cart-items' },
];

export function makingFields(object) {
    let payload = {};
    for (var key in object) {
        payload[key.replace('_meta', '')] = object[key];
    }
    return payload;
}

export function getPayload(object) {
    let payload = {};
    for (var key in object) {
        if (key === 'offerName') {
            payload['name'] = object[key];
        } else if (key === 'offerType') {
            payload['type'] = object[key];
        } else if (key === 'status' || key === 'name') {
            payload[key] = object[key];
        } else {
            if (key.search('FontFamily') >= 0) {
                payload[key + '_meta'] = object[key] ? object[key] : 'Open Sans';
            } else {
                payload[key + '_meta'] = object[key];
            }
        }
    }
    return payload;
};

export const titleCss = "{ color: #000; font-size:20px; }";
export const priceCss = "{ margin: 0px 10px; }";

export const recommendOptions = [
    { label: 'Recommendations', value: 'Recommendations' },
    { label: 'You Might Also Like', value: 'You Might Also Like' },
    { label: 'You May Also Like', value: 'You May Also Like' },
    { label: 'Add Best Sellers', value: 'Add Best Sellers' },
    { label: 'Other', value: 'other' },
];

export const dummyImage = require('../assets/dummy_image.jpg');

export const locationOptions = [
    { label: "Product Page - Below Add to cart", value: 'below-add-to-cart' },
    { label: 'Cart Page - Above Cart', value: 'above-cart' },
    { label: 'Cart Page - Below Cart', value: 'below-cart' },
    { label: 'Custom Position', value: 'custom-position' },
];

export const conditionOptions = [
    { label: 'Equal to', value: 'equal-to' },
    { label: 'Not Equal to', value: 'not-equal-to' },
    { label: 'Greater or Equal to', value: 'greater-equal-to' },
    { label: 'Less or Equal to', value: 'less-equal-to' },
];

export const typeOptions = [
    { label: 'Day', value: 'day' },
    { label: 'Date', value: 'date' },
    { label: 'Time', value: 'time' },
    // { label: 'Page', value: 'page' },
];

export const dayOptions = [
    { label: 'Monday', value: 'monday' },
    { label: 'Tuesday', value: 'tuesday' },
    { label: 'Wednesday', value: 'wednesday' },
    { label: 'Thursday', value: 'thursday' },
    { label: 'Friday', value: 'friday' },
    { label: 'Saturday', value: 'saturday' },
    { label: 'Sunday', value: 'sunday' },
];

export const pageOptions = [
    { label: 'Product Page', value: 'product-page' },
    { label: 'Cart Page', value: 'cart-page' },
];

export const stockOptions = [
    { label: 'In stock', value: 'in-stock' },
    { label: 'Out of stock', value: 'out-stock' },
];

export const badgePositionOptions = [
    { label: 'Top Left', value: 'top-left' },
    { label: 'Top Right', value: 'top-right' },
    { label: 'Bottom Left', value: 'bottom-left' },
    { label: 'Bottom Left', value: 'bottom-right' },
];

export const priceRoundingOptions = [
    { label: "Don't Round Prices", value: 'no-round' },
    { label: 'Round to Nearest Whole Number', value: 'nearest-round' },
    { label: 'Round to Nearest 10 cents', value: 'nearest-10-cents' },
];

export function formatInTimezone (date) {
    let usaTime = date.toLocaleString("en-US", {
        timeZone: "America/New_York"
    });
    return new Date(usaTime);
}

export const smartLabels = [
    ['{day}', 'Display todays name, e.g. if its Monday, it will display ‘Monday’'],
    ['{tomorrow}', 'Display tomorrow’s name, e.g. if its Monday, it will display ‘Tuesday’'],
    ['{day_after_tomorrow}', 'Display the day name of the day after tomorrow, e.g. if its monday, it will display ‘Wednesday’'],
    ['{time}', 'Display the current time, e.g. it will display ‘16:57’'],
    ['{date}', 'Display the current date, e.g. if date is 16-12-2020 it will display ‘December 16, 2020’'],
    ['{date format="d-m-y"} OR {date format="d/m/y"}', 'The {date} label also allows for more flexible settings such as ‘format’, e.g it will display ‘16-12-2020’ OR ‘16/12/2020’ '],
    ['{time_left time="20:00"}', 'To display the time left before 20:00, e.g. if time is 16:57, it will display 3 hours 3 mins'],
    ['{time_left date="31-12-2020"}', 'You can do the same for the time left till a date, e.g. if date is 17-12-2020, it will display ‘13 days 16 hours 53 mins’'],
    ['{time_left date="31-12-2020 10:00"}', 'You can also use date and time together for the time left a date with time, e.g. if date is 17-12-2020, it will display ‘14 days 2 hours 54 mins’'],
];

export const recommendationLocations = [
    { label: 'Above Products', value: 'top' },
    { label: 'Left of Products', value: 'left' },
];

export const recommendationPosition = [
    { label: 'Below Cart', value: 'below-cart' },
    { label: 'Below Cart Table', value: 'below-cart-table' },
    { label: 'Custom Position', value: 'custom-position' },
];

export const buttonSizeOptions = [
    { label: 'Large', value: 'large' },
    { label: 'Medium', value: 'medium' },
    { label: 'Small', value: 'small' },
];

export function redirectPage(path) {
    /* if (localStorage.getItem('storeName')) {
        shop = localStorage.getItem('storeName');
    } else {
    } */
    var shop = window.location.ancestorOrigins[0].replace('https://', '');
    window.top.location.href = `https://${shop}/admin/apps/cartboost-1/${path}`;
    // window.top.location.href = `/${path}`;
}

export function upgradePlans(shop) {
    window.location.href = `http://app.cartboost.io/cartboost/plans?shop=${shop}`;
}

export const animationOptions = [
    { label: 'No Animation', value: 'no-animation' },
    { label: 'Bounce', value: 'bounce' },
    { label: 'Flash', value: 'flash' },
    { label: 'Flip', value: 'flip' },
    { label: 'Head Shake', value: 'headShake' },
    { label: 'Heart Beat', value: 'heartBeat' },
    { label: 'Jello', value: 'jello' },
    { label: 'Pulse', value: 'pulse' },
    { label: 'Rubber Band', value: 'rubberBand' },
    { label: 'Swing', value: 'swing' },
    { label: 'Tada', value: 'tada' },
    { label: 'Wobble', value: 'wobble' },

];

export function getStoreName() {
    return window.location.ancestorOrigins[0].replace('https://', '');
}

export function HSVtoRGB(h, s, v) {
    let r, g, b, i, f, p, q, t
    if (arguments.length === 1) {
        s = h.s; 
        v = h.v; 
        h = h.h;
    }
    i = Math.floor(h * 6)
    f = h * 6 - i
    p = v * (1 - s)
    q = v * (1 - f * s)
    t = v * (1 - (1 - f) * s)
    switch (i % 6) {
        case 0:
            r = v; 
            g = t;
            b = p
            break
        case 1:
            r = q; 
            g = v; 
            b = p;
            break
        case 2:
            r = p; 
            g = v; 
            b = t;
            break
        case 3:
            r = p; 
            g = q; 
            b = v;
            break
        case 4:
            r = t; 
            g = p; 
            b = v;
            break
        case 5:
            r = v; 
            g = p; 
            b = q;
            break
        default: ;
    }
    const toHex = x => {
        const hex = Math.round(x * 255).toString(16)
        return hex.length === 1 ? '0' + hex : hex
    }
    return `#${toHex(r)}${toHex(g)}${toHex(b)}`
}

export function RGBtoHSV(hex) {
    if (hex) {
        let r = 0, g = 0, b = 0
        // 3 digits
        if (hex.length === 4) {
            r = '0x' + hex[1] + hex[1]
            g = '0x' + hex[2] + hex[2]
            b = '0x' + hex[3] + hex[3]
    
            // 6 digits
        } else if (hex.length === 7) {
            r = Number('0x' + hex[1] + hex[2])
            g = Number('0x' + hex[3] + hex[4])
            b = Number('0x' + hex[5] + hex[6])
        }
    
        let rabs, gabs, babs, rr, gg, bb, h, s, v, diff, diffc;
        rabs = r / 255;
        gabs = g / 255;
        babs = b / 255;
        v = Math.max(rabs, gabs, babs)
        diff = v - Math.min(rabs, gabs, babs)
        diffc = c => (v - c) / 6 / diff + 1 / 2
        if (diff === 0) {
            h = s = 0;
        } else {
            s = diff / v;
            rr = diffc(rabs);
            gg = diffc(gabs);
            bb = diffc(babs);
    
            if (rabs === v) {
                h = bb - gg;
            } else if (gabs === v) {
                h = (1 / 3) + rr - bb;
            } else if (babs === v) {
                h = (2 / 3) + gg - rr;
            }
            if (h < 0) {
                h += 1;
            } else if (h > 1) {
                h -= 1;
            }
        }
        return {
            hue: Math.round(h * 360),
            saturation: s,
            brightness: v,
            alpha: 0
        }
    }
}