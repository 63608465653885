import React from 'react'

export default function RequiredError({ message }) {
    return (
        <div id="PolarisTextField1Error" className="Polaris-InlineError">
            <div className="Polaris-InlineError__Icon">
                <span className="Polaris-Icon">
                    <svg viewBox="0 0 20 20" className="Polaris-Icon__Svg" focusable="false" aria-hidden="true">
                        <path d="M10 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16zm-1-8h2V6H9v4zm0 4h2v-2H9v2z" fillRule="evenodd">
                        </path>
                    </svg>
                </span>
            </div>{message ? message : 'This is required field'}
        </div>
    )
}
