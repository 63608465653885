import React, { useState, createContext, useContext } from 'react'

const GlobalPlanContext = createContext()
const GlobalPlanActionsContext = createContext()

/* eslint-disable */
const useContextFactory = (name, context) => {
    return () => {
        const ctx = useContext(context)
        if (ctx === undefined) {
            throw new Error(`use${name}Context must be used withing a ${name}ContextProvider.`)
        }
        return ctx
    }
}
/* eslint-enable */

export const useGlobalPlanContext = useContextFactory('GlobalPlanContext', GlobalPlanContext)
export const useGlobalPlanActionsContext = useContextFactory('GlobalPlanActionsContext', GlobalPlanActionsContext)

const GlobalPlanContextProvider = (props) => {
    const [isGlobalPlan, setGlobalPlan] = useState(null);
    return (
        <GlobalPlanContext.Provider value={isGlobalPlan}>
            <GlobalPlanActionsContext.Provider value={setGlobalPlan}>
                {props.children}
            </GlobalPlanActionsContext.Provider>
        </GlobalPlanContext.Provider>
    )
}

export default GlobalPlanContextProvider


