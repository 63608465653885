import AppMain from './component/cartbar/main.js';
/* import AppEditShippingOffer from './component/cartbar/shipping-offer.js';
import AppEditCouponOffer from './component/cartbar/coupon-offer.js';
import AppEditProductOffer from './component/cartbar/product-offer.js'; */
import CartBarOffer from './component/cartbar/cartbar.js';
import AppIncartUpsells from './component/incartUpsells/main.js';
import AppAddIncartUpsell from './component/incartUpsells/incart-upsell.js';
import CartbarAnalytics from './component/cartbar/analytics.js';
import IncartAnalytics from './component/incartUpsells/analytics.js';
import PopupAnalytics from './component/popupUpsells/analytics.js';
import AppFaqs from './component/faqs.js';
import PopupUpsells from './component/popupUpsells/main.js';
import PopupManage from './component/popupUpsells/popup-manage';
import Recommendations from './component/recommendation/main';
import Recommendation from './component/recommendation/recommendation';
import Sales from './component/salesboost/index';
import NewSale from './component/salesboost/new-sale';
import Timers from './component/timerboost/index';
import NewTimer from './component/timerboost/new-timer';
import HomeScreen from './component/home/index';
import AllAnalytics from './component/home/analytics';
import RecommendAnalytics from './component/recommendation/analytics';
import TrustBadgeIndex from './component/trustBadge/index';
import BadgeCreate from './component/trustBadge/trust-badge';
import AllBoostBar from './component/boostbar/main';
import BoostBar from './component/boostbar/boostbar';

export default [
    {
        path: '/',
        exact: true,
        component: HomeScreen,
        authL: false
    },
    {
        path: '/cartbar-upsells',
        exact: true,
        component: AppMain,
        authL: true,
        name: "CartBar Upsells"
    },
    {
        path: '/add-shipping-offer',
        exact: true,
        component: CartBarOffer,
        authL: true,
        name: "CartBar Upsells"
    },
    {
        path: '/add-coupon-offer',
        exact: true,
        component: CartBarOffer,
        authL: true,
        name: "CartBar Upsells"
    },
    {
        path: '/add-product-offer',
        exact: true,
        component: CartBarOffer,
        authL: true,
        name: "CartBar Upsells"
    },
    {
        path: '/edit-shipping-offer/:id',
        exact: true,
        component: CartBarOffer,
        authL: true,
        name: "CartBar Upsells"
    },
    {
        path: '/edit-coupon-offer/:id',
        exact: true,
        component: CartBarOffer,
        authL: true,
        name: "CartBar Upsells"
    },
    {
        path: '/edit-product-offer/:id',
        exact: true,
        component: CartBarOffer,
        authL: true,
        name: "CartBar Upsells"
    },

    {
        path: '/cartbar-analytics/:id',
        exact: true,
        component: CartbarAnalytics,
        authL: true,
        name: "CartBar Upsells"
    },
    {
        path: '/incart-upsells',
        exact: true,
        component: AppIncartUpsells,
        authL: true,
        name: "Incart Upsells"
    },
    {
        path: '/incart-analytics/:id',
        exact: true,
        component: IncartAnalytics,
        authL: true,
        name: "Incart Upsells"
    },
    {
        path: '/create-incart',
        exact: true,
        component: AppAddIncartUpsell,
        authL: true,
        name: "Incart Upsells"
    },
    {
        path: '/edit-incart/:id',
        exact: true,
        component: AppAddIncartUpsell,
        authL: true,
        name: "Incart Upsells"
    },
    {
        path: '/faqs',
        exact: true,
        component: AppFaqs,
        authL: false
    },
    {
        path: '/popup-upsells',
        exact: true,
        component: PopupUpsells,
        authL: true,
        name: "Popup Upsells"
    },
    {
        path: '/popup-upsells/create',
        exact: true,
        component: PopupManage,
        authL: true,
        name: "Popup Upsells"
    },
    {
        path: '/popup-upsells/edit/:id',
        exact: true,
        component: PopupManage,
        authL: true,
        name: "Popup Upsells"
    },
    {
        path: '/popup-analytics/:id',
        exact: true,
        component: PopupAnalytics,
        authL: true,
        name: "Popup Upsells"
    },
    {
        path: '/recommendations',
        exact: true,
        component: Recommendations,
        authL: true,
        name: "Recommendations"
    },
    {
        path: '/create-recommendation',
        exact: true,
        component: Recommendation,
        authL: true,
        name: "Recommendations"
    },

    {
        path: '/edit-recommendation/:id',
        exact: true,
        component: Recommendation,
        authL: true,
        name: "Recommendations"
    },
    {
        path: '/recommendation-analytics/:id',
        exact: true,
        component: RecommendAnalytics,
        authL: true,
        name: "Recommendations"
    },
    {
        path: '/sales',
        exact: true,
        component: Sales,
        authL: true,
        name: "SalesBoost"
    },
    {
        path: '/create-sale',
        exact: true,
        component: NewSale,
        authL: true,
        name: "SalesBoost"
    },
    {
        path: '/edit-sale/:id',
        exact: true,
        component: NewSale,
        authL: true,
        name: "SalesBoost"
    },
    {
        path: '/timers',
        exact: true,
        component: Timers,
        authL: true,
        name: "TimerBoost"
    },
    {
        path: '/create-timer',
        exact: true,
        component: NewTimer,
        authL: true,
        name: "TimerBoost"
    },
    {
        path: '/edit-timer/:id',
        exact: true,
        component: NewTimer,
        authL: true,
        name: "TimerBoost"
    },
    {
        path: '/home',
        exact: true,
        component: HomeScreen,
        authL: false
    },
    {
        path: '/analytics',
        exact: true,
        component: AllAnalytics,
        authL: true,
        name: "Analytics"
    },
    {
        path: '/trust-badge',
        exact: true,
        component: TrustBadgeIndex,
        authL: true,
        name: "Trust Badge"
    },
    {
        path: '/create-badge',
        exact: true,
        component: BadgeCreate,
        authL: true,
        name: "Trust Badge"
    },
    {
        path: '/edit-badge/:id',
        exact: true,
        component: BadgeCreate,
        authL: true,
        name: "Trust Badge"
    },
    {
        path: '/boost-bar',
        exact: true,
        component: AllBoostBar,
        authL: true,
        name: "BoostBar"
    },
    {
        path: '/create-boost-bar',
        exact: false,
        component: BoostBar,
        authL: true,
        name: "BoostBar"
    },
    {
        path: '/edit-boost-bar/:id',
        exact: false,
        component: BoostBar,
        authL: true,
        name: "BoostBar"
    }
];