import React, { useEffect, useState } from 'react';
import { ResourcePicker } from '@shopify/app-bridge-react';

export default function ItemPicker(props) {
    const [type, setType] = useState('Product');
    useEffect(() => {
        setType(props.resourceType);
    }, [props])

    const initialSelectedIds = props.selectedItem && props.selectedItem.length ? props.selectedItem : [];
    
    return (
        <ResourcePicker
            resourceType={type}
            showVariants={false}
            open={props.openPicker}
            showDraft={false}
            // allowMultiple={props.allowMultiple ? props.allowMultiple : false}
            selectMultiple={props.allowMultiple ? props.allowMultiple : false}
            onSelection={(resources) => props.handleItemSelection(resources)}
            onCancel={() => props.setOpenPicker(false)}
            initialSelectionIds={initialSelectedIds}
        />
    )
}
