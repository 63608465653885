import React from 'react';
import { titleCss, priceCss } from './Helper';
import { TextStyle } from '@shopify/polaris';

export default function CssHelper() {
    return (
        <div className="m-t-40">
            <p>Enter the CSS selector(s) you'd like to target, as displayed below.</p>
            <p><TextStyle variation="code"> .cart-product__title {titleCss} </TextStyle></p>
            <p><TextStyle variation="code"> .cart-product__Price {priceCss} </TextStyle></p>
        </div>
    )
}
