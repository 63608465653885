import React, { useEffect, useState } from 'react';
import { Icon, Card, Link, FormLayout } from '@shopify/polaris';
import { redirectPage, upgradePlans } from './Helper';
import {
    CartMajorMonotone,
    QuickSaleMajorMonotone,
    DuplicateMinor,
    ThumbsUpMajorMonotone,
    CartUpMajorMonotone,
    ClockMajorMonotone,
    QuestionMarkMajorMonotone,
    AnalyticsMajorMonotone,
    LockMajorMonotone,
    ConfettiMajorMonotone,
    CashDollarMajorMonotone
} from "@shopify/polaris-icons";
// import { useLocation } from 'react-router-dom';
import Loader from './Loader';
import { useGlobalPlanContext } from '../context/GlobalContext';
import { getStoreName } from './Helper';

// const queryString = require('query-string');

/* const trustBadgeIcon = <span className="Polaris-Icon">
    <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" className="trustBadge"><path fillRule="evenodd" d="M9.131.233c-2.37 1.383-5.37 2.33-7.634 2.646-.822.115-1.495.79-1.494 1.62l.002.497c-.03 6.043.477 11.332 9.462 14.903a1.45 1.45 0 001.062 0C19.52 16.328 20.032 11.04 20 4.996v-.501c0-.828-.673-1.51-1.491-1.638-2.149-.337-5.282-1.274-7.65-2.628A1.733 1.733 0 009.13.233zm4.577 8.478a1 1 0 00-1.414-1.415L9.001 10.59 7.708 9.297a1 1 0 10-1.414 1.414l2 2.001a1 1 0 001.414 0l4-4.001z" /></svg>
</span>; */

const tabs = [
    {
        id: 'cart-bar-upsells',
        name: 'CartBar Upsells',
        icon: <Icon source={CartMajorMonotone} />,
        url: 'cartbar-upsells',
        auth: true
    },
    {
        id: 'incart-upsells',
        name: 'Incart Upsells',
        icon: <Icon source={QuickSaleMajorMonotone} />,
        url: 'incart-upsells',
        auth: true
    },
    {
        id: 'popup-upsells',
        name: 'Popup Upsells',
        icon: <Icon source={DuplicateMinor} />,
        panelID: 'popup-upsells-content',
        url: 'popup-upsells',
        auth: true
    },
    {
        id: 'recommendations-upsells',
        name: 'Recommendations',
        icon: <Icon source={ThumbsUpMajorMonotone} />,
        panelID: 'recommendations-upsells-content',
        url: 'recommendations',
        auth: true
    },
    {
        id: 'sales-upsells',
        name: 'SalesBoost',
        icon: <Icon source={CartUpMajorMonotone} />,
        panelID: 'sales-upsells-content',
        url: 'sales',
        auth: true
    },
    {
        id: 'timer-upsells',
        name: 'TimerBoost',
        icon: <Icon source={ClockMajorMonotone} />,
        panelID: 'timer-upsells-content',
        url: 'timers',
        auth: true
    },
    /* {
        id: 'trust-badge',
        name: 'Trust Badge',
        icon: trustBadgeIcon,
        panelID: 'trust-badge-content',
        url: 'trust-badge',
        auth: true
    }, */
    {
        id: 'boost-bar',
        name: 'BoostBar',
        icon: <Icon source={ConfettiMajorMonotone} />,
        panelID: 'boost-bar-content',
        url: 'boost-bar',
        auth: true
    },
    {
        id: 'analytics',
        name: 'Analytics',
        icon: <Icon source={AnalyticsMajorMonotone} />,
        panelID: 'timer-upsells-content',
        url: 'analytics',
        auth: true
    },
    {
        id: 'faqs',
        name: 'Faqs',
        icon: <Icon source={QuestionMarkMajorMonotone} />,
        panelID: 'faqs-content',
        url: 'faqs',
        auth: false
    },
    {
        id: 'plan-bar',
        name: 'Plans',
        icon: <Icon source={CashDollarMajorMonotone} />,
        panelID: 'plan-bar-content',
        url: 'plan-bar',
        auth: false
    },
];

export default function Menu(props) {

    // const location = useLocation();
    // const params = queryString.parse(location.search);
    const [offerMenus, setOfferMenus] = useState([]);
    const plan = useGlobalPlanContext();
    const shop = getStoreName();
    /* let shop = '';
    if (params.shop) {
        shop = params.shop;
    } else {
        shop = localStorage.getItem('storeName');
    } */

    useEffect(() => {
        if (plan) {
            setOfferMenus(JSON.parse(plan.menus));
        }
    }, [plan]);

    if (offerMenus.length > 0) {

        return (
            <>
                {
                    props.screen === "desktop" ? (
                        <div className="icons-area">
                            <FormLayout>
                                <FormLayout.Group>
                                    {tabs.map((tab, index) => {
                                        const menu_access = tab.auth === false ? (tab.name === "Plans" ? -1 : 1) : offerMenus.indexOf(tab.name);
                                        return (
                                            <Link onClick={e => menu_access === -1 ? upgradePlans(shop) : redirectPage(tab.url)} key={index}>
                                                <Card>
                                                    {tab.icon}
                                                    <div className="inner-blk">{tab.name}</div>
                                                    {menu_access === -1 && tab.name !== "Plans" &&
                                                        <div className="upgrade-plan">
                                                            <div className="upgrade-plan-content">
                                                                <Icon source={LockMajorMonotone} />
                                                            Upgrade
                                                        </div>
                                                        </div>
                                                    }
                                                </Card>
                                            </Link>
                                        )
                                    })}
                                    {/* given two blanks tag to make equal space */}
                                    <></>
                                    <></>
                                </FormLayout.Group>
                            </FormLayout>
                        </div>
                    ) : (
                        <div className="Menu-Links">
                            <ul>
                                {tabs.map((tab, index) => {
                                    const menu_access = tab.auth === false ? (tab.name === "Plans" ? -1 : 1) : offerMenus.indexOf(tab.name);
                                    return (
                                        <li key={tab.id} className={props.selected === index ? 'active' : ''}>
                                            <Link onClick={e => menu_access === -1 ? upgradePlans(shop) : redirectPage(tab.url)}>
                                                <span>
                                                    {tab.icon}{tab.name}
                                                </span>
                                                {menu_access === -1 && tab.name !== "Plans" &&
                                                    <div className="mobile-upgrade-plan">
                                                        <div className="upgrade-plan-content">
                                                            <Icon source={LockMajorMonotone} />
                                                            Upgrade
                                                        </div>
                                                    </div>
                                                }
                                            </Link>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    )
                }
            </>
        )
    }
    return (
        <Loader />
    )

}
