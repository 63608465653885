import React, { useCallback, useState, useEffect } from 'react';
import { Popover, ColorPicker } from '@shopify/polaris';
import { HSVtoRGB, RGBtoHSV } from './Helper';

function ColorChoser(props) {
    const [popoverActive, setPopoverActive] = useState(false);
    const [hexColor, setHexColor] = useState(props.color);

    useEffect(() => {
        setHexColor(props.color ?? '#ffffff')
    }, [props]);

    const handleChange = (newValue) => {
        const newColor = HSVtoRGB(newValue.hue / 360, newValue.saturation, newValue.brightness);
        setHexColor(newColor);
        props.handleColorInput(newColor, props.field);
    };

    const togglePopoverActive = useCallback(
        () => setPopoverActive((popoverActive) => !popoverActive),
        [],
    );

    const activator = (
        <div className="" onClick={togglePopoverActive}>
            <span color={hexColor} className="color-box" style={{ backgroundColor: hexColor }}></span>
            <span className="arrow-down"></span>
        </div>
    );


    return (
        <div className="color-box-wrapper">
            <Popover
                active={popoverActive}
                activator={activator}
                onClose={togglePopoverActive}
            >
                <ColorPicker onChange={handleChange} color={RGBtoHSV(hexColor)} />
            </Popover>
        </div>
    );
}

export default ColorChoser;