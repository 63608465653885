export const PaymentIcons = [
    "pf-visa",
    "pf-visa-debit",
    "pf-visa-electron",
    "pf-visa-pay",
    "pf-mastercard",
    "pf-mastercard-alt",
    "pf-mastercard-securecode",
    "pf-american-express",
    "pf-american-express-alt",
    "pf-paypal",
    "pf-paypal-alt",
    "pf-interac",
    "pf-interac-alt",
    "pf-shopify",
    "pf-apple-pay",
    "pf-google-wallet",
    "pf-google-wallet-alt",
    "pf-square",
    "pf-stripe",
    "pf-amazon-pay",
    "pf-amazon-pay-alt",
    "pf-bitcoin",
    "pf-jcb",
    "pf-discover",
    "pf-diners",
    "pf-diners-alt",
    "pf-alipay",
    "pf-bancontact-mister-cash",
    "pf-bank-transfer",
    "pf-bankomat",
    "pf-braintree",
    "pf-carta-si",
    "pf-cash-on-delivery",
    "pf-cash-on-pickup",
    "pf-cashcloud",
    "pf-cb",
    "pf-clickandbuy",
    "pf-dankort",
    "pf-direct-debit",
    "pf-ec",
    "pf-elo",
    "pf-elo-alt",
    "pf-eps",
    "pf-eth",
    "pf-facture",
    "pf-fattura",
    "pf-flattr",
    "pf-giropay",
    "pf-gratipay",
    "pf-gratipay-sign",
    "pf-hipercard",
    "pf-ideal",
    "pf-invoice",
    "pf-klarna",
    "pf-mercado-pago",
    "pf-mercado-pago-sign",
    "pf-moip",
    "pf-multibanco",
    "pf-ogone",
    "pf-pagseguro",
    "pf-paybox",
    "pf-paylife",
    "pf-paymill",
    "pf-paysafecard",
    "pf-payshop",
    "pf-payu",
    "pf-postepay",
    "pf-quick",
    "pf-rechnung",
    "pf-ripple",
    "pf-sage",
    "pf-sepa",
    "pf-six",
    "pf-skrill",
    "pf-skrill-alt",
    "pf-sodexo",
    "pf-sofort",
    "pf-truste",
    "pf-unionpay",
    "pf-venmo",
    "pf-verified-by-visa",
    "pf-verisign",
    "pf-wechat-pay",
    "pf-western-union",
    "pf-western-union-alt",
    "pf-wirecard",
];

export const advanceBadgeImage = [
    {
        id: '1a',
        name: 'badges-red-free-return',
        src: 'https://app.cartboostapp.com/dev-cartboost/public/images/badges/badges-red-free-return.svg'
    },
    {
        id: '2a',
        name: 'badges-red-free-shipping',
        src: 'https://app.cartboostapp.com/dev-cartboost/public/images/badges/badges-red-free-shipping.svg'
    },
    {
        id: '3a',
        name: 'badges-red-money-back-guarantee-30days',
        src: 'https://app.cartboostapp.com/dev-cartboost/public/images/badges/badges-red-money-back-guarantee-30days.svg'
    },
    {
        id: '4a',
        name: 'badges-red-money-back-guarantee',
        src: 'https://app.cartboostapp.com/dev-cartboost/public/images/badges/badges-red-money-back-guarantee.svg'
    },
    {
        id: '5a',
        name: 'badges-yellow-best-choice',
        src: 'https://app.cartboostapp.com/dev-cartboost/public/images/badges/badges-yellow-best-choice.svg'
    },
    {
        id: '6a',
        name: 'badges-yellow-free-return',
        src: 'https://app.cartboostapp.com/dev-cartboost/public/images/badges/badges-yellow-free-return.svg'
    },
    {
        id: '7a',
        name: 'badges-yellow-free-shipping',
        src: 'https://app.cartboostapp.com/dev-cartboost/public/images/badges/badges-yellow-free-shipping.svg'
    },
    {
        id: '8a',
        name: 'badges-yellow-money-back-guarantee-30days',
        src: 'https://app.cartboostapp.com/dev-cartboost/public/images/badges/badges-yellow-money-back-guarantee-30days.svg'
    },
];