import React from 'react';
import { Card, SkeletonBodyText } from '@shopify/polaris';

export default function Loader() {
    return (
        <Card>
            <Card.Section>
                <SkeletonBodyText />
            </Card.Section>
        </Card>
    )
}
