import React from 'react';
import { EmptyState } from '@shopify/polaris';
import { redirectPage } from '../common/Helper';

function EmptyOffer({ url, buttonText }) {
    return (
        <div className={buttonText === 'Create Upsell' ? 'hlanding_pagetwo' : 'hlanding_page'}>
            <EmptyState
                heading="Boost Your Cart"
                action={{ content: buttonText, onAction: e => redirectPage(url) }}
            >
                <p>Conversions and Sales!</p>
                <p>Cartboost helps you boost your cart conversions and</p>
                <p>Aov (Average order value)</p>
            </EmptyState>
        </div>
    );
}
export default EmptyOffer;
