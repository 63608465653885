import React from 'react';
import { Button, Icon } from '@shopify/polaris';
import { ArrowLeftMinor } from '@shopify/polaris-icons';
import { redirectPage } from '../common/Helper';

export default function BackButton({ disable, url }) {
    if (disable) {
        return (
            <Button primary disabled icon={<Icon source={ArrowLeftMinor} />}>
                Back
            </Button>
        )
    }
    return (
        <Button primary icon={<Icon source={ArrowLeftMinor} />} onClick={e => redirectPage(url)}>
            Back
        </Button>
    )
}
