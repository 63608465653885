import React, { useState, useEffect } from 'react';
import { RangeSlider, Select, TextField, Form, FormLayout, Button, Page, Link, Checkbox, Card, ResourceList, Stack, Thumbnail } from '@shopify/polaris';
import ColorChoser from '../../common/Color';
import TabHeader from '../../common/TabHeader';
import { colorWithoutHash, barPointerOptions, cartBarOptions, getPayload, makingFields, redirectPage, textAlignmentOptions, productPlacementOptions, dummyImage, getStoreName } from '../../common/Helper';
import API from '../../service/Api';
import Tooltip from '../../common/Tooltip';
import { useParams, useLocation } from "react-router-dom";
import ProgressBar from '../../common/ProgressBar';
import CustomPlacement from '../../common/CustomPlacement';
import BackButton from '../../common/BackButton';
import TabSettings from '../../common/TabSettings';
import FontFamilyPicker from '../../common/FontPicker';
import CssHelper from '../../common/CssHelper';
import RequiredError from '../../common/RequiredError';
import ItemPicker from '../../common/ItemPicker';

let initialStates = {
    status: 0,
    offerName: '',
    cartLimit: '100',
    cartBarOfferText: '{ValueNeeded} away from FREE Shipping',
    cartBarCompletionText: 'Congratulations! You qualify for Free Shipping',
    cartBarTextColor: '#000000',
    cartBarBgColor: '#1f6ef5',
    cartBarFontSize: '15',
    cartBarFontFamily: '',
    cartBarTextAlign: 'center',
    cartBarTextBackground: false,
    cartBarShadow: false,
    enableBoostBar: false,
    enableBoostBarSiteWide: false,
    boostBarSiteWideMessage: 'Get FREE Delivery',
    boostBarTextColor: '#ffffff',
    boostBarBgColor: '#000000',
    boostBarFontSize: '15',
    boostBarFontFamily: '',
    boostBarTextAlign: 'center',
    barRangeValue: 50,
    barBaseColor: '#e0e0de',
    barSliderColor: '#5c6ac4',
    barThickness: '5',
    barPointer: 'none',
    barPointerColor: '#ffffff',
    enableCustomCss: false,
    customCss: '',
    cartBarPlacement: 'above-items',
    ajaxCart: false,
    hideCartbarPrices: false,
    hideCartbarValuePrice: false,
    cartBarSquare: false,
    cartBarMobileTextColor: '#000000',
    cartBarMobileBgColor: '#1f6ef5',
    cartBarMobileFontSize: '15',
    cartBarMobileFontFamily: '',
    cartBarMobileTextAlign: 'center',
    discountCode: '',
    productPlacement: 'in-cartbar',
};


export default function ShippingOffer() {

    const shop = getStoreName();
    const { id } = useParams();
    const location = useLocation();
    const [title, setTitle] = useState("Create New Shipping CartBar");
    const [fields, setFields] = useState(initialStates);
    const [disableBackButton, setDisableBackButton] = useState(false);

    const [inputErrors, setInputErrors] = useState({
        offerNameError: null,
        offerDecpError: null,
        discountError: null,
        cartLimitError: null
    });

    const [isLoading, setLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [toolTip, setTooltip] = useState({
        active: false,
        content: null,
        error: false
    });
    const [tabIndex, setTabIndex] = useState(0);
    const [cartBarType, setCartBarType] = useState("shipping");
    const [openPicker, setOpenPicker] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [requiredProduct, setRequiredProduct] = useState(null);
    /**get data from db */
    useEffect(() => {
        if (location) {
            if (location.pathname.search('coupon') >= 0) {
                setCartBarType("coupon");
                setTitle("Create New Coupon CartBar");
                initialStates.cartBarOfferText = "{ValueNeeded} away from FREE Coupon";
                initialStates.cartBarCompletionText = "Congratulations! You qualify for Free Coupon";
                setFields(initialStates);
            } else if (location.pathname.search('product') >= 0) {
                setCartBarType("product");
                setTitle("Create New Product CartBar");
                initialStates.cartBarOfferText = "{ValueNeeded} away from FREE Product";
                initialStates.cartBarCompletionText = "Congratulations! You qualify for Free Product";
                setFields(initialStates);
            }
        }
        // get data for specific id
        if (id) {
            API.get(`bar-upsell/${id}`).then(response => {
                const barOffer = response.data.data.baroffer;
                const barOfferMeta = JSON.parse(response.data.data.baroffer.barOptions);
                setTitle(barOffer.offerName);
                let dbFields = makingFields(barOfferMeta);
                dbFields.offerName = barOffer.offerName;
                dbFields.barRangeValue = 50;
                dbFields.status = barOffer.offerStatus;
                //make initialStates with db values
                initialStates = dbFields;
                setFields(dbFields);
                if (barOfferMeta.product_meta) {
                    setSelectedItem([{
                        id: barOfferMeta.product_meta.id,
                        variants: [{
                            id: barOfferMeta.product_meta.variants[0].id,
                        }],
                    }]);
                    setRequiredProduct(barOfferMeta.product_meta);
                }
            }).catch(err => {
                setTooltip({
                    content: err.response.data.message,
                    active: true,
                    error: true
                });
            });
        }
    }, [id, shop, location]);

    /** Disable back button if form is edited */
    useEffect(() => {
        if (JSON.stringify(fields) === JSON.stringify(initialStates)) {
            setDisableBackButton(false);
        } else {
            setDisableBackButton(true);
        }
    }, [fields]);

    const handleInputChange = (inputValue, inputName) => {
        setFields({
            ...fields,
            [inputName]: inputValue
        });
    };

    const handleColorChange = (color, inputName) => {
        const value = color.search('#') >= 0 ? color : '#' + color;
        setFields({
            ...fields,
            [inputName]: value
        });
    };

    const handleValidation = (data) => {
        let errors = {};
        if (data.offerName.trim() === "") {
            errors.offerNameError = "This is required field";
        }
        if (data.cartLimit === "" || data.cartLimit === null) {
            errors.cartLimitError = "This is required field";
        } else if (Number(data.cartLimit) < 0) {
            errors.cartLimitError = "This value can not be less than 0";
        }
        if (cartBarType === 'coupon' && data.discountCode.trim() === "") {
            errors.discountError = "This is required field";
        }
        if (cartBarType === 'product' && !requiredProduct) {
            errors.productError = "This is required field";
        }
        setInputErrors(errors);
        return errors;
    };

    const handleItemSelection = (resources) => {
        const idFromResources = resources.selection.map((item) => item.id);
        if (idFromResources.length) {
            setSelectedItem([{
                id: idFromResources[0]
            }]);
            setRequiredProduct(resources.selection[0]);
            if (disableBackButton === false) {
                setDisableBackButton(true);
            }
        } else {
            setSelectedItem(null);
            setRequiredProduct(null);
            if (JSON.stringify(fields) === JSON.stringify(initialStates)) {
                setDisableBackButton(false);
            }
        }
        setOpenPicker(false);
        setInputErrors({
            ...inputErrors,
            productError: null
        });
    };

    const handleSubmit = () => {
        let checkValidation = handleValidation(fields);
        if (Object.keys(checkValidation).length === 0) {
            setLoading(true);
            let payload = getPayload(fields);
            payload.shop = shop;
            payload.type = cartBarType === 'shipping' ? 'free-shipping' : (cartBarType === 'coupon' ? 'free-coupon' : 'free-product');
            if (cartBarType === 'product') {
                payload.product_meta = requiredProduct;
            }
            const url = id ? `bar-upsell/${id}` : `bar-upsell/create`;
            API.post(url, payload).then(response => {
                setLoading(false);
                setTooltip({
                    content: `Cartbar Upsell ${id ? 'Updated' : 'Created'} Successfully`,
                    active: true,
                    error: false
                });
                redirectPage('cartbar-upsells');
            }).catch(err => {
                setLoading(false);
                setTooltip({
                    content: err.response.data.message,
                    active: true,
                    error: true
                });
            });
        } else {
            setTooltip({
                content: "Please fill required fields",
                active: true,
                error: true
            });
        }
    };

    const deleteOffer = () => {
        setDeleteLoading(true);
        API.delete(`bar-upsell/${id}`).then(response => {
            setDeleteLoading(false);
            setTooltip({
                content: "Cartbar Upsell Deleted Successfully",
                active: true,
                error: false
            });
            redirectPage('cartbar-upsells');
        }).catch(err => {
            setDeleteLoading(false);
            setTooltip({
                content: err.response.data.message,
                active: true,
                error: true
            });
        });
    };

    const handleTabIndex = (index) => {
        setTabIndex(index);
    };

    return (
        <div className='edit-coupon-ofr'>
            <TabHeader />
            <Page
                title={title}
                primaryAction={<BackButton disable={disableBackButton} url="cartbar-upsells" />}
            >
                <ProgressBar title="CartBar Preview" fields={fields} handleBarInput={handleInputChange} />
                <Form noValidate onSubmit={handleSubmit}>
                    {/* Just added all fonts here so that all fonts can be loaded for preview */}
                    <div style={{display:'none'}}>
                        <FontFamilyPicker
                            pickerId="main"
                            label="CartBar Font Family:"
                            activeFont={fields.cartBarFontFamily}
                            field="cartBarFontFamily"
                            handleFontFamilyInput={handleInputChange}
                        />
                        <FontFamilyPicker
                            pickerId="mobileFont"
                            label="CartBar Font Family:"
                            activeFont={fields.cartBarMobileFontFamily}
                            field="cartBarMobileFontFamily"
                            handleFontFamilyInput={handleInputChange}
                        />
                    </div>
                    <TabSettings handleTabIndex={handleTabIndex}>
                        {tabIndex === 0 &&
                            <FormLayout>
                                <FormLayout.Group>
                                    <TextField
                                        label="Offer Name:"
                                        value={fields.offerName}
                                        onChange={e => handleInputChange(e, 'offerName')}
                                        error={inputErrors.offerNameError}
                                    />
                                    {cartBarType === 'coupon' ? (
                                        <div>
                                            <div className="group-input-handle">
                                                <FormLayout.Group condensed>
                                                    <TextField
                                                        label="Discount Code:"
                                                        value={fields.discountCode}
                                                        onChange={e => handleInputChange(e, 'discountCode')}
                                                        error={inputErrors.discountError}
                                                    />
                                                    <TextField
                                                        label="Cart Value Limit:"
                                                        value={fields.cartLimit}
                                                        type="number"
                                                        onChange={e => handleInputChange(e, 'cartLimit')}
                                                        error={inputErrors.cartLimitError}
                                                    />
                                                </FormLayout.Group>
                                            </div>
                                            <div className="helper-text"><span>Manage Discounts. Visit <Link url={`https://${shop}/admin/discounts`} external>discount</Link> page</span></div>
                                        </div>
                                    ) :
                                        (
                                            <TextField
                                                label="Cart Value Limit:"
                                                value={fields.cartLimit}
                                                type="number"
                                                min="0"
                                                onChange={e => handleInputChange(e, 'cartLimit')}
                                                helpText={cartBarType === 'shipping' ? <span>To update your shipping preferences. Visit <Link url={`https://${shop}/admin/settings/shipping`} external>shipping</Link> page</span> : ''}
                                                error={inputErrors.cartLimitError}
                                            />
                                        )
                                    }
                                </FormLayout.Group>
                                <FormLayout.Group>
                                    <TextField
                                        multiline={2}
                                        label="CartBar Offer Text:"
                                        value={fields.cartBarOfferText}
                                        onChange={e => handleInputChange(e, 'cartBarOfferText')}
                                    />
                                    <TextField
                                        multiline={2}
                                        label="CartBar Completion Text:"
                                        value={fields.cartBarCompletionText}
                                        onChange={e => handleInputChange(e, 'cartBarCompletionText')}
                                    />
                                </FormLayout.Group>
                                <FormLayout.Group>
                                    <TextField
                                        prefix="#"
                                        label="Base CartBar Color:"
                                        onChange={e => handleColorChange(e, 'barBaseColor')}
                                        value={colorWithoutHash(fields.barBaseColor)}
                                        suffix={<ColorChoser
                                            field="barBaseColor"
                                            handleColorInput={handleInputChange}
                                            color={fields.barBaseColor}
                                        />
                                        }
                                    />
                                    <TextField
                                        prefix="#"
                                        label="CartBar Slider Color:"
                                        onChange={e => handleColorChange(e, 'barSliderColor')}
                                        value={colorWithoutHash(fields.barSliderColor)}
                                        suffix={<ColorChoser
                                            field="barSliderColor"
                                            handleColorInput={handleInputChange}
                                            color={fields.barSliderColor}
                                        />
                                        }
                                    />
                                </FormLayout.Group>
                                <FormLayout.Group>
                                    <Select
                                        label="Bar Pointer:"
                                        options={barPointerOptions}
                                        onChange={e => handleInputChange(e, 'barPointer')}
                                        value={fields.barPointer}
                                    />
                                    <TextField
                                        prefix="#"
                                        label="Bar Pointer Color:"
                                        onChange={e => handleColorChange(e, 'barPointerColor')}
                                        value={colorWithoutHash(fields.barPointerColor)}
                                        suffix={<ColorChoser
                                            field="barPointerColor"
                                            handleColorInput={handleInputChange}
                                            color={fields.barPointerColor}
                                        />
                                        }
                                    />
                                </FormLayout.Group>
                                <FormLayout.Group>
                                    <RangeSlider
                                        output
                                        label="Bar Thickness:"
                                        min={5}
                                        max={15}
                                        step={1}
                                        value={fields.barThickness}
                                        onChange={e => handleInputChange(e, 'barThickness')}
                                        prefix={<p>5px</p>}
                                        suffix={<p>15px</p>}
                                    />
                                    <Select
                                        label="CartBar Placement:"
                                        options={cartBarOptions}
                                        onChange={e => handleInputChange(e, 'cartBarPlacement')}
                                        value={fields.cartBarPlacement}
                                    />
                                </FormLayout.Group>
                                {cartBarType === 'product' &&
                                    <FormLayout.Group>
                                        <Select
                                            label="Offer Product Placement:"
                                            options={productPlacementOptions}
                                            onChange={e => handleInputChange(e, 'productPlacement')}
                                            value={fields.productPlacement}
                                        />
                                        <></>
                                    </FormLayout.Group>
                                }
                                <FormLayout.Group>
                                    <Checkbox
                                        label="Hide CartBar Prices"
                                        checked={fields.hideCartbarPrices}
                                        onChange={e => handleInputChange(e, 'hideCartbarPrices')}
                                    />
                                    <Checkbox
                                        label="CartBar Border Square"
                                        checked={fields.cartBarSquare}
                                        onChange={e => handleInputChange(e, 'cartBarSquare')}
                                    />
                                </FormLayout.Group>
                                <FormLayout.Group>
                                    <Checkbox
                                        label="Hide CartBar Value Price"
                                        checked={fields.hideCartbarValuePrice}
                                        onChange={e => handleInputChange(e, 'hideCartbarValuePrice')}
                                    />
                                    <Checkbox
                                        label="Enable CartBar Shadow"
                                        checked={fields.cartBarShadow}
                                        onChange={e => handleInputChange(e, 'cartBarShadow')}
                                    />
                                </FormLayout.Group>
                                <FormLayout.Group>
                                    <Checkbox
                                        label="Enable CartBar Background"
                                        checked={fields.cartBarTextBackground}
                                        onChange={e => handleInputChange(e, 'cartBarTextBackground')}
                                    />
                                    {fields.cartBarPlacement === 'custom-positon' &&
                                        <Checkbox
                                            label="Ajax Cart"
                                            checked={fields.ajaxCart}
                                            onChange={e => handleInputChange(e, 'ajaxCart')}
                                        />
                                    }
                                </FormLayout.Group>
                                {cartBarType === 'product' &&
                                    <FormLayout.Group>
                                        <div className="product-cartbar-offer">
                                            <Card title="Offer Product">
                                                <ResourceList
                                                    resourceName={{ singular: 'sale', plural: 'pname' }}
                                                    items={[
                                                        {
                                                            title: requiredProduct?.title,
                                                            amount: '$' + requiredProduct?.variants[0]?.price,
                                                            pcate: requiredProduct?.variants[0]?.title,
                                                            psku: requiredProduct?.variants[0]?.sku,
                                                            qty: 1,
                                                            tamount: '$' + requiredProduct?.variants[0]?.price,
                                                            image: requiredProduct?.images[0]?.originalSrc
                                                        }
                                                    ]}
                                                    renderItem={(item) => {
                                                        const { title, pcate, psku, tamount, image } = item;
                                                        const media = <Thumbnail
                                                            source={image ? image : dummyImage}
                                                            size="large"
                                                            alt={requiredProduct?.title}
                                                        />;
                                                        return (
                                                            <div className="myprdctlist">
                                                                { requiredProduct &&
                                                                    <ResourceList.Item
                                                                        media={media}
                                                                        accessibilityLabel={`View pname for ${title}`}
                                                                    >
                                                                        <Stack>
                                                                            <div className="pdesc">
                                                                                <Stack.Item fill>{title}</Stack.Item>
                                                                                <Stack.Item fill>{pcate}</Stack.Item>
                                                                                <Stack.Item>{psku}</Stack.Item>
                                                                            </div>
                                                                            <div className="ptamount">
                                                                                <Stack.Item>{tamount}</Stack.Item>
                                                                            </div>
                                                                        </Stack>
                                                                    </ResourceList.Item>
                                                                }
                                                                <Button primary onClick={() => setOpenPicker(true)}>Select Product</Button>
                                                            </div>
                                                        );
                                                    }}
                                                />
                                                {inputErrors.productError &&
                                                    <RequiredError />
                                                }
                                            </Card>
                                        </div>
                                    </FormLayout.Group>
                                }
                                <CustomPlacement placement={fields.cartBarPlacement} />
                            </FormLayout>
                        }
                        {tabIndex === 1 &&
                            <FormLayout>
                                <FormLayout.Group>
                                    <TextField
                                        prefix="#"
                                        label="CartBar Text Color:"
                                        onChange={e => handleColorChange(e, 'cartBarTextColor')}
                                        value={colorWithoutHash(fields.cartBarTextColor)}
                                        suffix={<ColorChoser
                                            field="cartBarTextColor"
                                            handleColorInput={handleInputChange}
                                            color={fields.cartBarTextColor}
                                        />
                                        }
                                    />
                                    <TextField
                                        prefix="#"
                                        label="CartBar Background Color:"
                                        onChange={e => handleColorChange(e, 'cartBarBgColor')}
                                        value={colorWithoutHash(fields.cartBarBgColor)}
                                        suffix={<ColorChoser
                                            field="cartBarBgColor"
                                            handleColorInput={handleInputChange}
                                            color={fields.cartBarBgColor}
                                        />
                                        }
                                    />
                                </FormLayout.Group>
                                <FormLayout.Group>
                                    <FontFamilyPicker
                                        pickerId="main"
                                        label="CartBar Font Family:"
                                        activeFont={fields.cartBarFontFamily}
                                        field="cartBarFontFamily"
                                        handleFontFamilyInput={handleInputChange}
                                    />
                                    <Select
                                        label="CartBar Text Alignment:"
                                        options={textAlignmentOptions}
                                        onChange={e => handleInputChange(e, 'cartBarTextAlign')}
                                        value={fields.cartBarTextAlign}
                                    />
                                </FormLayout.Group>
                                <FormLayout.Group>
                                    <RangeSlider
                                        output
                                        label="CartBar Font Size:"
                                        min={10}
                                        max={30}
                                        step={1}
                                        value={fields.cartBarFontSize}
                                        onChange={e => handleInputChange(e, 'cartBarFontSize')}
                                        prefix={<p>10px</p>}
                                        suffix={<p>30px</p>}
                                    />
                                </FormLayout.Group>
                            </FormLayout>
                        }
                        {tabIndex === 2 &&
                            <FormLayout>
                                <FormLayout.Group>
                                    <TextField
                                        prefix="#"
                                        label="CartBar Text Color:"
                                        onChange={e => handleColorChange(e, 'cartBarMobileTextColor')}
                                        value={colorWithoutHash(fields.cartBarMobileTextColor)}
                                        suffix={<ColorChoser
                                            field="cartBarMobileTextColor"
                                            handleColorInput={handleInputChange}
                                            color={fields.cartBarMobileTextColor}
                                        />
                                        }
                                    />
                                    <TextField
                                        prefix="#"
                                        label="CartBar Background Color:"
                                        onChange={e => handleColorChange(e, 'cartBarMobileBgColor')}
                                        value={colorWithoutHash(fields.cartBarMobileBgColor)}
                                        suffix={<ColorChoser
                                            field="cartBarMobileBgColor"
                                            handleColorInput={handleInputChange}
                                            color={fields.cartBarMobileBgColor}
                                        />
                                        }
                                    />
                                </FormLayout.Group>
                                <FormLayout.Group>
                                    <FontFamilyPicker
                                        pickerId="mobileFont"
                                        label="CartBar Font Family:"
                                        activeFont={fields.cartBarMobileFontFamily}
                                        field="cartBarMobileFontFamily"
                                        handleFontFamilyInput={handleInputChange}
                                    />
                                    <Select
                                        label="CartBar Text Alignment:"
                                        options={textAlignmentOptions}
                                        onChange={e => handleInputChange(e, 'cartBarMobileTextAlign')}
                                        value={fields.cartBarMobileTextAlign}
                                    />
                                </FormLayout.Group>
                                <FormLayout.Group>
                                    <RangeSlider
                                        output
                                        label="CartBar Font Size:"
                                        min={10}
                                        max={30}
                                        step={1}
                                        value={fields.cartBarMobileFontSize}
                                        onChange={e => handleInputChange(e, 'cartBarMobileFontSize')}
                                        prefix={<p>10px</p>}
                                        suffix={<p>30px</p>}
                                    />
                                </FormLayout.Group>
                            </FormLayout>
                        }
                        {tabIndex === 3 &&
                            <FormLayout>
                                <Checkbox
                                    label="Enable Custom CSS:"
                                    checked={fields.enableCustomCss}
                                    onChange={e => handleInputChange(e, 'enableCustomCss')}
                                />
                                <TextField
                                    multiline={8}
                                    value={fields.customCss}
                                    placeholder="Enter Custom CSS Here..."
                                    disabled={fields.enableCustomCss ? false : true}
                                    onChange={e => handleInputChange(e, 'customCss')}
                                />
                                <CssHelper />
                            </FormLayout>
                        }
                    </TabSettings>
                    <div className="button_wrap m-t-30">
                        <Button primary submit loading={isLoading} onClick={handleSubmit}>Save</Button>
                        {id && <Button destructive onClick={deleteOffer} loading={deleteLoading}>Delete</Button>}
                    </div>
                    {shop &&
                        <ItemPicker
                            resourceType="Product"
                            openPicker={openPicker}
                            handleItemSelection={handleItemSelection}
                            setOpenPicker={setOpenPicker}
                            selectedItem={selectedItem}
                        />
                    }
                    <Tooltip tooltip={toolTip} />
                </Form>
            </Page>
        </div>
    )
}
