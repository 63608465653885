import React, { useState, useEffect } from 'react';
import { Button, ButtonGroup, Heading } from '@shopify/polaris';

export default function CardHeading(props) {
    // set state of button
    const [isFirstButtonActive, setIsFirstButtonActive] = useState(false);
    useEffect(() => {
        setIsFirstButtonActive(props.status);
    }, [props]);
    // handle on button
    const handleFirstButtonClick = () => {
        if (isFirstButtonActive) return;
        setIsFirstButtonActive(true);
        props.handleButtonToggle(true, props.name);
    };
    // handle off button
    const handleSecondButtonClick = () => {
        if (!isFirstButtonActive) return;
        setIsFirstButtonActive(false);
        props.handleButtonToggle(false, props.name);
    };

    return (
        <div className="card-heading-with-toggle">
            <Heading>{props.title}</Heading>
            <ButtonGroup segmented>
                <Button pressed={isFirstButtonActive} onClick={handleFirstButtonClick}>
                    ON
                </Button>
                <Button pressed={!isFirstButtonActive} onClick={handleSecondButtonClick}>
                    OFF
                </Button>
            </ButtonGroup>
        </div>
    );
}
