import React from 'react';
import './App.css';
import routes from './routes';
import {
	BrowserRouter as Router,
	Route,
	Switch,
	Redirect
} from 'react-router-dom';
import { IntercomProvider} from 'react-use-intercom';
import Appsignal from "@appsignal/javascript";
import { ErrorBoundary } from "@appsignal/react";


const PrivateRoute = ({ ...rest }) => {
	let offerMenus = [];
	if (localStorage.getItem('cartboostPlan')) {
		offerMenus = JSON.parse(localStorage.getItem('cartboostPlan'));
		offerMenus = JSON.parse(offerMenus.menus);
	}

	if (rest.authL === false || (rest.authL === true && offerMenus.indexOf(rest.name) !== -1)) {
		return (
			<Route {...rest} />
		)
	} else {
		return (
			<Redirect to={{ pathname: "/" }} />
		) 
	}
}


export default function App() {
	const INTERCOM_APP_ID = 'resnokf2';
	const appsignal = new Appsignal({key: "fd9b577d-3525-463d-94eb-72a030755a2c"});
	return (
		
			<Router>
				<div>
				<ErrorBoundary  instance={appsignal}>
					<IntercomProvider appId={INTERCOM_APP_ID} autoBoot>
						<Switch>
							{routes.map((route, i) => {
								return <PrivateRoute key={i} {...route}  />
							})}
						</Switch>
					</IntercomProvider>	
				</ErrorBoundary>
				</div>
			</Router>
	);
}





