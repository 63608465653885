import React, { useState, useCallback } from 'react';
import { Card, Tabs, Icon } from '@shopify/polaris';
import {
    DesktopMajorMonotone,
    MobileMajorMonotone,
    GlobeMajorMonotone,
    SettingsMajorMonotone
} from '@shopify/polaris-icons';
import '../assets/tab-settings.scss';

export default function TabSettings({ handleTabIndex, children, excludeIndex }) {
    const [selected, setSelected] = useState(0);

    const handleTabChange = useCallback((selectedTabIndex) => {
        setSelected(selectedTabIndex);
        handleTabIndex(selectedTabIndex);
    }, [handleTabIndex],);

    let tabs = [
        {
            id: 'universal',
            content: <span className="tabs-menu"><Icon source={GlobeMajorMonotone} color="indigo" /> Universal Settings </span>,
            panelID: 'all-customers-content-1',
        },
        {
            id: 'desktop',
            content: <span className="tabs-menu"><Icon source={DesktopMajorMonotone} color="indigo" /> Desktop Settings </span>,
            panelID: 'desktop-content',
        },
        {
            id: 'mobile',
            content: <span className="tabs-menu"><Icon source={MobileMajorMonotone} color="indigo" /> Mobile Settings </span>,
            panelID: 'mobile-content',
        },
        {
            id: 'custom',
            content: <span className="tabs-menu"><Icon source={SettingsMajorMonotone} color="indigo" /> Custom CSS </span>,
            panelID: 'custom-content',
        },
    ];

    if (excludeIndex) {
        tabs = tabs.filter((_, index) => index !== excludeIndex );
    }

    return (
        <div className="component-all-settings">
            <Card>
                <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
                    <Card.Section>
                        {children}
                    </Card.Section>
                </Tabs>
            </Card>
        </div>
    );
}
