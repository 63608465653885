import React, { useEffect, useState } from 'react'
import FontPicker from "font-picker-react";
import { useParams } from "react-router-dom";

export default function FontFamilyPicker(props) {
    const [activeFont, setActiveFont] = useState(null);
    const { id } = useParams();

    useEffect(() => {
        if (id) {
            if (props.activeFont) {
                setActiveFont(props.activeFont);
            } 
        } else {
            setActiveFont(props.activeFont ? props.activeFont : 'Open Sans');
        }   
    }, [props, id]);

    return (
        <div className="font-family-wrapper">
            <label>{props.label}</label>
            {activeFont &&
                <FontPicker
                    pickerId={props.pickerId}
                    apiKey="AIzaSyAH_Cu9td5hLv79cFohdkQWFkL5xCKiz8k"
                    activeFontFamily={activeFont}
                    limit={100}
                    onChange={(nextFont) => {
                        props.handleFontFamilyInput(nextFont.family, props.field)
                    }
                    }
                />
            }
        </div>
    )
}
