import React, { useEffect, useState, useRef } from 'react';
import { Card, ButtonGroup, Button, Heading, Icon } from '@shopify/polaris';
import {
    DesktopMajorMonotone,
    MobileMajorMonotone
} from '@shopify/polaris-icons';
import { withGetScreen } from 'react-getscreen';

var intervalID = null;

const removeUnwantedKeys = (val) => {
    let data = { ...val };
    let messages = 0;
    for (let key in data) {
        if (key.search('Color') >= 0) {
            delete data[key];
        } else if (key.search('Message') >= 0) {
            if (key !== 'boostBarMessagesType') {
                if (data[key]) {
                    messages++;
                    delete data[key];
                }
            }
        }
    }
    data.messages = messages
    return data;
}

const useHasChanged = (val) => {
    const data = removeUnwantedKeys(val);
    const prevVal = usePrevious(val)
    return JSON.stringify(prevVal) !== JSON.stringify(data)
}

const usePrevious = (value) => {
    const data = removeUnwantedKeys(value);
    const ref = useRef();
    useEffect(() => {
        ref.current = data;
    });
    return ref.current;
}

const ProgressBar = (props) => {
    const { title, fields } = props;
    const hasChanged = useHasChanged(props.fields);
    
    useEffect(() => {
        function interval(flag) {
            if (flag) {
                const scrollSpeed = fields.scrollSpeed === 'medium' ? 5000 : (fields.scrollSpeed === 'fast' ? 1000 : 10000);
                var current = 0;
                intervalID = setInterval(function () {
                    var slides = document.querySelectorAll("#boost-bar-slider .content");
                    if (slides.length) {
                        for (var i = 0; i < slides.length; i++) {
                            slides[i].style.opacity = 0;
                        }
                        current = (current !== slides.length - 1) ? current + 1 : 0;
                        if (slides[current]) {
                            slides[current].style.opacity = 1;
                        }
                    }
                }, scrollSpeed);
            } else {
                clearInterval(intervalID);
            }
        }
        if (hasChanged) {
            if (fields.boostBarMessagesType === 'multiple') {
                // clear previous interval
                interval(false);
                // start new interval
                setTimeout(function () {
                    interval(true);
                }, 1000);
            } else {
                // clear previous interval
                interval(false);
                var slides = document.querySelectorAll("#boost-bar-slider .content");
                if (slides.length) {
                    for (var i = 0; i < slides.length; i++) {
                        if (i === 0) {
                            slides[i].style.opacity = 1;
                        } else {
                            slides[i].style.opacity = 0;
                        }
                    }
                }
            }
        }
    }, [hasChanged, fields])
    
    const getStyle = () => {
        const previewStyle = {
            fontSize: isFirstButtonActive ? fields.boostBarFontSize : fields.boostBarMobileFontSize,
            textAlign: isFirstButtonActive ? fields.boostBarTextAlign : fields.boostBarMobileTextAlign,
            backgroundColor: fields.boostBarBgColor
        }
        return previewStyle;
    };


    const contentStyle = (color) => {
        const style = {
            color: color,
        };
        return style;
    };

    const [isFirstButtonActive, setIsFirstButtonActive] = useState(true);

    const handleFirstButtonClick = () => {
        if (isFirstButtonActive) return;
        setIsFirstButtonActive(true);
    };

    const handleSecondButtonClick = () => {
        if (!isFirstButtonActive) return;
        setIsFirstButtonActive(false);
    };

    return (
        <div className="">
            <Card sectioned>
                <div className="card-heading-with-toggle">
                    <Heading>{title}</Heading>
                    <ButtonGroup segmented>
                        <Button pressed={isFirstButtonActive} onClick={handleFirstButtonClick} icon={<Icon source={DesktopMajorMonotone} />}>
                            Desktop
							</Button>
                        <Button pressed={!isFirstButtonActive} onClick={handleSecondButtonClick} icon={<Icon source={MobileMajorMonotone} color="indigo" />}>
                            Mobile
							</Button>
                    </ButtonGroup>
                </div>
                <div id="boost-bar-slider" className={`m-t-10 preview-bar ${isFirstButtonActive ? 'apply-font-pcFont' : 'apply-font-mobileFont'} `} style={getStyle()}>
                    {(props.isMobile() || !isFirstButtonActive) ? (
                        <>
                            <p className="content" style={contentStyle(fields.boostBarMobileMessageColor)}>{fields.boostBarSiteWideMobileMessage}</p>
                            {fields.boostBarSiteWideMobileMessage1 && <p className="content" style={contentStyle(fields.boostBarMobileMessageColor1)}>{fields.boostBarSiteWideMobileMessage1}</p>}
                            {fields.boostBarSiteWideMobileMessage2 && <p className="content" style={contentStyle(fields.boostBarMobileMessageColor2)}>{fields.boostBarSiteWideMobileMessage2}</p>}
                            {fields.boostBarSiteWideMobileMessage3 && <p className="content" style={contentStyle(fields.boostBarMobileMessageColor3)}>{fields.boostBarSiteWideMobileMessage3}</p>}
                        </>
                    ) : (
                        <>
                            <p className="content" style={contentStyle(fields.boostBarMessageColor)}>{fields.boostBarSiteWideMessage}</p>
                            {fields.boostBarSiteWideMessage1 && <p className="content" style={contentStyle(fields.boostBarMessageColor1)}>{fields.boostBarSiteWideMessage1}</p>}
                            {fields.boostBarSiteWideMessage2 && <p className="content" style={contentStyle(fields.boostBarMessageColor2)}>{fields.boostBarSiteWideMessage2}</p>}
                            {fields.boostBarSiteWideMessage3 && <p className="content" style={contentStyle(fields.boostBarMessageColor3)}>{fields.boostBarSiteWideMessage3}</p>}
                        </>
                    )}
                </div>

            </Card>
        </div>

    );
};

export default withGetScreen(ProgressBar);