import React, { useState } from 'react';
import { Card, Page, TextContainer, Collapsible, Icon } from '@shopify/polaris';
import {
	ChevronUpMinor,
	ChevronDownMinor
} from '@shopify/polaris-icons';
import TabHeader from '../common/TabHeader';

export default function TabsExample() {
	
	const [questions, setQuestions] = useState([
		{
			title: 'Where can I contact Cartboost for support?',
			description: 'For any troubleshooting or questions on integration please contact us at support@cartboostapp.com. You can also visit us online at our website at www.Cartboostapp.com.',
			active: false
		},
		{
			title: 'How many Upsells can I have live at one time?',
			description: 'We recommend having 1 Cartboost Bar Upsell and 1 In-cart Upsell on at a time. You can only have 1 Cartboost Bar Upsell per time but you may have an unlimited amount of in-cart upsells live at one time.',
			active: false
		},
		{
			title: 'The Free coupon upsell is not triggering on checkout, how can this be fixed?',
			description: 'Please make sure you have created the discount code you are using in the Shopify admin at Discounts > Create Coupon Code.',
			active: false
		}
	]);

	const handleToggle = (index) => {
		let data = [...questions];
		data[index].active = !data[index].active;
		setQuestions(data);
	}

	return (
		<div className='faq-outr'>
			<TabHeader />
			<Page title="FAQs">
				{questions.map((question, index) => {
					return (
						<Card sectioned key={index}>
							<div className="poz-rel" onClick={e => handleToggle(index)} aria-expanded={question.active} aria-controls="basic-collapsible">
								{(index + 1) + '. ' + question.title}
								<span className="accordion-arrow"><Icon source={question.active ? ChevronUpMinor : ChevronDownMinor} /></span>
							</div>
							<div className="text-collapse">
								<Collapsible
									open={question.active}
									id="basic-collapsible"
									transition={{ duration: '150ms', timingFunction: 'ease' }}
								>
									<TextContainer>{question.description}</TextContainer>
								</Collapsible>
							</div>
						</Card>
					)
				})}
			</Page>
		</div>
	);
}