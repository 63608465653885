import React, { useState, useEffect, useCallback } from 'react';
import { Card, Page, Select, TextField, Form, Stack, FormLayout, Thumbnail, Button, ResourceList, RangeSlider, Checkbox, Banner, TextStyle, Modal, TextContainer, Link, ButtonGroup, Heading, Icon } from '@shopify/polaris';
import TabHeader from '../../common/TabHeader';
import ColorChoser from '../../common/Color';
import { colorWithoutHash, offerTypeOptions, TextPlacementOptions, buttonOptions, upsellPlacementOptions, dummyImage, redirectPage, makingFields, getPayload, buttonSizeOptions, textAlignmentOptions, getStoreName } from '../../common/Helper';
import ItemPicker from '../../common/ItemPicker';
import { ResourcePicker } from '@shopify/app-bridge-react';
import { useParams } from "react-router-dom";
import CustomTooltip from '../../common/Tooltip';
import API from '../../service/Api';
import { withGetScreen } from 'react-getscreen';
import FontFamilyPicker from '../../common/FontPicker';
import CssHelper from '../../common/CssHelper';
import RequiredError from '../../common/RequiredError';
import BackButton from '../../common/BackButton';
import TabSettings from '../../common/TabSettings';
import {
	DesktopMajorMonotone,
	MobileMajorMonotone
} from '@shopify/polaris-icons';


const htmlCode = '<div id="Incart_offers"></div>';
const limitCode = 'id = “limitCartboost_{{ item.product.id }}”';
const tdCode = "<td> quantity code within </td>";
const divCode = "<div> quantity code within </div>";

let initialStates = {
	offerType: 'cart-limit',
	offerName: '',
	cartLimit: '',
	cartMax: '',
	promotionalTextPlacement: 'middle',
	promotionalFontSize: '15',
	promotionalTextColor: '#000000',
	promotionalDesktopText: '',
	promotionalButtonText: 'Add To Cart',
	promotionalButtonBgColor: '#6371c7',
	promotionalButtonTextColor: '#ffffff',
	promotionalButtonFontSize: '15',
	promotionalTextFontFamily: '',
	promotionalButtonFontFamily: '',
	promotionalTextAlignment: 'left',
	promotionalTextPadding: 3,
	buttonOtherName: '',
	promotionalMobileText: '',
	promotionalMobileTextPlacement: 'middle',
	promotionalMobileTextFontSize: '15',
	promotionalMobileTextFontFamily: '',
	promotionalMobileTextColor: '#000000',
	promotionalMobileButtonBgColor: '#6371c7',
	promotionalMobileButtonTextColor: '#ffffff',
	promotionalMobileButtonFontFamily: '',
	promotionalMobileTextPadding: 3,
	promotionalMobileTextAlignment: 'left',
	promotionalMobileButtonText: 'Add To Cart',
	promotionalMobileButtonFontSize: '15',
	upsellPlacement: 'below-items',
	enableCustomCss: false,
	customCss: '',
	displayProductTitle: false,
	ajaxCart: false,
	enableBgColor: false,
	promotionalBgColor: '#ffffff',
	displayProductPrice: false,
	displayProductSalePrice: false,
	newPrice: '',
	freeProduct: '',
	applyLimit: false,
	upsellLayout: 'layout-1',
	buttonMobileText: 'Add To Cart',
	buttonMobileOtherName: '',
	buttonMobileFontSize: '15',
	imageSizeMobile: 'small',
	imageSizeDesktop: 'medium',
	roundBackgroundEdge: 0,
	upsellAlignment: 'center',
	status: 0,
	buttonSizeDesktop: 'medium',
	buttonSizeMobile: 'medium',
	autoAddUpsell: false,
	removeOfferProduct: false,
	showTags: '',
	hideTags: ''
};

function IncartUpsell(props) {
	const shop = getStoreName();
	const { id } = useParams();

	const [title, setTitle] = useState("Create New Upsell");
	const [fields, setFields] = useState(initialStates);
	const [inputErrors, setInputErrors] = useState({
		offerNameError: null,
		offerDecpError: null,
		discountError: null,
		cartLimitError: null,
		productError: null,
		collectionError: null,
		offerProductError: null,
		newPriceError: null,
		freeProductError: null,
		deskopButtonOtherNameError: null,
		mobileButtonOtherNameError: null
	});

	const [isLoading, setLoading] = useState(false);
	const [deleteLoading, setDeleteLoading] = useState(false);
	const [toolTip, setTooltip] = useState({
		active: false,
		content: null,
		error: false
	});

	const [openPicker, setOpenPicker] = useState(false);
	const [selectedItem, setSelectedItem] = useState('required-product');
	const [requiredProduct, setRequiredProduct] = useState([]);
	const [offerProduct, setOfferProduct] = useState(null);
	const [requiredCollection, setRequiredCollection] = useState(null);
	// const [offerCollection, setOfferCollection] = useState(null);
	// selected ids
	const [requiredProductId, setRequiredProductId] = useState([]);
	const [offerProductId, setOfferProductId] = useState(null);
	const [requiredCollectionId, setRequiredCollectionId] = useState(null);
	const [copyButtonText, setButtonText] = useState('Copy code');
	const [disableBackButton, setDisableBackButton] = useState(false);
	const [limitModalPreview, setLimitModalPreview] = useState(false);
	const [tabIndex, setTabIndex] = useState(0);
	/**get data from db */
	useEffect(() => {
		if (id) {
			API.get(`incart-upsell/${id}`).then(response => {
				const upsell = response.data.data.upsell;
				const metaData = JSON.parse(upsell.upsellOptions);
				const buttonName = buttonOptions.find(x => x.value === metaData.promotionalButtonText_meta);
				const mobileButtonName = buttonOptions.find(x => x.value === metaData.promotionalMobileButtonText_meta);
				setTitle(upsell.upsellName);
				let dbFields = makingFields(metaData);
				dbFields.offerName = upsell.upsellName;
				dbFields.offerType = upsell.upsellType;
				dbFields.status = upsell.upsellStatus;
				dbFields.promotionalButtonText = buttonName ? metaData.promotionalButtonText_meta : 'other';
				dbFields.buttonOtherName = buttonName ? '' : metaData.promotionalButtonText_meta;
				dbFields.promotionalMobileButtonText = mobileButtonName ? metaData.promotionalMobileButtonText_meta : 'other';
				dbFields.buttonMobileOtherName = mobileButtonName ? '' : metaData.promotionalMobileButtonText_meta;
				dbFields.imageSizeMobile = dbFields.imageSizeMobile ?? 'small';
				dbFields.imageSizeDesktop = dbFields.imageSizeDesktop ?? 'medium';
				dbFields.upsellAlignment = dbFields.upsellAlignment ?? 'center';
				dbFields.textAlignment = dbFields.textAlignment ?? 'left';
				dbFields.roundBackgroundEdge = dbFields.roundBackgroundEdge ?? 0;
				dbFields.freeProduct = dbFields.freeProduct ?? '';
				//make initialStates with db values
				initialStates = dbFields;
				setFields(dbFields);
				if (metaData.offerProduct_meta && metaData.offerProduct_meta.id) {
					setOfferProductId([{
						id: metaData.offerProduct_meta.id,
						variants: [{
							id: metaData.offerProduct_meta.variants[0].id,
						}],
					}]);
					setOfferProduct(metaData.offerProduct_meta);
				}
				if (upsell.upsellType === 'specific-product' && metaData.product_meta && metaData.product_meta.length) {
					setRequiredProduct(metaData.product_meta);
					const ids = [];
					metaData.product_meta.forEach(item => {
						ids.push({
							id: item.id
						})
					})
					setRequiredProductId(ids);
				}
				if (upsell.upsellType === 'collection-only') {
					setRequiredCollectionId([{
						id: metaData.collection_meta.id,
					}]);
					setRequiredCollection(metaData.collection_meta);
				}
			}).catch(err => {
				console.log('err: ', err);
			});
		}
	}, [id, shop]);

	/** Disable back button if form is edited */
	useEffect(() => {
		if (JSON.stringify(fields) === JSON.stringify(initialStates)) {
			setDisableBackButton(false);
		} else {
			setDisableBackButton(true);
		}
	}, [fields]);

	const handleInputChange = (inputValue, inputName) => {
		/* if (inputName === 'promotionalDesktopText') {
			setFields({
				...fields,
				promotionalMobileText: inputValue,
				promotionalDesktopText: inputValue
			})
		} else {
			setFields({
				...fields,
				[inputName]: inputValue
			});
		} */
		setFields({
			...fields,
			[inputName]: inputValue
		});
	};

	const handleColorChange = (color, inputName) => {
		const value = color.search('#') >= 0 ? color : '#' + color;
		setFields({
			...fields,
			[inputName]: value
		});
	};

	const handleItemSelection = (resources) => {
		const idFromResources = resources.selection.map((item) => item.id);
		if (idFromResources.length) {
			if (selectedItem === 'required-product') {
				const ids = [];
				idFromResources.forEach(item => {
					ids.push({
						id: item
					})
				})
				setRequiredProductId(ids);
				setInputErrors({
					...inputErrors,
					productError: null
				});
				setRequiredProduct(resources.selection);
			} else if (selectedItem === 'offer-product') {
				setOfferProductId([{
					id: idFromResources[0]
				}]);
				setInputErrors({
					...inputErrors,
					offerProductError: null
				});
				var compareAtPrice = resources.selection[0].variants[0].compareAtPrice;
				setFields({
					...fields,
					newPrice: compareAtPrice
				});
				setOfferProduct(resources.selection[0]);
			} else if (selectedItem === 'required-collection') {
				setRequiredCollectionId([{
					id: idFromResources[0]
				}]);
				setRequiredCollection(resources.selection[0]);
				setInputErrors({
					...inputErrors,
					collectionError: null
				})
			}
			if (disableBackButton === false) {
				setDisableBackButton(true);
			}
		} else {
			if (selectedItem === 'required-product') {
				setRequiredProductId(null);
				setRequiredProduct(null);
				setInputErrors({
					...inputErrors,
					productError: null
				});
			} else if (selectedItem === 'offer-product') {
				setOfferProductId(null);
				setOfferProduct(null);
				setInputErrors({
					...inputErrors,
					offerProductError: null
				});
			} else if (selectedItem === 'required-collection') {
				setRequiredCollectionId(null);
				setRequiredCollection(null);
				setInputErrors({
					...inputErrors,
					collectionError: null
				})
			}
			if (JSON.stringify(fields) === JSON.stringify(initialStates)) {
				setDisableBackButton(false);
			}
		}

		setOpenPicker(false);
	};

	const handleSelectedItemPicker = (name) => {
		setSelectedItem(name);
		setOpenPicker(true);
	};

	const getSelectedIds = () => {
		if (selectedItem === 'required-product') {
			return requiredProductId ? requiredProductId : [];
		} else if (selectedItem === 'offer-product') {
			return offerProductId ? offerProductId : [];
		} else if (selectedItem === 'required-collection') {
			return requiredCollectionId ? requiredCollectionId : [];
		}
	};

	const handleValidation = (data) => {
		let errors = {};
		if (data.offerName.trim() === "") {
			errors.offerNameError = "This is required field";
		}
		if (data.cartLimit === "" && data.offerType === 'cart-limit') {
			errors.cartLimitError = "This is required field";
		}
		if (Number(data.cartMax) < 0 && data.offerType === 'cart-limit') {
			errors.cartMaxError = "This value can not be less than 0";
		}
		if (Number(data.cartLimit) < 0 && data.offerType === 'cart-limit') {
			errors.cartLimitError = "This value can not be less than 0";
		}
		if (data.cartMax === "" && data.offerType === 'cart-limit') {
			errors.cartMaxError = "This is required field";
		} else if (Number(data.cartMax) <= Number(data.cartLimit) && data.offerType === 'cart-limit') {
			errors.cartMaxError = "This value can not be equal or less than cart min value";
		}
		if ((data.displayProductSalePrice && !data.newPrice) || (data.displayProductSalePrice && data.newPrice.trim() === '')) {
			errors.newPriceError = "This is required field";
		}
		if (data.displayProductSalePrice && Number(data.newPrice) < 0) {
			errors.newPriceError = "The amount can not be less than 0";
		}
		if (requiredProduct.length === 0 && data.offerType === 'specific-product') {
			errors.productError = "This is required field";
		}
		if (!requiredCollection && data.offerType === 'collection-only') {
			errors.collectionError = "This is required field";
		}
		if (!offerProduct) {
			errors.offerProductError = "This is required field";
		}
		if (data.applyLimit && data.freeProduct === '') {
			errors.freeProductError = "This is required field";
		}
		if (data.promotionalButtonText === 'other' && (!data.buttonOtherName || data.buttonOtherName.trim() === '')) {
			errors.deskopButtonOtherNameError = "This is required field";
		}
		if (data.promotionalMobileButtonText === 'other' && (!data.buttonMobileOtherName || data.buttonMobileOtherName.trim() === '')) {
			errors.mobileButtonOtherNameError = "This is required field";
		}
		setInputErrors(errors);
		return errors;
	};

	const showInputError = () => {
		return (
			<RequiredError />
		);
	};

	const handleSubmit = () => {
		let checkValidation = handleValidation(fields);
		if (Object.keys(checkValidation).length === 0) {
			setLoading(true);
			let payload = getPayload(fields);
			payload.shop = shop;
			payload.promotionalButtonText_meta = fields.promotionalButtonText === 'other' ? fields.buttonOtherName : fields.promotionalButtonText;
			payload.promotionalMobileButtonText_meta = fields.promotionalMobileButtonText === 'other' ? fields.buttonMobileOtherName : fields.promotionalMobileButtonText;
			payload.offerProduct_meta = offerProduct;
			if (fields.offerType === 'cart-limit') {
				payload.cartLimit_meta = fields.cartLimit;
				payload.cartMax_meta = fields.cartMax;
			} else if (fields.offerType === 'specific-product') {
				payload.product_meta = requiredProduct;
			} else if (fields.offerType === 'collection-only') {
				payload.collection_meta = requiredCollection;
			}
			const url = id ? `incart-upsell/${id}` : `incart-upsell/create`;
			API.post(url, payload).then(response => {
				setLoading(false);
				setTooltip({
					content: `Incart Upsell ${id ? 'Updated' : 'Created'} Successfully`,
					active: true,
					error: false
				});
				redirectPage('incart-upsells');
			}).catch(err => {
				setLoading(false);
				setTooltip({
					content: err.response.data.message,
					active: true,
					error: true
				});
			});
		} else {
			setTooltip({
				content: "Please fill required fields",
				active: true,
				error: true
			});
		}
	};

	const deleteOffer = () => {
		setDeleteLoading(true);
		API.delete(`incart-upsell/${id}`).then(response => {
			setDeleteLoading(false);
			setTooltip({
				content: "Incart Upsell Deleted Successfully",
				active: true,
				error: false
			});
			redirectPage('incart-upsells');
		}).catch(err => {
			setDeleteLoading(false);
			setTooltip({
				content: err.response.data.message,
				active: true,
				error: true
			});
		});
	};

	const thumbImage = offerProduct && offerProduct.images[0] ? offerProduct.images[0].originalSrc : dummyImage;

	const copyToClipboard = () => {
		var textField = document.createElement('textarea')
		textField.innerText = htmlCode;
		document.body.appendChild(textField)
		textField.select()
		document.execCommand('copy');
		textField.remove();
		setButtonText('Copied');
	};

	const showTitleDescp = () => {
		if (fields.displayProductTitle && offerProduct) {
			if ((fields.promotionalMobileText && props.isMobile()) || !isFirstButtonActive) {
				return (<><b>{offerProduct.title}</b><span> - {fields.promotionalMobileText}</span></>);
			} else if (fields.promotionalDesktopText) {
				return (<><b>{offerProduct.title}</b><span> - {fields.promotionalDesktopText}</span></>);
			}
			return (<b>{offerProduct.title}</b>);
		}
		return (props.isMobile() || !isFirstButtonActive ? fields.promotionalMobileText : fields.promotionalDesktopText);
	};

	const showPrice = () => {
		if (fields.displayProductPrice) {
			if (fields.displayProductSalePrice && offerProduct && fields.newPrice) {
				return (<b> ${offerProduct?.variants[0]?.price} <span className="compare-price">${fields.newPrice}</span></b>);
			} else if (offerProduct) {
				return (<b> ${offerProduct?.variants[0]?.price}</b>);
			}
			return null;
		}
		return null;
	};

	const handleLimitModalPreview = useCallback(() => setLimitModalPreview(!limitModalPreview), [limitModalPreview]);

	const getOfferTextStyle = () => {
		let position = fields.promotionalTextPlacement;
		let padding = fields.promotionalTextPadding;
		if ((props.isMobile() || !isFirstButtonActive)) {
			position = fields.promotionalMobileTextPlacement;
			padding = fields.promotionalMobileTextPadding
		}
		const alignItems = position === 'up' ? 'flex-start' : (position === 'middle' ? 'center' : 'flex-end');
		return {
			alignItems: alignItems,
			padding: padding
		}
	};

	const getPromoTextStyle = () => {
		return {
			color: (props.isMobile() || !isFirstButtonActive) ? fields.promotionalMobileTextColor : fields.promotionalTextColor,
			fontSize: (props.isMobile() || !isFirstButtonActive) ? fields.promotionalMobileTextFontSize : fields.promotionalFontSize,
			textAlign: (props.isMobile() || !isFirstButtonActive) ? fields.promotionalMobileTextAlignment : fields.promotionalTextAlignment
		}
	};

	const getButtonStyle = () => {
		return {
			backgroundColor: (props.isMobile() || !isFirstButtonActive) ? fields.promotionalMobileButtonBgColor : fields.promotionalButtonBgColor,
			fontSize: (props.isMobile() || !isFirstButtonActive) ? fields.promotionalMobileButtonFontSize : fields.promotionalButtonFontSize,
			color: (props.isMobile() || !isFirstButtonActive) ? fields.promotionalMobileButtonTextColor : fields.promotionalButtonTextColor
		}
	};
	const getButtonText = () => {
		if (props.isMobile() || !isFirstButtonActive) {
			return fields.promotionalMobileButtonText === 'other' ? fields.buttonMobileOtherName : fields.promotionalMobileButtonText;
		}
		return fields.promotionalButtonText === 'other' ? fields.buttonOtherName : fields.promotionalButtonText;
	};

	const getImageSize = () => {
		let size = fields.imageSizeDesktop;
		if (props.isMobile() || !isFirstButtonActive) {
			size = fields.imageSizeMobile;
		}
		return {
			width: size === 'medium' ? '120px' : (size === 'large' ? '160px' : '80px')
		};
	};

	const getUpsellAlignment = () => {
		let alignment = 'center';
		if (fields.upsellAlignment === 'left') {
			alignment = 'flex-start';
		} else if (fields.upsellAlignment === 'right') {
			alignment = 'flex-end';
		}

		return { justifyContent: alignment };
	};

	const [isFirstButtonActive, setIsFirstButtonActive] = useState(true);

	const handleFirstButtonClick = () => {
		if (isFirstButtonActive) return;
		setIsFirstButtonActive(true);
	};

	const handleSecondButtonClick = () => {
		if (!isFirstButtonActive) return;
		setIsFirstButtonActive(false);
	};

	const handleTabIndex = (index) => {
		setTabIndex(index);
	};

	const offerButtonClass = () => {
		if ((props.isMobile() || !isFirstButtonActive)) {
			return `OfferBtn apply-font-main4 ${fields.buttonSizeMobile}`;
		}
		return `OfferBtn apply-font-main2 ${fields.buttonSizeDesktop}`;
	};


	return (
		<div className="edit-coupon-ofr">
			<TabHeader />
			<Page
				title={title}
				primaryAction={<BackButton disable={disableBackButton} url="incart-upsells" />}
			>
				<Card sectioned>
					<div className="card-heading-with-toggle">
						<Heading>Preview</Heading>
						<ButtonGroup segmented>
							<Button pressed={isFirstButtonActive} onClick={handleFirstButtonClick} icon={<Icon source={DesktopMajorMonotone} />}>
								Desktop
							</Button>
							<Button pressed={!isFirstButtonActive} onClick={handleSecondButtonClick} icon={<Icon source={MobileMajorMonotone} color="indigo" />}>
								Mobile
							</Button>
						</ButtonGroup>
					</div>
					<div className={`incart-offer-wrapper m-t-10 ${(!isFirstButtonActive || props.isMobile()) ? 'mobile-preview' : ''}`} style={getUpsellAlignment()}>
						<div className={`offer_wrapper ${fields.upsellLayout}`} id="carBoost_incartLimit" style={fields.enableBgColor ? { backgroundColor: fields.promotionalBgColor, borderRadius: fields.roundBackgroundEdge } : {}}>
							<div className="image_wrap" style={getImageSize()}>
								<img src={thumbImage} alt="" />
							</div>
							<div className="inCart_desc_outr">
								<div className="offer_text" style={getOfferTextStyle()}>
									<p className={`apply-font-${(props.isMobile() || !isFirstButtonActive) ? 'main3' : 'main'}`} style={getPromoTextStyle()}>
										{showTitleDescp()}
										{showPrice()}
									</p>
								</div>
								<div className="button_wrapper">
									<button className={offerButtonClass()} style={getButtonStyle()}>{getButtonText()}</button>
								</div>
							</div>
						</div>
					</div>
				</Card>
				<Form noValidate onSubmit={handleSubmit}>
					<TabSettings handleTabIndex={handleTabIndex}>
						{tabIndex === 0 &&
							<FormLayout>
								<FormLayout.Group>
									<TextField
										label="Offer Name"
										value={fields.offerName}
										onChange={e => handleInputChange(e, 'offerName')}
										error={inputErrors.offerNameError}
									/>
									<Select
										label="Offer Type"
										options={offerTypeOptions}
										onChange={e => handleInputChange(e, 'offerType')}
										value={fields.offerType}
										disabled={id ? true : false}
									/>
								</FormLayout.Group>
								{fields.offerType === 'cart-limit' &&
									<FormLayout.Group>
										<TextField
											label="Cart Min Value:"
											value={fields.cartLimit}
											type="number"
											onChange={e => handleInputChange(e, 'cartLimit')}
											error={inputErrors.cartLimitError}
										/>
										<TextField
											type="number"
											value={fields.cartMax}
											label="Cart Max Value:"
											min={0}
											onChange={(e) => handleInputChange(e, 'cartMax')}
											error={inputErrors.cartMaxError}
										/>
									</FormLayout.Group>
								}
								{fields.offerType === 'specific-product' &&
									<FormLayout.Group>
										<div className="recommendation-offer-products">
											<Card title="Required Product">
												<ResourceList
													resourceName={{ singular: 'sale', plural: 'pname' }}
													items={requiredProduct}
													renderItem={(item) => {
														const { title, images, variants } = item;
														const image = images[0]?.originalSrc;
														const media = <Thumbnail
															source={image ? image : dummyImage}
															size="large"
															alt={title}
														/>;
														return (
															<div className="myprdctlist">
																{requiredProduct &&
																	<ResourceList.Item
																		media={media}
																		accessibilityLabel={`View pname for ${title}`}
																	>
																		<Stack>
																			<div className="pdesc">
																				<Stack.Item fill>{title}</Stack.Item>
																				<Stack.Item fill>{variants[0]?.title}</Stack.Item>
																				<Stack.Item>{variants[0]?.sku}</Stack.Item>
																			</div>
																			<div className="ptamount">
																				{variants[0]?.price && <Stack.Item>${variants[0]?.price}</Stack.Item>}
																			</div>
																		</Stack>
																	</ResourceList.Item>
																}
															</div>
														);
													}}
												/>
												<Button primary onClick={e => handleSelectedItemPicker('required-product')}>Select Product</Button>
												{inputErrors.productError &&
													showInputError()
												}
											</Card>
										</div>
									</FormLayout.Group>
								}
								{fields.offerType === 'collection-only' &&
									<FormLayout.Group>
										<Card title="Required Collection">
											<ResourceList
												resourceName={{ singular: 'sale', plural: 'pname' }}
												items={[
													{
														title: requiredCollection?.title,
														image: requiredCollection?.image?.originalSrc
													}
												]}
												renderItem={(item) => {
													const { title, image } = item;
													const media = <Thumbnail
														source={image ? image : dummyImage}
														size="large"
														alt={title}
													/>;
													return (
														<div className="myprdctlist">
															{requiredCollection &&
																<ResourceList.Item
																	media={media}
																	accessibilityLabel={`View pname for ${title}`}
																>
																	<div className="pdesc">
																		<Stack.Item fill>{title}</Stack.Item>
																	</div>
																</ResourceList.Item>
															}
															<Button primary onClick={e => handleSelectedItemPicker('required-collection')}>Select Collection</Button>
														</div>
													);
												}}
											/>
											{inputErrors.collectionError &&
												showInputError()
											}
										</Card>
									</FormLayout.Group>
								}
								<FormLayout.Group>
									<Card title="Offer Product">
										<ResourceList
											resourceName={{ singular: 'sale', plural: 'pname' }}
											items={[
												{
													title: offerProduct?.title,
													amount: '$' + offerProduct?.variants[0]?.price,
													pcate: offerProduct?.variants[0]?.title,
													psku: offerProduct?.variants[0]?.sku,
													qty: 1,
													tamount: '$' + offerProduct?.variants[0]?.price,
													image: offerProduct?.images[0]?.originalSrc
												}
											]}
											renderItem={(item) => {
												const { title, pcate, psku, tamount, image } = item;
												const media = <Thumbnail
													source={image ? image : dummyImage}
													size="large"
													alt={offerProduct?.title}
												/>;
												return (
													<div className="myprdctlist">
														{offerProduct &&
															<ResourceList.Item
																media={media}
																accessibilityLabel={`View pname for ${title}`}
															>
																<Stack>
																	<div className="pdesc">
																		<Stack.Item fill>{title}</Stack.Item>
																		<Stack.Item fill>{pcate}</Stack.Item>
																		<Stack.Item>{psku}</Stack.Item>
																	</div>
																	<div className="ptamount">
																		<Stack.Item>{tamount}</Stack.Item>
																	</div>
																</Stack>
															</ResourceList.Item>
														}
														<Button primary onClick={e => handleSelectedItemPicker('offer-product')}>Select Product</Button>
													</div>
												);
											}}
										/>
										{inputErrors.offerProductError &&
											showInputError()
										}
									</Card>
								</FormLayout.Group>
								<FormLayout.Group>
									<Checkbox
										label="Show Product Title"
										checked={fields.displayProductTitle}
										onChange={e => handleInputChange(e, 'displayProductTitle')}
									/>
									<Checkbox
										label="Show Price"
										checked={fields.displayProductPrice}
										onChange={e => handleInputChange(e, 'displayProductPrice')}
									/>
								</FormLayout.Group>
								<FormLayout.Group>
									<Checkbox
										label="Background Color"
										checked={fields.enableBgColor}
										onChange={e => handleInputChange(e, 'enableBgColor')}
									/>
									<Checkbox
										label="Auto-add Incart Upsell"
										checked={fields.autoAddUpsell}
										onChange={e => handleInputChange(e, 'autoAddUpsell')}
									/>
								</FormLayout.Group>
								{fields.enableBgColor &&
									<FormLayout.Group>
										<TextField
											prefix="#"
											onChange={e => handleColorChange(e, 'promotionalBgColor')}
											value={colorWithoutHash(fields.promotionalBgColor)}
											suffix={<ColorChoser
												field="promotionalBgColor"
												handleColorInput={handleInputChange}
												color={fields.promotionalBgColor}
											/>
											}
										/>
										<></>
									</FormLayout.Group>
								}
								<FormLayout.Group>
									<Checkbox
										label="Apply Limit"
										checked={fields.applyLimit}
										onChange={e => handleInputChange(e, 'applyLimit')}
									/>
									<Checkbox
										label="Remove Offered Product From Cart If Condition Not Met"
										checked={fields.removeOfferProduct}
										onChange={e => handleInputChange(e, 'removeOfferProduct')}
									/>
								</FormLayout.Group>
								{fields.applyLimit &&
									<FormLayout.Group>
										<Select
											value={fields.freeProduct}
											options={[
												{ label: 'Limit 1', value: 'limit' },
												{ label: 'Free', value: 'free' },
											]}
											placeholder="Select an option"
											onChange={e => handleInputChange(e, 'freeProduct')}
											error={inputErrors.freeProductError}
											helpText={<span>For more information view the <Link onClick={e => handleLimitModalPreview()}>documentation</Link>.</span>}
										/>
										<></>
									</FormLayout.Group>
								}
								{fields.displayProductPrice &&
									<FormLayout.Group>
										<Checkbox
											label="Show Compare at Price"
											checked={fields.displayProductSalePrice}
											onChange={e => handleInputChange(e, 'displayProductSalePrice')}
										/>
										{fields.displayProductSalePrice && fields.displayProductPrice &&
											<TextField
												type="number"
												min="0"
												value={fields.newPrice}
												onChange={e => handleInputChange(e, 'newPrice')}
												error={inputErrors.newPriceError}
												prefix={'$'}
											/>
										}
									</FormLayout.Group>
								}
								<FormLayout.Group>
									<TextField
										label="Show Upsell If Customer Have Tag(s)"
										value={fields.showTags}
										onChange={e => handleInputChange(e, 'showTags')}
										helpText="Multiple tags can be entered by separting commas, For example:- Test, Test1"
									/>
									<TextField
										label="Hide Upsell If Customer Have Tag(s)"
										value={fields.hideTags}
										onChange={e => handleInputChange(e, 'hideTags')}
										helpText="Multiple tags can be entered by separting commas, For example:- Test, Test1"
									/>
								</FormLayout.Group>
								<FormLayout.Group>
									<Select
										label="Upsell Placement"
										value={fields.upsellPlacement}
										options={upsellPlacementOptions}
										onChange={e => handleInputChange(e, 'upsellPlacement')}
									/>
									<Select
										label="Upsell Alignment"
										value={fields.upsellAlignment}
										options={textAlignmentOptions}
										onChange={e => handleInputChange(e, 'upsellAlignment')}
									/>
								</FormLayout.Group>
								{fields.upsellPlacement === 'custom-positon' &&
									<FormLayout.Group>
										<Checkbox
											label="Ajax Cart"
											checked={fields.ajaxCart}
											onChange={e => handleInputChange(e, 'ajaxCart')}
										/>
									</FormLayout.Group>
								}
								<FormLayout.Group>
									<RangeSlider
										output
										label="Round Background Edge"
										min={0}
										max={25}
										step={1}
										value={fields.roundBackgroundEdge}
										onChange={e => handleInputChange(e, 'roundBackgroundEdge')}
										prefix={<p>0px</p>}
										suffix={<p>25px</p>}
									/>
								</FormLayout.Group>
								{fields.upsellPlacement === 'custom-positon' &&
									<>
										<Banner
											title="Custom placement"
											action={{ content: copyButtonText, onClick: copyToClipboard }}
											status="info"
										>
											<p>Copy the html code and place it where you want to show the offer on cart page. <TextStyle variation="code"> {htmlCode} </TextStyle></p>
										</Banner>
									</>
								}
							</FormLayout>
						}
						{tabIndex === 1 &&
							<FormLayout>
								<TextField
									multiline={1}
									label="Promotional Text"
									value={fields.promotionalDesktopText}
									onChange={e => handleInputChange(e, 'promotionalDesktopText')}
									maxLength={300}
									showCharacterCount
								/>
								<FormLayout.Group>
									<TextField
										prefix="#"
										label="Text Color"
										onChange={e => handleColorChange(e, 'promotionalTextColor')}
										value={colorWithoutHash(fields.promotionalTextColor)}
										suffix={<ColorChoser
											field="promotionalTextColor"
											handleColorInput={handleInputChange}
											color={fields.promotionalTextColor}
										/>
										}
									/>
									<Select
										label="Placement"
										value={fields.promotionalTextPlacement}
										options={TextPlacementOptions}
										onChange={e => handleInputChange(e, 'promotionalTextPlacement')}
									/>
								</FormLayout.Group>
								<FormLayout.Group>
									<FontFamilyPicker
										pickerId="main"
										label="Text Font Family"
										activeFont={fields.promotionalTextFontFamily}
										field="promotionalTextFontFamily"
										handleFontFamilyInput={handleInputChange}
									/>
									<Select
										label="Text Alignment"
										value={fields.promotionalTextAlignment}
										options={textAlignmentOptions}
										onChange={e => handleInputChange(e, 'promotionalTextAlignment')}
									/>
								</FormLayout.Group>
								<FormLayout.Group>
									<RangeSlider
										output
										label="Text Font Size"
										min={10}
										max={30}
										step={1}
										value={fields.promotionalFontSize}
										onChange={e => handleInputChange(e, 'promotionalFontSize')}
										prefix={<p>10px</p>}
										suffix={<p>30px</p>}
									/>
									<RangeSlider
										output
										label="Text Padding"
										min={0}
										max={20}
										step={1}
										value={fields.promotionalTextPadding}
										onChange={e => handleInputChange(e, 'promotionalTextPadding')}
										prefix={<p>0px</p>}
										suffix={<p>20px</p>}
									/>
								</FormLayout.Group>
								<FormLayout.Group>
									<div className="group-input-handle">
										<FormLayout.Group condensed>
											<Select
												label="Button Text"
												value={fields.promotionalButtonText}
												options={buttonOptions}
												onChange={e => handleInputChange(e, 'promotionalButtonText')}
											/>
											{fields.promotionalButtonText === 'other' &&
												<TextField
													label="Other"
													value={fields.buttonOtherName}
													maxLength="25"
													onChange={e => handleInputChange(e, 'buttonOtherName')}
													error={inputErrors.deskopButtonOtherNameError}
												/>
											}
										</FormLayout.Group>
									</div>
									<FontFamilyPicker
										pickerId="main2"
										label="Button Font Family"
										activeFont={fields.promotionalButtonFontFamily}
										field="promotionalButtonFontFamily"
										handleFontFamilyInput={handleInputChange}
									/>
								</FormLayout.Group>
								<FormLayout.Group>
									<TextField
										prefix="#"
										label="Button Text Color"
										value={colorWithoutHash(fields.promotionalButtonTextColor)}
										suffix={<ColorChoser
											field="promotionalButtonTextColor"
											handleColorInput={handleInputChange}
											color={fields.promotionalButtonTextColor}
										/>
										}
									/>
									<TextField
										prefix="#"
										label="Button Background Color"
										onChange={e => handleColorChange(e, 'promotionalButtonBgColor')}
										value={colorWithoutHash(fields.promotionalButtonBgColor)}
										suffix={<ColorChoser
											field="promotionalButtonBgColor"
											handleColorInput={handleInputChange}
											color={fields.promotionalButtonBgColor}
										/>
										}
									/>
								</FormLayout.Group>
								<FormLayout.Group>
									<RangeSlider
										output
										label="Button Font Size"
										min={10}
										max={20}
										step={1}
										value={fields.promotionalButtonFontSize}
										onChange={e => handleInputChange(e, 'promotionalButtonFontSize')}
										prefix={<p>10px</p>}
										suffix={<p>20px</p>}
									/>
									<Select
										label="Button Size"
										value={fields.buttonSizeDesktop}
										options={buttonSizeOptions}
										onChange={e => handleInputChange(e, 'buttonSizeDesktop')}
									/>
								</FormLayout.Group>
								<FormLayout.Group>
									<Select
										label="Image Size"
										value={fields.imageSizeDesktop}
										options={buttonSizeOptions}
										onChange={e => handleInputChange(e, 'imageSizeDesktop')}
									/>
									<></>
								</FormLayout.Group>
							</FormLayout>
						}
						{tabIndex === 2 &&
							<FormLayout>
								<TextField
									multiline={1}
									label="Promotional Text"
									value={fields.promotionalMobileText}
									onChange={e => handleInputChange(e, 'promotionalMobileText')}
									maxLength={300}
									showCharacterCount
								/>
								<FormLayout.Group>
									<TextField
										prefix="#"
										label="Text Color"
										onChange={e => handleColorChange(e, 'promotionalMobileTextColor')}
										value={colorWithoutHash(fields.promotionalMobileTextColor)}
										suffix={<ColorChoser
											field="promotionalMobileTextColor"
											handleColorInput={handleInputChange}
											color={fields.promotionalMobileTextColor}
										/>
										}
									/>
									<Select
										label="Placement"
										value={fields.promotionalMobileTextPlacement}
										options={TextPlacementOptions}
										onChange={e => handleInputChange(e, 'promotionalMobileTextPlacement')}
									/>
								</FormLayout.Group>
								<FormLayout.Group>
									<FontFamilyPicker
										pickerId="main3"
										label="Text Font Family"
										activeFont={fields.promotionalMobileTextFontFamily}
										field="promotionalMobileTextFontFamily"
										handleFontFamilyInput={handleInputChange}
									/>
									<Select
										label="Text Alignment"
										value={fields.promotionalMobileTextAlignment}
										options={textAlignmentOptions}
										onChange={e => handleInputChange(e, 'promotionalMobileTextAlignment')}
									/>
								</FormLayout.Group>
								<FormLayout.Group>
									<RangeSlider
										output
										label="Text Font Size"
										min={10}
										max={30}
										step={1}
										value={fields.promotionalMobileTextFontSize}
										onChange={e => handleInputChange(e, 'promotionalMobileTextFontSize')}
										prefix={<p>10px</p>}
										suffix={<p>30px</p>}
									/>
									<RangeSlider
										output
										label="Text Padding"
										min={0}
										max={20}
										step={1}
										value={fields.promotionalMobileTextPadding}
										onChange={e => handleInputChange(e, 'promotionalMobileTextPadding')}
										prefix={<p>0px</p>}
										suffix={<p>20px</p>}
									/>
								</FormLayout.Group>
								<FormLayout.Group>
									<div className="group-input-handle">
										<FormLayout.Group condensed>
											<Select
												label="Button Text"
												value={fields.promotionalMobileButtonText}
												options={buttonOptions}
												onChange={e => handleInputChange(e, 'promotionalMobileButtonText')}
											/>
											{fields.promotionalMobileButtonText === 'other' &&
												<TextField
													label="Other"
													value={fields.buttonMobileOtherName}
													maxLength="25"
													onChange={e => handleInputChange(e, 'buttonMobileOtherName')}
													error={inputErrors.mobileButtonOtherNameError}
												/>
											}
										</FormLayout.Group>
									</div>
									<FontFamilyPicker
										pickerId="main4"
										label="Button Font Family"
										activeFont={fields.promotionalMobileButtonFontFamily}
										field="promotionalMobileButtonFontFamily"
										handleFontFamilyInput={handleInputChange}
									/>
								</FormLayout.Group>
								<FormLayout.Group>
									<TextField
										prefix="#"
										label="Button Text Color"
										value={colorWithoutHash(fields.promotionalMobileButtonTextColor)}
										suffix={<ColorChoser
											field="promotionalMobileButtonTextColor"
											handleColorInput={handleInputChange}
											color={fields.promotionalMobileButtonTextColor}
										/>
										}
									/>
									<TextField
										prefix="#"
										label="Button Background Color"
										onChange={e => handleColorChange(e, 'promotionalMobileButtonBgColor')}
										value={colorWithoutHash(fields.promotionalMobileButtonBgColor)}
										suffix={<ColorChoser
											field="promotionalMobileButtonBgColor"
											handleColorInput={handleInputChange}
											color={fields.promotionalMobileButtonBgColor}
										/>
										}
									/>
								</FormLayout.Group>
								<FormLayout.Group>
									<RangeSlider
										output
										label="Button Font Size"
										min={10}
										max={20}
										step={1}
										value={fields.promotionalMobileButtonFontSize}
										onChange={e => handleInputChange(e, 'promotionalMobileButtonFontSize')}
										prefix={<p>10px</p>}
										suffix={<p>20px</p>}
									/>
									<Select
										label="Button Size"
										value={fields.buttonSizeMobile}
										options={buttonSizeOptions}
										onChange={e => handleInputChange(e, 'buttonSizeMobile')}
									/>

								</FormLayout.Group>
								<FormLayout.Group>
									<Select
										label="Image Size"
										value={fields.imageSizeMobile}
										options={buttonSizeOptions}
										onChange={e => handleInputChange(e, 'imageSizeMobile')}
									/>
									<></>
								</FormLayout.Group>
							</FormLayout>
						}
						{tabIndex === 3 &&
							<FormLayout>
								<FormLayout.Group>
									<Checkbox
										label="Enable Custom CSS"
										checked={fields.enableCustomCss}
										onChange={e => handleInputChange(e, 'enableCustomCss')}
									/>
								</FormLayout.Group>
								<FormLayout.Group>
									<TextField
										multiline={8}
										disabled={fields.enableCustomCss ? false : true}
										placeholder="Enter Custom CSS Here..."
										value={fields.customCss}
										onChange={e => handleInputChange(e, 'customCss')}
										error={inputErrors.customCss}
									/>
								</FormLayout.Group>
								<CssHelper />
							</FormLayout>
						}
					</TabSettings>
					<div className="button_wrap m-t-30">
						<Button primary submit loading={isLoading}>Save</Button>
						{id && <Button destructive onClick={deleteOffer} loading={deleteLoading}>Delete</Button>}
					</div>
					{/* Just added all fonts here so that all fonts can be loaded for preview */}
					<div style={{ display: 'none' }}>
						<FontFamilyPicker
							pickerId="main"
							label="Text Font Family"
							activeFont={fields.promotionalTextFontFamily}
							field="promotionalTextFontFamily"
							handleFontFamilyInput={handleInputChange}
						/>
						<FontFamilyPicker
							pickerId="main2"
							label="Button Font Family"
							activeFont={fields.promotionalButtonFontFamily}
							field="promotionalButtonFontFamily"
							handleFontFamilyInput={handleInputChange}
						/>
						<FontFamilyPicker
							pickerId="main3"
							label="Text Font Family"
							activeFont={fields.promotionalMobileTextFontFamily}
							field="promotionalMobileTextFontFamily"
							handleFontFamilyInput={handleInputChange}
						/>
						<FontFamilyPicker
							pickerId="main4"
							label="Button Font Family"
							activeFont={fields.promotionalMobileButtonFontFamily}
							field="promotionalMobileButtonFontFamily"
							handleFontFamilyInput={handleInputChange}
						/>
					</div>
				</Form>
				{shop &&
					<>
						{selectedItem === 'required-collection' ? (
							<ResourcePicker
								resourceType="Collection"
								open={openPicker}
								allowMultiple={false}
								onSelection={(resources) => handleItemSelection(resources)}
								onCancel={() => setOpenPicker(false)}
								initialSelectionIds={getSelectedIds()}
							/>
						) : (
							<ItemPicker
								resourceType="Product"
								openPicker={openPicker}
								allowMultiple={selectedItem === 'required-product' ? 8 : false}
								handleItemSelection={handleItemSelection}
								setOpenPicker={setOpenPicker}
								selectedItem={getSelectedIds()}
							/>
						)}
					</>
				}
				<CustomTooltip tooltip={toolTip} />
			</Page>
			<Modal
				open={limitModalPreview}
				onClose={handleLimitModalPreview}
				title="Support"
				secondaryActions={[
					{
						content: 'Close',
						onAction: handleLimitModalPreview,
					},
				]}
			>
				<Modal.Section>
					<TextContainer>
						<p>Please add support@cartboost.io as a collaborator for custom installation. <br /><br /> Please email support@cartboost.io or chat with us for immediate assistance.</p><br />

						<p>Access the theme file that contains the cart items. Depending on your theme, you may need to access either the cart.liquid or the page-cart.liquid files.</p>
						<p>
							On occasion, the file name may be listed as something different. If you do not see one of the options above, and are unsure where to find the cart page, it's recommended to contact the theme developer for support.
						</p>
						<p>Once in the correct theme file, look for the Quantity column within the file.
							You'll need to add the code below to the main parent HTML element of Quantity Column</p>
						<TextStyle variation="code">{limitCode}</TextStyle>
						<p>If using HTML with Table Layout, it will look like this: </p>
						<TextStyle variation="code">{tdCode}</TextStyle>
						<p>If using HTML with Div Layout, it will look like this:</p>
						<TextStyle variation="code">{divCode}</TextStyle>
					</TextContainer>
				</Modal.Section>
			</Modal>
		</div>
	);
}

export default withGetScreen(IncartUpsell);