import React, { useEffect, useState } from 'react';
import { Card, Page, Badge, ButtonGroup, Button, DisplayText, Icon, Stack } from '@shopify/polaris';
import TabHeader from '../../common/TabHeader';
import API from '../../service/Api';
import Tooltip from '../../common/Tooltip';
import EmptyOffer from '../EmptyOffer';
import Loader from '../../common/Loader';
import {
    PlusMinor,
} from '@shopify/polaris-icons';
import { redirectPage, getStoreName } from '../../common/Helper';

export default function TabsExample() {
    const shop = getStoreName();
    const [isLoading, setLoading] = useState(null);
    const [deleteLoding, setDeleteLoding] = useState(null);
    const [offers, setOffers] = useState([]);
    const [skeletonLoader, setSkeletonLoader] = useState(true);
    const [toolTip, setTooltip] = useState({
        active: false,
        content: null,
        error: false
    });
    // get offers from db
    useEffect(() => {
        // request from db
        API.get(`trust-badge/fetch`).then(response => {
            setOffers(response.data.data);
            setSkeletonLoader(false);
        }).catch(err => {
            setSkeletonLoader(false);
        });
    }, [shop]);

    const deleteOffer = (id) => {
        setDeleteLoding(id);
        API.delete(`trust-badge/${id}`).then(response => {
            setTooltip({
                content: "Trust Badge Deleted Successfully",
                active: true,
                error: false
            });
            const allOffers = [...offers];
            const newOffers = allOffers.filter(x => x.id !== id);
            setOffers(newOffers);
            setDeleteLoding(null);
        }).catch(err => {
            setDeleteLoding(null);
            setTooltip({
                content: err.response.data.message,
                active: true,
                error: true
            });
        });
    };

    const changeStatus = (id) => {
        setLoading(id);
        const allOffers = [...offers];
        const index = allOffers.findIndex(x => x.id === id);
        const url = allOffers[index].status === 0 ? 'activate' : 'deactivate';
        API.post(`trust-badge/${url}/${id}`).then(response => {
            allOffers[index].status = allOffers[index].status === 0 ? 1 : 0;
            setOffers(allOffers);
            setLoading(null);
            setTooltip({
                content: "Status Changed Successfully",
                active: true,
                error: false
            });
        }).catch(err => {
            setLoading(null);
            setTooltip({
                content: err.response.data.message,
                active: true,
                error: true
            });
        });
    };


    return (
        <div className='upsells-outr'>
            <TabHeader />
            <Page
                title="Trust Badge"
                primaryAction={
                    <Button primary icon={<Icon source={PlusMinor} />} onClick={e => redirectPage('create-badge')}>
                        Create Badge
    				</Button>
                }
            >
                {offers.map((offer, index) => {
                    return (
                        <Card sectioned key={index}>
                            <Stack alignment="center">
                                <Stack.Item fill>
                                    <DisplayText size="small">
                                        {offer.title}
                                        {offer.status === 1 && <Badge status="success">Active</Badge>}
                                    </DisplayText>
                                </Stack.Item>
                                <ButtonGroup>
                                    <Button loading={isLoading === offer.id ? true : false} onClick={e => changeStatus(offer.id)}>
                                        {offer.status === 1 ? 'Deactivate' : 'Activate'}
                                    </Button>
                                    {offer.status !== 1 &&
                                        <Button primary onClick={e => redirectPage(`edit-badge/${offer.id}`)}>Edit</Button>
                                    }
                                    {offer.status !== 1 &&
                                        <Button destructive onClick={e => deleteOffer(offer.id)} loading={deleteLoding === offer.id ? true : false}>
                                            Delete
										</Button>
                                    }
                                </ButtonGroup>
                            </Stack>
                        </Card>
                    )
                })}
                {offers.length === 0 && skeletonLoader === false &&
                    <EmptyOffer url="create-badge" buttonText="Create Badge" />
                }
                {skeletonLoader &&
                    <Loader />
                }
                <Tooltip tooltip={toolTip} />
            </Page>
        </div>
    );
}

