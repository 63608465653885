import React, { useEffect, useState } from 'react';
import { Card, Page, Badge, Stack, ButtonGroup, Button, DisplayText, Icon, Banner, Spinner } from '@shopify/polaris';
import TabHeader from '../../common/TabHeader';
import API from '../../service/Api';
import Tooltip from '../../common/Tooltip';
import EmptyOffer from '../EmptyOffer';
import Loader from '../../common/Loader';
import { PlusMinor } from '@shopify/polaris-icons';
import { redirectPage, getStoreName } from '../../common/Helper';

export default function Index() {
    const shop = getStoreName();
    const [offers, setOffers] = useState([]);
    const [skeletonLoader, setSkeletonLoader] = useState(true);
    const [isLoading, setLoading] = useState(null);
    const [deleteLoding, setDeleteLoding] = useState(null);
    const [bannerLoding, setBannerLoding] = useState(false);
    const [activeSales, setActiveSales] = useState([]);
    const [toolTip, setTooltip] = useState({
        active: false,
        content: null,
        error: false
    });
    // get offers from db
    useEffect(() => {
        function fetchOffers() {
            API.get(`sales-boost/fetch`).then(response => {
                setOffers(response.data.data);
                const activeSales = response.data.data.filter(x => x.status === 1);
                setActiveSales(activeSales);
                setSkeletonLoader(false);
            }).catch(err => {
                setSkeletonLoader(false);
            });
        }
        if (offers.length) {
            const loading = offers.find(x => x.processing === 'loading');
            if (loading) {
                setBannerLoding(true);
                let id = setInterval(fetchOffers, 10000);
                return () => clearInterval(id);
            } else {
                setBannerLoding(false);
            }
        } else {
            if (skeletonLoader === true && shop) {
                fetchOffers();
            }
        }
    }, [offers, shop, skeletonLoader]);

    const changeStatus = (offerId) => {
        setLoading(offerId);
        const allOffers = [...offers];
        const index = allOffers.findIndex(x => x.id === offerId);
        const url = allOffers[index].status === 0 ? 'activate' : 'deactivate';
        allOffers[index].status = allOffers[index].status === 0 ? 1 : 0;
        allOffers[index].processing = 'loading';
        setOffers(allOffers);
        API.post(`sales-boost/${url}/${offerId}`).then(response => {
            setLoading(null);
        }).catch(err => {
            setLoading(null);
            setTooltip({
                content: err.response.data.message,
                active: true,
                error: true
            });
        });
    };

    const deleteOffer = (id) => {
        setDeleteLoding(id);
        API.delete(`sales-boost/${id}`).then(response => {
            setTooltip({
                content: "SalesBoost Deleted Successfully",
                active: true,
                error: false
            });
            const allOffers = [...offers];
            const newOffers = allOffers.filter(x => x.id !== id);
            setOffers(newOffers);
            setDeleteLoding(null);
        }).catch(err => {
            setDeleteLoding(null);
            setTooltip({
                content: err.response.data.message,
                active: true,
                error: true
            });
        });
    };
    
    return (
        <div className='upsells-outr'>
            <TabHeader />
            <Page
                title="Sales"
                primaryAction={
                    <Button primary icon={<Icon source={PlusMinor} />} onClick={e => redirectPage('create-sale')}>
                        Create New Sale
                    </Button>
                }
            >
                {activeSales.length > 0 && 
                    <Banner title="Warning" status="warning">
                    <p>Note: Overlapping SalesBoost Offers will result in stacked discount. Please ensure to update any overlapping entires manually via Shopify. </p>
                    </Banner>
                }
                {bannerLoding &&
                    <Banner
                        title="Your sales are updating, please wait"
                        status="info"
                    >
                        <p>
                            <Spinner size="small" color="teal" />
                            Please wait to make any changes until all of your sales have finished updating.
                        </p>
                    </Banner>
                }
                <div className="m-t-30">
                    {offers.map((offer, index) => {
                        return (
                            <Card sectioned key={index}>
                                <Stack alignment="center">
                                    <Stack.Item fill>
                                        <DisplayText size="small">
                                            {offer.title}
                                            {offer.status === 1 && offer.processing === 'done' && <Badge status="success">Active</Badge>}
                                        </DisplayText>
                                    </Stack.Item>
                                    {offer.processing === 'loading' &&
                                        <ButtonGroup>
                                            <Spinner size="small" color="inkLightest" />
                                        </ButtonGroup>
                                    }
                                    {offer.processing === 'done' && 
                                        <ButtonGroup>
                                            <Button loading={isLoading === offer.id ? true : false} onClick={e => changeStatus(offer.id)}>
                                                {offer.status === 1 ? 'Deactivate' : 'Activate'}
                                            </Button>
                                            {offer.status !== 1 &&
                                                <Button primary onClick={e => redirectPage(`edit-sale/${offer.id}`)}>Edit</Button>
                                            }
                                            {offer.status !== 1 &&
                                                <Button destructive onClick={e => deleteOffer(offer.id)} loading={deleteLoding === offer.id ? true : false}>
                                                    Delete
                                                </Button>
                                            }
                                        </ButtonGroup>
                                    }
                                </Stack>
                            </Card>
                        )
                    })}
                </div>
                {offers.length === 0 && skeletonLoader === false &&
                    <EmptyOffer url="create-sale" buttonText="Create New Sale" />
                }
                {skeletonLoader &&
                    <Loader />
                }
                <Tooltip tooltip={toolTip} />
            </Page>
        </div>
    );
}

