import React from 'react';
import { Card } from '@shopify/polaris';
import ReactWOW from 'react-wow';
import '../../assets/animate.css';

export default function Preview(props) {
    const { fields, trustBadges, advanceBadges } = props;
    const badgeAlginment = fields.badgeAlginment === 'left' ? 'flex-start' : (fields.badgeAlginment === 'right' ? 'flex-end' : fields.badgeAlginment);
    return (
        <Card title="Preview" sectioned>
            <div className="badge-final-preview">
                <div className="add-to-cart-button">
                    <ReactWOW animateClass="wow" animation={fields.buttonAnimation} duration="2s" iteration="10000">
                        <button className="add-cart-btn">Add To Cart</button>
                    </ReactWOW>
                </div>
                {fields.badgeToggle &&
                    <>
                        <div className="message apply-font-main"
                            style={{ color: fields.messageColor, fontSize: fields.messageFontSize + 'px', textAlign: fields.badgeAlginment }}>
                            {fields.message}
                        </div>
                        <div className="trust-badges-wrapper" style={{ justifyContent: badgeAlginment }}>
                            {trustBadges.map((item, index) => (
                                <div className="selected-payment-icons" key={index}>
                                    <i className={`badge-icon pf ${item.name}`} style={{ color: fields.badgesColor }} />
                                </div>
                            ))}
                        </div>
                    </>
                }
                {fields.advanceBadgeToggle &&
                    <div className="badge-images-wrapper" style={{ justifyContent: badgeAlginment }}>
                        {advanceBadges.map((image, index) => (
                            <img key={index} className="badge-image" alt={image.name} src={image.src ? image.src : window.URL.createObjectURL(image)} />
                        ))}
                    </div>
                }
            </div>
        </Card>
    )
}
