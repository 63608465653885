import React, { useEffect, useState } from 'react';
import { Card, Page, Stack, ButtonGroup, Button, Icon, Badge } from '@shopify/polaris';
import TabHeader from '../../common/TabHeader';
import API from '../../service/Api';
import Tooltip from '../../common/Tooltip';
import EmptyOffer from '../EmptyOffer';
import Loader from '../../common/Loader';
import { PlusMinor } from '@shopify/polaris-icons';
import { redirectPage, getStoreName } from '../../common/Helper';

export default function Index() {
    const shop = getStoreName();
    const [offers, setOffers] = useState([]);
    const [skeletonLoader, setSkeletonLoader] = useState(true);
    const [isLoading, setLoading] = useState(null);
    const [deleteLoding, setDeleteLoding] = useState(null);
    const [toolTip, setTooltip] = useState({
        active: false,
        content: null,
        error: false
    });
    // get offers from db
    useEffect(() => {
        API.get(`timer-boost/fetch`).then(response => {
            setOffers(response.data.data);
            setSkeletonLoader(false);
        }).catch(err => {
            setSkeletonLoader(false);
        });
    }, [shop]);

    const changeStatus = (offerId) => {
        setLoading(offerId);
        const allOffers = [...offers];
        const index = allOffers.findIndex(x => x.id === offerId);
        const url = allOffers[index].status === 0 ? 'activate' : 'deactivate';
        API.post(`timer-boost/${url}/${offerId}`).then(response => {
            allOffers[index].status = allOffers[index].status === 0 ? 1 : 0;
            setOffers(allOffers);
            setLoading(null);
            setTooltip({
                content: "Status Changed Successfully",
                active: true,
                error: false
            });
        }).catch(err => {
            setLoading(null);
            setTooltip({
                content: err.response.data.message,
                active: true,
                error: true
            });
        });
    };

    const deleteOffer = (id) => {
        setDeleteLoding(id);
        API.delete(`timer-boost/${id}`).then(response => {
            setTooltip({
                content: "TimerBoost Deleted Successfully",
                active: true,
                error: false
            });
            const allOffers = [...offers];
            const newOffers = allOffers.filter(x => x.id !== id);
            setOffers(newOffers);
            setDeleteLoding(null);
        }).catch(err => {
            setDeleteLoding(null);
            setTooltip({
                content: err.response.data.message,
                active: true,
                error: true
            });
        });
    };

    return (
        <div className='upsells-outr'>
            <TabHeader />
            <Page
                title="Messages"
                primaryAction={
                    <Button primary icon={<Icon source={PlusMinor} />} onClick={e => redirectPage('create-timer')}>
                        Create New Message
                    </Button>
                }
            >
                {offers.map((offer, index) => {
                    const metadata = offer.metadata;
                    return (
                        <Card key={index}>
                            <Card.Section>
                                {offer.title}
                                {offer.status === 1 && <Badge status="success">Active</Badge>}
                            </Card.Section>
                            <Card.Section>
                                <Stack alignment="center">
                                    <Stack.Item fill>
                                        <p>{metadata.message_meta}</p>
                                    </Stack.Item>
                                    <ButtonGroup>
                                        <Button loading={isLoading === offer.id ? true : false} onClick={e => changeStatus(offer.id)}>
                                            {offer.status === 1 ? 'Deactivate' : 'Activate'}
                                        </Button>
                                        {offer.status !== 1 &&
                                            <Button primary onClick={e => redirectPage(`edit-timer/${offer.id}`)}>Edit</Button>
                                        }
                                        {offer.status !== 1 &&
                                            <Button destructive onClick={e => deleteOffer(offer.id)} loading={deleteLoding === offer.id ? true : false}>
                                                Delete
                                            </Button>
                                        }
                                    </ButtonGroup>
                                </Stack>
                            </Card.Section>
                        </Card>
                    )
                })}
                {offers.length === 0 && skeletonLoader === false &&
                    <EmptyOffer url="create-timer" buttonText="Create New Message" />
                }
                {skeletonLoader &&
                    <Loader />
                }
                <Tooltip tooltip={toolTip} />
            </Page>
        </div>
    );
}

