import React, { useState, useCallback, useEffect } from 'react';
import { Frame, Toast } from '@shopify/polaris';

export default function Tooltip({ tooltip }) {
    const [active, setActive] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        setActive(tooltip.active);
        setError(tooltip.error);
    }, [tooltip]);

    const toggleActive = useCallback(() => setActive((active) => !active), []);

    return (
        <div className="Polaris-Frame-No-Height">
            {active && <Frame>
                <Toast content={tooltip.content} error={error} onDismiss={toggleActive} />
            </Frame>
            }
        </div>
    );
}
