import React, { useEffect, useState, useCallback } from 'react';
import { Card, Page, TextStyle, Button, Stack, DisplayText, ButtonGroup, Popover, ActionList, Icon, Badge } from '@shopify/polaris';
import TabHeader from '../../common/TabHeader';
import API from '../../service/Api';
import Tooltip from '../../common/Tooltip';
import EmptyOffer from '../EmptyOffer';
import Loader from './../../common/Loader';
import { 
	PlusMinor,
	ShipmentMajorMonotone,
	DiscountsMajorMonotone,
	ProductsMajorMonotone, 
} from '@shopify/polaris-icons';
import { redirectPage, getStoreName } from '../../common/Helper';

export default function TabsExample() {
	const [offers, setOffers] = useState([]);
	const [isLoading, setLoading] = useState(null);
	const [deleteLoding, setDeleteLoding] = useState(null);
	const [skeletonLoader, setSkeletonLoader] = useState(true);
	const [toolTip, setTooltip] = useState({
		active: false,
		content: null,
		error: false
	});
	const shop = getStoreName();
	// get offers from db
	useEffect(() => {
		API.get(`bar-upsell/fetch`).then(response => {
			setOffers(response.data.data);
			setSkeletonLoader(false);
		}).catch(err => {
			setSkeletonLoader(false);
		});
	}, [shop]);

	const changeStatus = (offerId) => {
		setLoading(offerId);
		const allOffers = [...offers];
		const index = allOffers.findIndex(x => x.id === offerId);
		const url = allOffers[index].offerStatus === 0 ? 'activate' : 'deactivate';
		API.post(`bar-upsell/${url}/${offerId}`).then(response => {
			allOffers[index].offerStatus = allOffers[index].offerStatus === 0 ? 1 : 0;
			setOffers(allOffers);
			setLoading(null);
			setTooltip({
				content: "Status Changed Successfully",
				active: true,
				error: false
			});
		}).catch(err => {
			setLoading(null);
			setTooltip({
				content: err.response.data.message,
				active: true,
				error: true
			});
		});
	};

	const deleteOffer = (id) => {
		setDeleteLoding(id);
		API.delete(`bar-upsell/${id}`).then(response => {
			setTooltip({
				content: "CartBar Upsell Deleted Successfully",
				active: true,
				error: false
			});
			const allOffers = [...offers];
			const newOffers = allOffers.filter(x => x.id !== id);
			setOffers(newOffers);
			setDeleteLoding(null);
		}).catch(err => {
			setDeleteLoding(null);
			setTooltip({
				content: err.response.data.message,
				active: true,
				error: true
			});
		});
	};

	const [active, setActive] = useState(false);

	const toggleActive = useCallback(() => setActive((active) => !active), []);

	const activator = (
		<Button onClick={toggleActive} disclosure primary icon={<Icon source={PlusMinor} />}>
			Add CartBar
		</Button>
	);

	return (
		<div className='upsells-outr'>
			<TabHeader />
			<Page
				title="CartBar Upsells"
				primaryAction={
					<Popover active={active} activator={activator} onClose={toggleActive}>
						<ActionList
							items={[
								{ content: 'Shipping CartBar', onAction: e => redirectPage('add-shipping-offer'), icon: ShipmentMajorMonotone },
								{ content: 'Coupon CartBar', onAction: e => redirectPage('add-coupon-offer'), icon: DiscountsMajorMonotone },
								{ content: 'Product CartBar', onAction: e => redirectPage('add-product-offer'), icon: ProductsMajorMonotone },
							]}
						/>
					</Popover>
				}
			>
				{offers.map((offer, index) => {
					const offerMetadata = JSON.parse(offer.barOptions);
					const editUrl = offer.offerType === 'free-shipping' ? 'shipping' : (offer.offerType === 'free-product' ? 'product' : 'coupon');
					return (
						<Card sectioned key={index}>
							<Stack alignment="center">
								<Stack.Item fill>
									<DisplayText size="small">
										{offer.offerName}
										{offer.offerStatus === 1 && <Badge status="success">Active</Badge>}
									</DisplayText>
									<TextStyle variation="subdued">{offerMetadata.description_meta}</TextStyle>
								</Stack.Item>
								<ButtonGroup>
									<Button loading={isLoading === offer.id ? true : false} onClick={e => changeStatus(offer.id)}>
										{offer.offerStatus === 1 ? 'Deactivate' : 'Activate'}
									</Button>
									{offer.offerStatus !== 1 &&
										<Button primary onClick={e => redirectPage(`edit-${editUrl}-offer/${offer.id}`)}>Edit</Button>
									}
									{offer.offerStatus !== 1 &&
										<Button destructive onClick={e => deleteOffer(offer.id)} loading={deleteLoding === offer.id ? true : false}>
											Delete
										</Button>
									}
									<Button primary onClick={e => redirectPage(`cartbar-analytics/${offer.id}`)}>Analytics</Button>
								</ButtonGroup>
							</Stack>
						</Card>
					)
				})}
				{offers.length === 0 && skeletonLoader === false &&
					<EmptyOffer url="add-shipping-offer" buttonText="Add CartBar" />
				}
				{skeletonLoader &&
					<Loader />
				}
				<Tooltip tooltip={toolTip} />
			</Page>
		</div>
	);
}

