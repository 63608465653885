import React from 'react'
import { Heading, Subheading, Card } from '@shopify/polaris';
import TabHeader from '../../common/TabHeader';
import AppMenu from '../../common/Menu';
import { withGetScreen } from 'react-getscreen';

function HomeScreen(props) {
    return (
        <div>
            <TabHeader />
            <div className="HomepageContent">
                <Heading >Welcome to Cartboost<sup>TM</sup></Heading>
                <Subheading>Choose an offer type to boost your cart</Subheading>
                {props.isMobile() ? (
                    <Card>
                        <AppMenu screen="mobile" />
                    </Card>
                ) : (
                    <AppMenu screen="desktop" />
                )}
            </div>
        </div>
    )
}

const options = { mobileLimit: 767, shouldListenOnResize: true };
export default withGetScreen(HomeScreen, options);