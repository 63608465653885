import React, { useState } from 'react';
import { Card, ButtonGroup, Button, Heading, Icon } from '@shopify/polaris';
import { dummyImage } from '../../common/Helper';
import { withGetScreen } from 'react-getscreen';
import {
    DesktopMajorMonotone,
    MobileMajorMonotone
} from '@shopify/polaris-icons';

function RecommendationPreview(props) {
    const { fields, products } = props;
    const [isFirstButtonActive, setIsFirstButtonActive] = useState(true);

    const getRecommendStyle = () => {
        return {
            fontSize: isFirstButtonActive ? fields.recommendationFontSize : fields.recommendationMobileFontSize,
            color: isFirstButtonActive ? fields.recommendationTextColor : fields.recommendationMobileTextColor
        }
    };

    const getDescpStyle = () => {
        return {
            fontSize: isFirstButtonActive ? fields.descriptionFontSize : fields.descriptionMobileFontSize,
            color: isFirstButtonActive ? fields.descriptionTextColor : fields.descriptionMobileTextColor
        }
    };

    const getTitleStyle = () => {
        return {
            fontSize: isFirstButtonActive ? fields.productTitleFontSize : fields.productTitleMobileFontSize,
            color: isFirstButtonActive ? fields.productTitleTextColor : fields.productTitleMobileTextColor
        }
    };

    const getPriceStyle = () => {
        return {
            fontSize: isFirstButtonActive ? fields.productPriceFontSize : fields.productPriceMobileFontSize,
            color: isFirstButtonActive ? fields.productPriceTextColor : fields.productPriceMobileTextColor
        }
    };

    const getButtonStyle = () => {
        return {
            backgroundColor: isFirstButtonActive ? fields.buttonBgColor : fields.buttonMobileBgColor,
            color: isFirstButtonActive ? fields.buttonTextColor : fields.buttonMobileTextColor
        }
    };

    const getImageStyle = () => {
        /* let data = {}
        if (fields.imageHeight) {
            data.height = fields.imageHeight + 'px';
        }
        if (fields.imageWidth) {
            data.width = fields.imageWidth + 'px';
        }

        return data; */
        let size = fields.imageSizeDesktop;
        if (props.isMobile() || !isFirstButtonActive) {
            size = fields.imageSizeMobile;
        }
        return {
            width: size === 'medium' ? '120px' : (size === 'large' ? '160px' : '80px'),
            height: size === 'medium' ? '120px' : (size === 'large' ? '160px' : '80px')
        };
    };

    const getButtonClass = () => {
        if (props.isMobile() || !isFirstButtonActive) {
            return `pro-Button apply-font-buttonMobile ` + fields.buttonSizeMobile;
        }

        return `pro-Button apply-font-button ` + fields.buttonSizeDesktop;
    };

    const handleFirstButtonClick = () => {
        if (isFirstButtonActive) return;
        setIsFirstButtonActive(true);
    };

    const handleSecondButtonClick = () => {
        if (!isFirstButtonActive) return;
        setIsFirstButtonActive(false);
    };

    const getInnerFlexWidht = () => {
        return { 
            maxWidth: (65 + Number(isFirstButtonActive ? fields.productSpacing : fields.productMobileSpacing)) + '%' 
        }
    };

    const getButtonText = () => {
        if (isFirstButtonActive) {
            return fields.buttonText === 'other' ? fields.buttonOtherName : fields.buttonText;
        }
        return fields.buttonMobileText === 'other' ? fields.buttonMobileOtherName : fields.buttonMobileText;
    };

    return (
        <Card sectioned>
            <div className="card-heading-with-toggle">
                <Heading>Preview</Heading>
                <ButtonGroup segmented>
                    <Button pressed={isFirstButtonActive} onClick={handleFirstButtonClick} icon={<Icon source={DesktopMajorMonotone} />}>
                        Desktop
							</Button>
                    <Button pressed={!isFirstButtonActive} onClick={handleSecondButtonClick} icon={<Icon source={MobileMajorMonotone} color="indigo" />}>
                        Mobile
							</Button>
                </ButtonGroup>
            </div>
            <div className={"flex-parent " + (fields.recommendationLocation === 'left' ? 'left-heading' : '') }>
                <div className="recommendation-text">
                    <div className={`main-heading apply-font-${isFirstButtonActive ? 'recommend' : 'recommendMobile'}`} style={getRecommendStyle()}>
                        {fields.recommendationText === 'other' ? fields.recommendOtherName : fields.recommendationText}
                    </div>
                    <div className={`sub-heading apply-font-${isFirstButtonActive ? 'description' : 'descriptionMobile'}`} style={getDescpStyle()}>{fields.description}</div>
                </div>
                <div className="inner-flex" style={getInnerFlexWidht()}>
                    {products.map((product, index) => {
                        const pimage = product?.images[0]?.originalSrc;
                        return (
                            <div key={index} className="Pro-Area">
                                <div className="img-sec" style={getImageStyle()}>
                                    <img src={pimage ? pimage : dummyImage} alt=""/>
                                    {fields.saleBadge &&
                                        <div className="sale-Badge">
                                            Sale
                                    </div>
                                    }
                                </div>
                                <div className={`pro-Name apply-font-${isFirstButtonActive ? 'producttitle' : 'productTitleMobile' }`} style={getTitleStyle()}>{product.title}</div>
                                <div className={`pro-Price apply-font-${isFirstButtonActive ? 'productprice' : 'productPriceMobile' }`} style={getPriceStyle()}>${product?.variants[0]?.price}</div>
                                <div className="Button-area" style={{ fontSize: fields.buttonFontSize }}>
                                    <button className={getButtonClass()} style={getButtonStyle()}>
                                        {getButtonText()}
                                    </button>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </Card>
    )
}
const options = { mobileLimit: 767, shouldListenOnResize: true }
export default withGetScreen(RecommendationPreview, options);