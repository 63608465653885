import React,  { useEffect, useState } from 'react';
import { Card, ButtonGroup, Button, Heading, Icon  } from '@shopify/polaris';
import {
    DesktopMajorMonotone,
    MobileMajorMonotone
} from '@shopify/polaris-icons';

const ProgressBar = (props) => {
    const { title, fields } = props;
    const { cartBarShadow, cartLimit, barSliderColor, barBaseColor, barThickness, barPointer, barPointerColor, hideCartbarPrices, hideCartbarValuePrice, cartBarSquare } = props.fields;
    const barPointerClass = barPointer === 'circle' ? 'circle-style' : (barPointer === 'icon' ? 'icon-style' : '');
    const valueNeeded = (Number(fields.cartLimit) - Number(fields.barRangeValue)).toFixed(2);
    const actualWidth = (Number(fields.barRangeValue) / Number(fields.cartLimit)) * 100;
    // const progreesWidth = valueNeeded > 0 ? barRangeValue : 100;
    // const progreesWidth = Number(fields.cartLimit) === 0 ? 3 : (actualWidth > Number(fields.cartLimit) ? 100 : actualWidth);
    const progreesWidth = Number(fields.cartLimit) === 0 ? 3 : (Number(fields.cartLimit) < Number(fields.barRangeValue) ? 100 : actualWidth);

    useEffect(() => {
        if (fields.boostBarMessagesType === 'multiple') {
            const scrollSpeed = fields.scrollSpeed === 'medium' ? 5000 : (fields.scrollSpeed === 'fast' ? 1000 : 7000);
            var current = 0;
            setTimeout(function() {
                setInterval(function () {
                    var slides = document.querySelectorAll("#boost-bar-slider .content");
                    if (slides.length) {
                        for (var i = 0; i < slides.length; i++) {
                            slides[i].style.opacity = 0;
                        }
                        current = (current !== slides.length - 1) ? current + 1 : 0;
                        if (slides[current]) {
                            slides[current].style.opacity = 1;
                        }
                    }
                }, scrollSpeed);
            }, 2000);
        }
    }, [fields]);

    const getStyle = () => {
        const preview = title === 'CartBar Preview' ? 'CartBar Preview' : 'BoostBar Preview';
        let previewStyle = {}
        if (preview === 'CartBar Preview') {
            previewStyle = {
                fontSize: isFirstButtonActive ? fields.cartBarFontSize : fields.cartBarMobileFontSize,
                textAlign: isFirstButtonActive ? fields.cartBarTextAlign : fields.cartBarMobileTextAlign,
                color: isFirstButtonActive ? fields.cartBarTextColor : fields.cartBarMobileTextColor,
            }
        } else {
            previewStyle = {
                fontSize: isFirstButtonActive ? fields.boostBarFontSize : fields.boostBarMobileFontSize,
                textAlign: isFirstButtonActive ? fields.boostBarTextAlign : fields.boostBarMobileTextAlign,
            }
        }

        if (fields.cartBarTextBackground && preview === 'CartBar Preview') {
            previewStyle.backgroundColor = isFirstButtonActive ? fields.cartBarBgColor : fields.cartBarMobileBgColor;
        }

        if (preview !== 'CartBar Preview') {
            previewStyle.backgroundColor = fields.boostBarBgColor;
        }

        return previewStyle;
    };

    const getPreWinningText = () => {
        if (title === 'CartBar Preview') {
            return valueNeeded > 0 ? fields.cartBarOfferText.replace('{ValueNeeded}', '$' + parseFloat(valueNeeded)) : fields.cartBarCompletionText;
        }
        return (fields.enableBoostBarSiteWide ? fields.boostBarSiteWideMessage : fields.cartBarOfferText);
    };

    const getProgressStyle = () => {
        let style = { height: barThickness, backgroundColor: barBaseColor };
        if (cartBarSquare) {
            style.borderRadius = '0px';
        }
        return style;
    }

    const contentStyle = (color) => {
        const style = {
            color: color,
        };
        return style;
    };

    const [isFirstButtonActive, setIsFirstButtonActive] = useState(true);

    const handleFirstButtonClick = () => {
        if (isFirstButtonActive) return;
        setIsFirstButtonActive(true);
    };

    const handleSecondButtonClick = () => {
        if (!isFirstButtonActive) return;
        setIsFirstButtonActive(false);
    };

    return (
        <div className={cartBarShadow ? 'own-shadow' : ''}>
            <Card sectioned>
                <div className="card-heading-with-toggle">
                    <Heading>{title}</Heading>
                    <ButtonGroup segmented>
                        <Button pressed={isFirstButtonActive} onClick={handleFirstButtonClick} icon={<Icon source={DesktopMajorMonotone} />}>
                            Desktop
							</Button>
                        <Button pressed={!isFirstButtonActive} onClick={handleSecondButtonClick} icon={<Icon source={MobileMajorMonotone} color="indigo" />}>
                            Mobile
							</Button>
                    </ButtonGroup>
                </div>
                {(fields.boostBarMessagesType === 'multiple' || fields.boostBarMessagesType === 'single')  ? (
                    <div id="boost-bar-slider" className={`m-t-10 preview-bar ${isFirstButtonActive ? 'apply-font-pcFont' : 'apply-font-mobileFont'} `} style={getStyle()}>
                        <p className="content" style={contentStyle(fields.boostBarMessageColor)}>{fields.boostBarSiteWideMessage}</p>
                        {fields.boostBarSiteWideMessage1 && <p className="content" style={contentStyle(fields.boostBarMessageColor1)}>{fields.boostBarSiteWideMessage1}</p>}
                        {fields.boostBarSiteWideMessage2 && <p className="content" style={contentStyle(fields.boostBarMessageColor2)}>{fields.boostBarSiteWideMessage2}</p>}
                        {fields.boostBarSiteWideMessage3 && <p className="content" style={contentStyle(fields.boostBarMessageColor3)}>{fields.boostBarSiteWideMessage3}</p>}
                    </div> 
                ) : (
                        <p className={`m-t-10 preview-bar ${isFirstButtonActive ? 'apply-font-main' : 'apply-font-mobileFont'} `} style={getStyle()}>
                        {getPreWinningText()}
                    </p>
                )}
                
                {title === 'CartBar Preview' &&
                    <div className="bar-preview-input">
                        <div className="prgrs-outr">
                            {!hideCartbarPrices && <span className="start_point">$10</span>}
                            <div className="progress-container" style={getProgressStyle()}>
                            <div className="filler-style" style={{ backgroundColor: barSliderColor, width: progreesWidth + '%' }}>
                                    <span className={`${barPointerClass} prgrs-icon`} style={barPointer === 'circle' ? { backgroundColor: barPointerColor, borderColor: barPointerColor === '#ffffff' ? '#ccc' : barPointerColor } : {}}></span>
                                </div>
                            </div>
                            {!hideCartbarPrices && <span className="end_point">${cartLimit}</span>}
                        </div>
                        {!hideCartbarValuePrice && <div className="bar-current-value">$50</div>}
                    </div>
                }
            </Card>
        </div>

    );
};

export default ProgressBar;