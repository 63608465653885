import React, { useState, useCallback, useEffect } from 'react';
import { Card, Page, Form, FormLayout, TextField, Select, RangeSlider, Stack, DropZone, Banner, List, Thumbnail, Link, Checkbox, ChoiceList, Button, Layout, TextStyle, Icon, ResourceList, Spinner } from '@shopify/polaris';
import TabHeader from '../../common/TabHeader';
import BackButton from '../../common/BackButton';
import CardHeading from './card-heading';
import ColorChoser from '../../common/Color';
import FontFamilyPicker from '../../common/FontPicker';
import { animationOptions, colorWithoutHash, textAlignmentOptions, getPayload, redirectPage, makingFields, dummyImage, getStoreName } from '../../common/Helper';
// import RLDD from 'react-list-drag-and-drop/lib/RLDD';
import { ReactSortable } from "react-sortablejs";
import { PaymentIcons, advanceBadgeImage } from './payment-icons';
import { DragHandleMinor, DeleteMajorMonotone, EditMinor, SaveMinor } from '@shopify/polaris-icons';
import API from '../../service/Api';
import Tooltip from '../../common/Tooltip';
import { useParams } from "react-router-dom";
import ItemPicker from '../../common/ItemPicker';
import RequiredError from '../../common/RequiredError';
import BadgePreview from './preview';
import '../../assets/trust-badges.css';

const initialStates = {
    name: '',
    status: 0,
    animationToggle: false,
    buttonAnimation: 'no-animation',
    badgeToggle: false,
    message: 'Secure and trusted checkout with',
    messageFontFamily: '',
    messageColor: '#000000',
    messageFontSize: 14,
    badgesColor: '#000000',
    advanceBadgeToggle: false,
    badgeAlginment: 'left',
    // featuredProduct: false,
    productTarget: 'all',
    customerTarget: 'all',
    dateTarget: 'always',
    productTag: '',
    customerTag: '',
    customerSpent: '',
    startDate: '',
    startTime: '',
    endDateToggle: false,
    endDate: '',
    endTime: ''
};

const circleTick = <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M0 10a10 10 0 1020 0 10 10 0 00-20 0zm15.2-1.8a1 1 0 00-1.4-1.4L9 11.6 6.7 9.3a1 1 0 00-1.4 1.4l3 3c.4.4 1 .4 1.4 0l5.5-5.5z" fill="#6774c8" /></svg>;


export default function TrustBadge() {
    // states
    const [title, setTitle] = useState("Create New Badge");
    const { id } = useParams();
    const shop = getStoreName();
    const [fields, setFields] = useState(initialStates);
    const [files, setFiles] = useState([]);
    const [rejectedFiles, setRejectedFiles] = useState([]);
    const [largeFiles, setLargeFiles] = useState([]);
    const hasError = rejectedFiles.length > 0 || largeFiles.length > 0;
    const [trustBadges, setTrustBadges] = useState([
        {
            id: 1,
            name: 'pf-visa'
        },
        {
            id: 2,
            name: 'pf-mastercard-alt'
        },
        {
            id: 3,
            name: 'pf-paypal'
        },
        {
            id: 4,
            name: 'pf-american-express'
        },
    ]);
    const [advanceBadges, setAdvanceBadge] = useState([
        {
            id: '1a',
            name: 'badges-red-free-return',
            src: 'https://app.cartboostapp.com/dev-cartboost/public/images/badges/badges-red-free-return.svg'
        },
        {
            id: '6a',
            name: 'badges-yellow-free-return',
            src: 'https://app.cartboostapp.com/dev-cartboost/public/images/badges/badges-yellow-free-return.svg'
        },
    ]);
    const [deletePreview, setDeletePreview] = useState(false);
    const [inputErrors, setInputErrors] = useState({
        titleError: null,
        productTagError: null,
        customerTagError: null,
        customerSpentError: null,
        startDateError: null,
        startTimeError: null,
        endDateError: null,
        endTimeError: null,
        productsError: null
    });
    const [toolTip, setTooltip] = useState({
        active: false,
        content: null,
        error: false
    });
    const [isLoading, setLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [openPicker, setOpenPicker] = useState(false);
    const [specificProducts, setSpecificProducts] = useState([]);
    const [specificProductsIds, setSpecificProductsIds] = useState([]);
    const [imageSpinner, setImageSpinner] = useState(false);
    const [integrationLoader, setIntegrationLoader] = useState(false);
    // get single badge data
    useEffect(() => {
        if (id) {
            API.get(`trust-badge/fetch/${id}`).then(response => {
                const badgeData = response.data.data;
                const metaData = badgeData.metadata;
                let dbFields = makingFields(metaData);
                dbFields.name = badgeData.title;
                dbFields.status = badgeData.status;
                const dbTrustBadges = dbFields.trustBadges;
                const dbDdvanceBadges = dbFields.advanceBadges;
                const uploadedFiles = dbFields.uploadedFiles;
                delete dbFields.trustBadges;
                delete dbFields.advanceBadges;
                setTitle(badgeData.title);
                setFields(dbFields);
                setTrustBadges(dbTrustBadges);
                setAdvanceBadge(dbDdvanceBadges);
                setFiles(uploadedFiles);
                if (metaData.productTarget_meta === 'manual' && metaData.specificProducts_meta && metaData.specificProducts_meta.length) {
                    setSpecificProducts(metaData.specificProducts_meta);
                    const ids = [];
                    metaData.specificProducts_meta.forEach(item => {
                        ids.push({
                            id: item.id
                        })
                    })
                    setSpecificProductsIds(ids);
                }
            }).catch(err => {
                console.log('err: ', err);
            });
        }
    }, [id, shop]);

    // handle changes
    const handleInputChange = (inputValue, inputName) => {
        if (inputName === 'productTarget' || inputName === 'customerTarget' || inputName === 'dateTarget') {
            inputValue = inputValue[0];
        }
        setFields({
            ...fields,
            [inputName]: inputValue
        })
    };

    // image upload
    const handleDrop = useCallback((_droppedFiles, acceptedFiles, rejectedFiles) => {
        if (files.length <= 5) {
            setLargeFiles([]);
            setRejectedFiles(rejectedFiles);
            if (acceptedFiles.length) {
                setImageSpinner(true);
                const formData = new FormData();
                formData.append(`shop`, shop);
                for (let i = 0; i < acceptedFiles.length; i++) {
                    var fileSize = Number((acceptedFiles[i].size / (1024 * 1024)).toFixed(2));
                    if (fileSize <= 5) {
                        formData.append(`photos[${i}]`, acceptedFiles[i], acceptedFiles[i].name);
                    } else {
                        setLargeFiles((file) => [...file, acceptedFiles[i]]);
                    }
                }
                API.post(`trust-badge/images/upload`, formData).then(response => {
                    setFiles((files) => [...files, ...response.data.data]);
                    setImageSpinner(false);
                }).catch(err => {
                    console.log("err", err);
                    setImageSpinner(false);
                });
            }
        }
    }, [files, shop]);

    const badgesLeft = 6 - files.length;
    const errorMessage = hasError && (
        <Banner
            title="The following images couldn’t be uploaded:"
            status="critical"
        >
            <List type="bullet">
                {rejectedFiles.map((file, index) => (
                    <List.Item key={index}>
                        {`"${file.name}" is not supported. File type must be .gif, .jpg, .png or .svg.`}
                    </List.Item>
                ))}
                {largeFiles.map((file, index) => (
                    <List.Item key={index}>
                        {`"${file.name}", the file size must be less than or equal to 5 MB.`}
                    </List.Item>
                ))}
            </List>
        </Banner>
    );
    //handle icons sort
    /* const handleRLDDChange = (reorderedItems) => {
        setTrustBadges(reorderedItems);
    }; */
    // handle select icons
    const handleTrustBadges = (icon) => {
        const findIcon = trustBadges.find(x => x.name === icon);
        if (findIcon) {
            const allItems = trustBadges.filter(x => x.name !== icon);
            setTrustBadges(allItems);
        } else {
            const iconItem = {
                id: trustBadges.length + 1,
                name: icon
            }
            setTrustBadges(prevArray => [...prevArray, iconItem]);
        }
    }
    // handle adavnce badge or icon
    const handleAdvanceBadges = (data) => {
        if (!deletePreview) {
            const findIcon = advanceBadges.find(x => x.id === data.id);
            if (findIcon) {
                const allItems = advanceBadges.filter(x => x.id !== data.id);
                setAdvanceBadge(allItems);
            } else {
                setAdvanceBadge(prevArray => [...prevArray, data]);
            }
        }
    };
    // delete badges
    const deleteBadge = () => {
        setDeletePreview(!deletePreview);
    };

    const deleteAdvanceBadges = (data) => {
        const filterFiles = files.filter(x => x.id !== data.id);
        const filterBadges = advanceBadges.filter(x => x.id !== data.id);
        setFiles(filterFiles);
        setAdvanceBadge(filterBadges);
        const payload = data;
        payload.shop = shop;
        API.post(`trust-badge/images/delete`, payload).then(response => {
            console.log('response: ', response);
        }).catch(err => {
            console.log("err", err);
        });
    };

    // handle manual products
    const handleItemSelection = (resources) => {
        const idFromResources = resources.selection.map((item) => item.id);
        if (idFromResources.length) {
            const ids = [];
            idFromResources.forEach(item => {
                ids.push({
                    id: item
                });
            });
            setSpecificProductsIds(ids);
            setSpecificProducts(resources.selection);
        } else {
            setSpecificProducts([]);
            setSpecificProductsIds([]);
        }
        setInputErrors({
            ...inputErrors,
            productsError: null
        });
        setOpenPicker(false);
    };
    // handle validation
    const handleValidation = (data) => {
        let errors = {};
        const productTag = data.productTag ?? '';
        const customerTag = data.customerTag ?? '';
        const startDate = data.startDate ?? '';
        const startTime = data.startTime ?? '';
        const endDate = data.endDate ?? '';
        const endTime = data.endTime ?? '';
        if (data.name.trim() === '') {
            errors.titleError = "This is required field";
        }
        if (productTag.trim() === '' && data.productTarget === 'tag') {
            errors.productTagError = "This is required field";
        }
        if (specificProducts.length === 0 && data.productTarget === 'manual') {
            errors.productsError = "This is required field";
        }
        if (customerTag.trim() === '' && data.customerTarget === 'tag') {
            errors.customerTagError = "This is required field";
        }
        if (Number(data.customerSpent) < 1 && data.customerTarget === 'spent') {
            errors.customerSpentError = "The value can not be less than 1";
        }
        if (startDate === '' && data.dateTarget === 'schedule') {
            errors.startDateError = "This is required field";
        }
        if (startTime === '' && data.dateTarget === 'schedule') {
            errors.startTimeError = "This is required field";
        }
        if (data.endDateToggle && endDate === '') {
            errors.endDateError = "This is required field";
        }
        if (data.endDateToggle && endTime === '') {
            errors.endTimeError = "This is required field";
        }
        if (startDate && endDate && startTime && endTime) {
            var startDateTime = Date.parse(startDate + ' ' + startTime);
            var endDateTime = Date.parse(endDate + ' ' + endTime);
            if (new Date(endDate) < new Date(startDate)) {
                errors.endDateError = "The end date can not be less than start date";
            }
            if (endDateTime < startDateTime) {
                errors.endTimeError = "The end time can not be less than start time";
            }
        }
        setInputErrors(errors);
        return errors;
    };
    // handle form 
    const handleSubmit = () => {
        let checkValidation = handleValidation(fields);
        if (Object.keys(checkValidation).length === 0) {
            let payload = getPayload(fields);
            payload.shop = shop;
            payload.trustBadges_meta = trustBadges;
            payload.advanceBadges_meta = advanceBadges;
            payload.uploadedFiles_meta = files;
            if (fields.productTarget === 'manual') {
                payload.specificProducts_meta = specificProducts;
            } else {
                payload.specificProducts_meta = [];
            }
            const url = id ? `trust-badge/${id}` : `trust-badge/create`;
            API.post(url, payload).then(response => {
                setLoading(false);
                setTooltip({
                    content: `Trust Badge ${id ? 'Updated' : 'Created'} Successfully`,
                    active: true,
                    error: false
                });
                redirectPage('trust-badge');
            }).catch(err => {
                setLoading(false);
                setTooltip({
                    content: err.response.data.message,
                    active: true,
                    error: true
                });
            });
        } else {
            setTooltip({
                content: "Please fill required fields",
                active: true,
                error: true
            });
        }
    };

    const deleteOffer = () => {
        setDeleteLoading(true);
        API.delete(`trust-badge/${id}`).then(response => {
            setDeleteLoading(false);
            setTooltip({
                content: "Trust Badge Deleted Successfully",
                active: true,
                error: false
            });
            redirectPage('trust-badge');
        }).catch(err => {
            setDeleteLoading(false);
            setTooltip({
                content: err.response.data.message,
                active: true,
                error: true
            });
        });
    };

    const integration = () => {
        setIntegrationLoader(true);
        let params = { shop: shop };
        API.post(`trust-badge/integration/code`, params).then(response => {
            setIntegrationLoader(false);
        }).catch(err => {
            setIntegrationLoader(false);
        });
    };

    return (
        <div className="edit-coupon-ofr" id="new-sale">
            <TabHeader />
            <Page
                title={title}
                primaryAction={<BackButton disable={false} url="trust-badge" />}
            >
                <Form noValidate onSubmit={handleSubmit}>
                    <Card title="Campaign Name" sectioned>
                        <FormLayout>
                            <TextField
                                value={fields.name}
                                onChange={e => handleInputChange(e, 'name')}
                                maxLength={140}
                                helpText="For your own internal reference. Only you can see it."
                                error={inputErrors.titleError}
                            />
                        </FormLayout>
                    </Card>

                    <Card sectioned>
                        <CardHeading
                            title="Add-to-Cart Button Animation"
                            status={fields.animationToggle}
                            name="animationToggle"
                            handleButtonToggle={handleInputChange}
                        />
                        {fields.animationToggle &&
                            <div className="m-t-30">
                                <FormLayout>
                                    <Select
                                        label="Animations"
                                        options={animationOptions}
                                        onChange={e => handleInputChange(e, 'buttonAnimation')}
                                        value={fields.buttonAnimation}
                                        helpText={<p><b>Note:</b> The button animation will apply to the dynamic checkout button if it is enabled on your product page.</p>}
                                    />
                                </FormLayout>
                            </div>
                        }
                    </Card>
                    <Card sectioned>
                        <CardHeading
                            title="Trust Badge"
                            status={fields.badgeToggle}
                            name="badgeToggle"
                            handleButtonToggle={handleInputChange}
                        />
                        {fields.badgeToggle &&
                            <div className="m-t-30">
                                <FormLayout>
                                    <TextField
                                        label="Message Above The Trust Badge"
                                        value={fields.message}
                                        onChange={e => handleInputChange(e, 'message')}
                                        maxLength={150}
                                        showCharacterCount
                                    />
                                    <div className="payment-icons-wrapper">
                                        <Layout>
                                            <Layout.Section>
                                                <TextStyle variation="subdued">Select Badges</TextStyle>
                                                <div className="icons-scroller">
                                                    <div className="icons-inner">
                                                        {PaymentIcons.map((icon, index) => {
                                                            const findIcon = trustBadges.find(x => x.name === icon);
                                                            return (
                                                                <label className={`badge ${findIcon ? 'active' : ''}`} key={index}>
                                                                    <i className={`badge-icon pf ` + icon}></i>
                                                                    <input
                                                                        className="badge-input hide"
                                                                        type="checkbox"
                                                                        value={icon}
                                                                        onClick={e => handleTrustBadges(icon)}
                                                                    />
                                                                </label>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </Layout.Section>
                                            <Layout.Section secondary>
                                                <TextStyle variation="subdued">Sort Badges</TextStyle>
                                                <div className="sort-div">
                                                    <ReactSortable list={trustBadges} setList={setTrustBadges}>
                                                        {trustBadges.map((item) => (
                                                            <div className="reorder" key={item.id}><Icon source={DragHandleMinor} /> {item.name.replace('pf-', '')}</div>
                                                        ))}
                                                    </ReactSortable>
                                                    {/* <RLDD
                                                        cssClasses="example"
                                                        items={trustBadges}
                                                        itemRenderer={(item) => {
                                                            return (
                                                                <div className="reorder">
                                                                    <Icon source={DragHandleMinor} />
                                                                    {item.name.replace('pf-', '')}
                                                                </div>
                                                            );
                                                        }}
                                                        onChange={handleRLDDChange}
                                                    /> */}
                                                </div>
                                            </Layout.Section>
                                        </Layout>
                                    </div>
                                    <FormLayout.Group>
                                        <FontFamilyPicker
                                            pickerId="main"
                                            label="Message Font Family"
                                            activeFont={fields.messageFontFamily}
                                            field="messageFontFamily"
                                            handleFontFamilyInput={handleInputChange}
                                        />
                                        <TextField
                                            prefix="#"
                                            label="Message Color"
                                            value={colorWithoutHash(fields.messageColor)}
                                            suffix={<ColorChoser
                                                field="messageColor"
                                                handleColorInput={handleInputChange}
                                                color={fields.messageColor}
                                            />
                                            }
                                        />
                                    </FormLayout.Group>
                                    <FormLayout.Group>
                                        <RangeSlider
                                            output
                                            label="Message Font Size"
                                            min={10}
                                            max={30}
                                            step={1}
                                            value={fields.messageFontSize}
                                            onChange={e => handleInputChange(e, 'messageFontSize')}
                                            prefix={<p>10px</p>}
                                            suffix={<p>30px</p>}
                                        />
                                        <TextField
                                            prefix="#"
                                            label="Badges Color"
                                            value={colorWithoutHash(fields.badgesColor)}
                                            suffix={<ColorChoser
                                                field="badgesColor"
                                                handleColorInput={handleInputChange}
                                                color={fields.badgesColor}
                                            />
                                            }
                                        />
                                    </FormLayout.Group>
                                </FormLayout>
                            </div>
                        }
                    </Card>

                    <Card sectioned>
                        <CardHeading
                            title="Advanced Trust Badge"
                            status={fields.advanceBadgeToggle}
                            name="advanceBadgeToggle"
                            handleButtonToggle={handleInputChange}
                        />
                        {fields.advanceBadgeToggle &&
                            <div className="m-t-30">
                                <FormLayout>
                                    {imageSpinner ? (
                                        <div className="images-spinner">
                                            <Spinner accessibilityLabel="Spinner example" size="large" color="teal" />
                                        </div>
                                    ) : (
                                            <Stack vertical>
                                                {errorMessage}
                                                <DropZone accept="image/*" type="image" onDrop={handleDrop} label={`Maximum 6 badges can be uploaded, ${badgesLeft} badges left.`}>
                                                    <DropZone.FileUpload />
                                                </DropZone>
                                            </Stack>
                                        )}
                                </FormLayout>
                                <FormLayout>
                                    <div className="advance-badges-wrapper">
                                        {advanceBadgeImage.map((image, index) => {
                                            const findImage = advanceBadges.find(x => x.id === image.id);
                                            const badgeClass = findImage && !deletePreview ? 'active' : (deletePreview ? 'edit-select' : '');
                                            return (
                                                <div className={`badge-blk fixed ${badgeClass}`} key={index}>
                                                    <img className="badge-image" alt={image.name} src={image.src} onClick={e => handleAdvanceBadges(image)} />
                                                    {circleTick}
                                                </div>
                                            )
                                        })}
                                        {files.map((file, index) => {
                                            const findImage = advanceBadges.find(x => x.id === file.id);
                                            const badgeClass = findImage && !deletePreview ? 'active' : (deletePreview ? 'edit-select' : '');
                                            return (
                                                <div className={`badge-blk files ${badgeClass}`} key={index}>
                                                    <img className="badge-image" alt={file.name} src={file.src} onClick={e => handleAdvanceBadges(file)} />
                                                    {circleTick}
                                                    <div className="delete-icon" onClick={e => deleteAdvanceBadges(file)} >
                                                        <Icon source={DeleteMajorMonotone} />
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </FormLayout>
                                <div className="m-t-10">
                                    <Link onClick={e => deleteBadge()}>
                                        <Stack alignment="trailing" spacing="extraTight">
                                            <Stack.Item><Icon source={deletePreview ? SaveMinor : EditMinor} color="blue" /></Stack.Item>
                                            <Stack.Item>{deletePreview ? 'Done' : 'Edit uploaded badges'}</Stack.Item>
                                        </Stack>
                                    </Link>
                                    {deletePreview && <div><b>Note</b>: Please must save your changes to ensure badges will get removed.</div>}
                                </div>
                            </div>
                        }
                    </Card>

                    <Card title="Aligns to" sectioned>
                        <FormLayout>
                            <Select
                                options={textAlignmentOptions}
                                onChange={e => handleInputChange(e, 'badgeAlginment')}
                                value={fields.badgeAlginment}
                                helpText="It is better to choose the same alignment as the add-to-cart button on your product page."
                            />
                        </FormLayout>
                    </Card>

                    <Card title="Targeting">
                        <Card.Section title="Applies to Products">
                            <ChoiceList
                                choices={[
                                    { label: 'All products', value: 'all' },
                                    { label: 'Target products based on product tag', value: 'tag' },
                                    { label: 'Target products based on manual selection', value: 'manual' },
                                ]}
                                selected={fields.productTarget}
                                onChange={e => handleInputChange(e, 'productTarget')}
                            />
                            <div className="m-t-10">
                                {fields.productTarget === 'tag' &&
                                    <TextField
                                        label="Product tag is equal to"
                                        onChange={e => handleInputChange(e, 'productTag')}
                                        value={fields.productTag}
                                        error={inputErrors.productTagError}
                                        helpText="Only one tag is allowed here and Tag name should be case-sensitive"
                                    />
                                }
                                {fields.productTarget === 'manual' &&
                                    <Button primary onClick={e => setOpenPicker(true)}>Browse Products</Button>
                                }
                                {inputErrors.productsError && fields.productTarget === 'manual' &&
                                    <RequiredError />
                                }
                                {specificProducts.length > 0 && fields.productTarget === 'manual' &&
                                    <div className="badge-products-wrapper">
                                        <ResourceList
                                            resourceName={{ singular: 'sale', plural: 'pname' }}
                                            items={specificProducts}
                                            renderItem={(item) => {
                                                const { title, images } = item;
                                                const image = images[0]?.originalSrc;
                                                const media = <Thumbnail
                                                    source={image ? image : dummyImage}
                                                    size="small"
                                                    alt={title}
                                                />;
                                                return (
                                                    <div className="badge-products">
                                                        <ResourceList.Item
                                                            media={media}
                                                            accessibilityLabel={`View pname for ${title}`}
                                                        >
                                                            <h3>
                                                                <TextStyle variation="strong">{title}</TextStyle>
                                                            </h3>
                                                        </ResourceList.Item>
                                                    </div>
                                                );
                                            }}
                                        />
                                    </div>
                                }
                            </div>
                        </Card.Section>

                        <Card.Section title="Applies to Customers">
                            <ChoiceList
                                choices={[
                                    { label: 'All customers', value: 'all' },
                                    { label: 'Target customers based on customer tag', value: 'tag' },
                                    { label: 'Target customers based on lifetime spent', value: 'spent' },
                                ]}
                                selected={fields.customerTarget}
                                onChange={e => handleInputChange(e, 'customerTarget')}
                            />
                            <div className="m-t-10">
                                {fields.customerTarget === 'tag' &&
                                    <TextField
                                        label="Customer tag is equal to"
                                        onChange={e => handleInputChange(e, 'customerTag')}
                                        value={fields.customerTag}
                                        helpText="Only one tag is allowed here and Tag name should be case-sensitive"
                                        error={inputErrors.customerTagError}
                                    />
                                }
                                {fields.customerTarget === 'spent' &&
                                    <TextField
                                        label="Customer spent is greater than or equal to"
                                        onChange={e => handleInputChange(e, 'customerSpent')}
                                        value={fields.customerSpent}
                                        error={inputErrors.customerSpentError}
                                        type="number"
                                        prefix=">="
                                    />
                                }
                            </div>
                        </Card.Section>

                        <Card.Section title="Active dates">
                            <ChoiceList
                                choices={[
                                    { label: 'Always display', value: 'always' },
                                    { label: 'Scheduled display', value: 'schedule' },
                                ]}
                                selected={fields.dateTarget}
                                onChange={e => handleInputChange(e, 'dateTarget')}
                            />
                            {fields.dateTarget === 'schedule' &&
                                <div>
                                    <FormLayout.Group>
                                        <TextField
                                            label="Start Date"
                                            onChange={e => handleInputChange(e, 'startDate')}
                                            value={fields.startDate}
                                            type="date"
                                            error={inputErrors.startDateError}
                                        />
                                        <TextField
                                            label="Start Time"
                                            onChange={e => handleInputChange(e, 'startTime')}
                                            value={fields.startTime}
                                            type="time"
                                            error={inputErrors.startTimeError}
                                        />
                                    </FormLayout.Group>
                                    <FormLayout.Group>
                                        <Checkbox
                                            label="Set End Date"
                                            checked={fields.endDateToggle}
                                            onChange={e => handleInputChange(e, 'endDateToggle')}
                                        />
                                    </FormLayout.Group>
                                </div>
                            }
                            {fields.endDateToggle && fields.dateTarget === 'schedule' &&
                                <FormLayout.Group>
                                    <TextField
                                        label="End Date"
                                        onChange={e => handleInputChange(e, 'endDate')}
                                        value={fields.endDate}
                                        type="date"
                                        error={inputErrors.endDateError}
                                    />
                                    <TextField
                                        label="End Time"
                                        onChange={e => handleInputChange(e, 'endTime')}
                                        value={fields.endTime}
                                        type="time"
                                        error={inputErrors.endTimeError}
                                    />
                                </FormLayout.Group>
                            }
                        </Card.Section>
                    </Card>

                    <Card
                        title="Required code integration"
                        primaryFooterAction={{ content: 'Integrate Now', onAction: integration, loading: integrationLoader }}
                        sectioned
                    >
                        <TextStyle>Click the button below <b>when you publish a new theme for your store</b>.</TextStyle>
                        <Card.Section title="Without integration, the following features won't work:">
                            <List>
                                <List.Item><TextStyle variation="subdued">Target products based on product tag</TextStyle></List.Item>
                                <List.Item><TextStyle variation="subdued">Target products based on manual selection</TextStyle></List.Item>
                                <List.Item><TextStyle variation="subdued">Target customers based on customer tag</TextStyle></List.Item>
                                <List.Item><TextStyle variation="subdued">Target customers based on lifetime spent</TextStyle></List.Item>
                            </List>
                        </Card.Section>
                    </Card>

                    <BadgePreview fields={fields} trustBadges={trustBadges} advanceBadges={advanceBadges} />
                    <div className="button_wrap m-t-30">
                        <Button primary submit loading={isLoading}>Save</Button>
                        {id && <Button destructive onClick={deleteOffer} loading={deleteLoading}>Delete</Button>}
                    </div>
                </Form>
                <Tooltip tooltip={toolTip} />
                {shop &&
                    <ItemPicker
                        resourceType="Product"
                        openPicker={openPicker}
                        allowMultiple={true}
                        handleItemSelection={handleItemSelection}
                        setOpenPicker={setOpenPicker}
                        selectedItem={specificProductsIds}
                    />
                }
            </Page>
        </div>
    )
}
