import React, { useState, useEffect, useCallback } from 'react';
import { Card, Page, TextField, Form, FormLayout, TextStyle, Select, Button, Icon, Banner, Link, Modal, TextContainer, Heading, DataTable, Checkbox } from '@shopify/polaris';
import TabHeader from '../../common/TabHeader';
import { useParams } from "react-router-dom";
import Tooltip from '../../common/Tooltip';
import { PlusMinor, DeleteMinor } from '@shopify/polaris-icons';
import { locationOptions, conditionOptions, typeOptions, dayOptions, stockOptions, pageOptions, getPayload, makingFields, smartLabels, redirectPage, getStoreName } from '../../common/Helper';
import BackButton from '../../common/BackButton';
import API from '../../service/Api';

const initialStates = {
    name: '',
    message: '',
    location: '',
    conditions: [{
        type: 'day',
        condition: 'equal-to',
        value: 'monday'
    }],
    status: 0,
    alwaysOn: false
};

const htmlCode = '<div id="timer_offers"></div>';

export default function NewTimer() {

    const shop = getStoreName();
    const { id } = useParams();
    const [title, setTitle] = useState("Create New Message");
    const [documentPreview, setDocumentPreview] = useState(false);
    const [fields, setFields] = useState(initialStates);
    const [copyButtonText, setButtonText] = useState('Copy code');
    const [isLoading, setLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [inputErrors, setInputErrors] = useState({
        titleError: null,
        messageError: null,
        locationError: null
    });
    const [toolTip, setTooltip] = useState({
        active: false,
        content: null,
        error: false
    });

    /** get single offer data */
    useEffect(() => {
        if (id) {
            API.get(`timer-boost/fetch/${id}`).then(response => {
                const timerData = response.data.data.timerData;
                setTitle(timerData.title);
                const metaData = timerData.metadata;
                let dbFields = makingFields(metaData);
                dbFields.name = timerData.title;
                dbFields.status = timerData.status;
                setFields(dbFields);
            }).catch(err => {
                console.log('err: ', err);
            });
        }
    }, [id, shop]);

    const getSelectOptions = (value) => {
        if (value === 'day') {
            return dayOptions;
        } else if (value === 'page') {
            return pageOptions;
        } else {
            return stockOptions;
        }
    };

    const handleInputChange = (inputValue, inputName) => {
        if (inputName === 'status' || inputName === 'target' || inputName === 'type') {
            inputValue = inputValue[0];
        }
        setFields({
            ...fields,
            [inputName]: inputValue
        })
    };

    const handleCondition = (inputValue, inputName, key) => {
        let data = { ...fields };
        if (inputName === 'type') {
            if (inputValue === 'day') {
                data.conditions[key]['value'] = 'monday';
            } else if (inputValue === 'page') {
                data.conditions[key]['value'] = 'product-page';
                data.conditions[key]['condition'] = 'equal-to';
            } else if (inputValue === 'stock-status') {
                data.conditions[key]['value'] = 'in-stock';
                data.conditions[key]['condition'] = 'equal-to';
            } else {
                data.conditions[key]['value'] = '';
            }
        }
        data.conditions[key][inputName] = inputValue;
        setFields(data);
    };

    const addMoreCondition = () => {
        let conditions = fields.conditions;
        conditions.push({
            type: 'day',
            condition: 'equal-to',
            value: 'monday'
        });
        setFields({
            ...fields,
            conditions: conditions
        });
    };

    const deleteCondition = (index) => {
        let conditions = [];
        fields.conditions.forEach((x, i) => {
            if (i !== index) {
                conditions.push(x);
            }
        });
        setFields({
            ...fields,
            conditions: conditions
        });
    };

    const copyToClipboard = () => {
        var textField = document.createElement('textarea')
        textField.innerText = htmlCode;
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy');
        textField.remove();
        setButtonText('Copied');
    };

    const handleValidation = (data) => {
        let errors = {};
        if (data.name.trim() === '') {
            errors.titleError = "This is required field";
        }
        if (data.message.trim() === '') {
            errors.messageError = "This is required field";
        }
        if (data.location === '') {
            errors.locationError = "This is required field";
        }
        setInputErrors(errors);
        return errors;
    };

    const handleSubmit = () => {
        let checkValidation = handleValidation(fields);
        if (Object.keys(checkValidation).length === 0) {
            setLoading(true);
            let payload = getPayload(fields);
            payload.shop = shop;
            const url = id ? `timer-boost/${id}` : `timer-boost/create`;
            API.post(url, payload).then(response => {
                setLoading(false);
                setTooltip({
                    content: `TimerBoost ${id ? 'Updated' : 'Created'} Successfully`,
                    active: true,
                    error: false
                });
                redirectPage('timers');
            }).catch(err => {
                console.log('err: ', err);
                setLoading(false);
                setTooltip({
                    content: err.response.data.message,
                    active: true,
                    error: true
                });
            });
        } else {
            setTooltip({
                content: "Please fill required fields",
                active: true,
                error: true
            });
        }
    };

    const deleteOffer = () => {
        setDeleteLoading(true);
        API.delete(`timer-boost/${id}`).then(response => {
            setDeleteLoading(false);
            setTooltip({
                content: "TimerBoost Deleted Successfully",
                active: true,
                error: false
            });
            redirectPage('timers');
        }).catch(err => {
            setDeleteLoading(false);
            setTooltip({
                content: err.response.data.message,
                active: true,
                error: true
            });
        });
    };

    const getConditions = (index) => {
        if (fields.conditions[index].type === 'page' || fields.conditions[index].type === 'stock-status') {
            let conditions = [...conditionOptions];
            conditions = conditions.slice(0, -2);
            return conditions;
        }
        return conditionOptions;
    };

    const handleDocumentPreview = useCallback(() => setDocumentPreview(!documentPreview), [documentPreview]);

    return (
        <div className="edit-coupon-ofr">
            <TabHeader />
            <Page
                title={title}
                primaryAction={<BackButton disable={false} url="timers" />}
            >
                <Form noValidate onSubmit={handleSubmit}>
                    <Card title="Message Settings" sectioned>
                        <FormLayout>
                            <TextField
                                label="Title"
                                value={fields.name}
                                onChange={e => handleInputChange(e, 'name')}
                                error={inputErrors.titleError}
                            />
                            <TextField
                                label="Message"
                                value={fields.message}
                                onChange={e => handleInputChange(e, 'message')}
                                multiline={4}
                                error={inputErrors.messageError}
                                helpText={<span>Use smart lables to display automated messages. For more information view the <Link onClick={e => setDocumentPreview(true)}>documentation</Link>.</span>}
                            />
                            <Select
                                label="Location"
                                options={locationOptions}
                                onChange={e => handleInputChange(e, 'location')}
                                placeholder="Select a location"
                                value={fields.location}
                                error={inputErrors.locationError}
                            />
                            {fields.location === 'custom-position' &&
                                <Banner
                                    title="Custom placement"
                                    action={{ content: copyButtonText, onClick: copyToClipboard }}
                                    status="info"
                                >
                                    <p>Copy the html code and place it where you want to show the offer on cart page. <TextStyle variation="code"> {htmlCode} </TextStyle></p>
                                </Banner>
                            }
                        </FormLayout>
                    </Card>
                    <Card title="Message Conditions" sectioned>
                        <TextStyle variation="subdued">
                            Match all condition to display the messages
                        </TextStyle>
                        <div className="m-t-30 message-conditions">
                            <FormLayout>
                                <Checkbox
                                    label="Always On"
                                    checked={fields.alwaysOn}
                                    onChange={e => handleInputChange(e, 'alwaysOn')}
                                />
                                {!fields.alwaysOn && fields.conditions.map((item, index) => {
                                    return (
                                        <FormLayout.Group key={index} condensed>
                                            <Select
                                                options={typeOptions}
                                                onChange={e => handleCondition(e, 'type', index)}
                                                value={fields.conditions[index].type}
                                            />
                                            <Select
                                                options={getConditions(index)}
                                                onChange={e => handleCondition(e, 'condition', index)}
                                                value={fields.conditions[index].condition}
                                            />
                                            {fields.conditions[index].type === 'day' || fields.conditions[index].type === 'page' || fields.conditions[index].type === 'stock-status' ? (
                                                <Select
                                                    options={getSelectOptions(fields.conditions[index].type)}
                                                    onChange={e => handleCondition(e, 'value', index)}
                                                    value={fields.conditions[index].value}
                                                />
                                            ) : (
                                                <TextField
                                                    type={fields.conditions[index].type === 'date' ? 'date' : 'time'}
                                                    value={fields.conditions[index].value}
                                                    onChange={e => handleCondition(e, 'value', index)}
                                                />
                                            )
                                            }
                                            <span onClick={e => deleteCondition(index)} style={{ cursor: "pointer" }}><Icon source={DeleteMinor} /></span>
                                        </FormLayout.Group>
                                    )
                                })}
                                <Button icon={<Icon source={PlusMinor} />} onClick={addMoreCondition}>Add Condition</Button>
                            </FormLayout>
                        </div>
                    </Card>
                    <div className="button_wrap m-t-30">
                        <Button primary submit loading={isLoading}>Save</Button>
                        {id && <Button destructive onClick={deleteOffer} loading={deleteLoading}>Delete</Button>}
                    </div>
                </Form>
                <Tooltip tooltip={toolTip} />
            </Page>
            <Modal
                large
                open={documentPreview}
                onClose={handleDocumentPreview}
                title="Smart Labels"
                secondaryActions={[
                    {
                        content: 'Close',
                        onAction: handleDocumentPreview,
                    },
                ]}
            >
                <Modal.Section>
                    <TextContainer>
                        <Heading>Available smart labels</Heading>
                        <p>Cartboost supports a variety of smart labels by default.</p>
                        <DataTable
                            columnContentTypes={[
                                'text',
                                'text',
                            ]}
                            headings={[
                                'Label',
                                'Description',
                            ]}
                            rows={smartLabels}
                        />
                    </TextContainer>
                </Modal.Section>
            </Modal>
        </div>
    );
}



