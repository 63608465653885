import React, { useState } from 'react'
import { Banner, TextStyle, Card } from '@shopify/polaris';
const htmlCode = '<div id="cartbar"></div>';

export default function CustomPlacement({ placement }) {
    const [copyButtonText, setButtonText] = useState('Copy code');

    const copyToClipboard = () => {
        var textField = document.createElement('textarea')
        textField.innerText = '<div id="cartbar"></div>';
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy');
        textField.remove();
        setButtonText('Copied');
    };

    return (
        <div>
            {placement === 'custom-positon' &&
                <Card.Section>
                    <Banner
                        title="Custom placement"
                        action={{ content: copyButtonText, onClick: copyToClipboard }}
                        status="info"
                    >
                        <p>Copy the html code and place it where you want to show the offer on cart page. <TextStyle variation="code"> {htmlCode} </TextStyle></p>
                    </Banner>
                </Card.Section>
            }
        </div>
    )
}
