import React, { useState, useEffect, useCallback } from 'react';
import { Card, Page, ChoiceList, ResourceList, Thumbnail, TextField, Form, Stack, FormLayout, Button, TextStyle, Collapsible, Checkbox, RangeSlider, Select, Heading, Subheading } from '@shopify/polaris';
import TabHeader from '../../common/TabHeader';
import { ResourcePicker } from '@shopify/app-bridge-react';
import ItemPicker from '../../common/ItemPicker';
import { useParams } from "react-router-dom";
import API from '../../service/Api';
import Tooltip from '../../common/Tooltip';
import { getPayload, makingFields, colorWithoutHash, offerTypeOptions, dummyImage, redirectPage, textAlignmentOptions, getStoreName } from '../../common/Helper';
import ColorChoser from '../../common/Color';
import FontFamilyPicker from '../../common/FontPicker';
import CssHelper from '../../common/CssHelper';
import PreviewModal from './preview-modal';
import RequiredError from '../../common/RequiredError';
import BackButton from '../../common/BackButton';
import TabSettings from '../../common/TabSettings';

let initialStates = {
    name: '',
    status: 0,
    triggerType: 'add-to-cart',
    cartMin: '',
    cartMax: '',
    offerType: 'cart-limit',
    offerTitle: '',
    offerDescriptionMobile: '',
    offerDescriptionDesktop: '',
    showProductTitle: false,
    showProductPrice: false,
    customHtmlCss: '',
    offerTitleColor: '#000000',
    offerDescriptionColor: '#000000',
    offerTitleFontFamily: '',
    offerDescriptionFontFamily: '',
    offerTitleFontSize: 15,
    offerDescriptionFontSize: 15,
    continueShadow: false,
    enableCustomCss: false,
    customCss: '',
    offerButtonText: 'Add to cart',
    offerButtonTextColor: '#ffffff',
    offerButtonColor: '#e48643',
    offerButtonFontSize: '16',
    offerButtonFontFamily: '',
    offerTriggerType: 'discount-code',
    discountCode: '',
    continueButtonFontSize: 15,
    continueButtonText: 'Continue to Cart',
    conitnueButtonColor: '#cccccc',
    continueButtonTextColor: '#000000',
    continueButtonFontFamily: '',
    ajaxCart: false,
    offerTitleMobileColor: '#000000',
    offerDescriptionMobileColor: '#000000',
    offerTitleMobileFontFamily: '',
    offerDescriptionMobileFontFamily: '',
    offerTitleMobileFontSize: 15,
    offerDescriptionMobileFontSize: 15,
    offerButtonMobileText: 'Add to cart',
    offerButtonMobileFontFamily: '',
    offerButtonMobileTextColor: '#ffffff',
    offerButtonMobileColor: '#e48643',
    offerButtonMobileFontSize: 15,
    continueButtonMobileFontSize: 15,
    continueButtonMobileTextColor: '#000000',
    conitnueButtonMobileColor: '#cccccc',
    continueButtonMobileText: 'Continue to Cart',
    continueButtonMobileFontFamily: '',
    continueShadowMobile: false,
    titleTextAlign: 'left',
    descpTextAlign: 'left'
};

export default function PopupManage() {

    const shop = getStoreName();
    const { id } = useParams();
    const [title, setTitle] = useState("Create New Popup Upsell");
    const [fields, setFields] = useState(initialStates);
    const [inputErrors, setInputErrors] = useState({
        nameError: null,
        cartMinError: null,
        cartMaxError: null,
        offerTitleError: null,
        offerDescriptionError: null,
        collectionError: null,
        productError: null,
        offerProductError: null,
        discountError: null,
        offerButtonTextError: null,
        continueButtonTextError: null,
        offerButtonMobileTextError: null,
        continueButtonMobileTextError: null
    });

    const [isLoading, setLoading] = useState(false);
    const [preview, setPreview] = useState(false);
    const [previewMode, setPreviewMode] = useState(null);
    const [deleteLoading, setDeleteLoading] = useState(false);

    const [collpaseActive, setCollapseActive] = useState({
        upsellTriggerActive: false,
        upsellOfferActive: false
    });

    const [toolTip, setTooltip] = useState({
        active: false,
        content: null,
        error: false
    });

    const [openPicker, setOpenPicker] = useState(false);
    const [selectedItem, setSelectedItem] = useState('required-product');
    const [requiredProduct, setRequiredProduct] = useState([]);
    const [offerProduct, setOfferProduct] = useState(null);
    const [requiredCollection, setRequiredCollection] = useState(null);
    const [requiredProductId, setRequiredProductId] = useState([]);
    const [offerProductId, setOfferProductId] = useState(null);
    const [requiredCollectionId, setRequiredCollectionId] = useState(null);
    const [disableBackButton, setDisableBackButton] = useState(false);
    const [tabIndex, setTabIndex] = useState(0);
    /**get data from db */
    useEffect(() => {
        if (id) {
            API.get(`popup-upsell/fetch/${id}`).then(response => {
                const offer = response.data.data.baroffer;
                setTitle(offer.offerName);
                const metaData = JSON.parse(offer.popupOptions);
                let dbFields = makingFields(metaData);
                dbFields.name = offer.offerName;
                dbFields.status = offer.offerStatus;
                dbFields.offerType = offer.offerType;
                //make initialStates with db values
                initialStates = dbFields;
                setFields(dbFields);
                if (metaData.triggerProduct_meta && metaData.triggerProduct_meta.length) {
                    /* setRequiredProductId([{
                        id: metaData.triggerProduct_meta.id,
                        variants: [{
                            id: metaData.triggerProduct_meta.variants[0].id,
                        }],
                    }]);
                    setRequiredProduct(metaData.triggerProduct_meta); */
                    setRequiredProduct(metaData.triggerProduct_meta);
                    const ids = [];
                    metaData.triggerProduct_meta.forEach(item => {
                        ids.push({
                            id: item.id
                        })
                    })
                    setRequiredProductId(ids);
                }
                if (metaData.triggerCollection_meta && metaData.triggerCollection_meta.id) {
                    setRequiredCollectionId([{
                        id: metaData.triggerCollection_meta.id,
                        variants: [{
                            id: metaData.triggerCollection_meta.id,
                        }],
                    }]);
                    setRequiredCollection(metaData.triggerCollection_meta);
                }
                if (metaData.offerProduct_meta && metaData.offerProduct_meta.id) {
                    setOfferProductId([{
                        id: metaData.offerProduct_meta.id,
                        variants: [{
                            id: metaData.offerProduct_meta.variants[0].id,
                        }],
                    }]);
                    setOfferProduct(metaData.offerProduct_meta);
                }
            }).catch(err => {
                console.log('err: ', err);
            });
        }
    }, [id, shop]);

    /** Disable back button if form is edited */
    useEffect(() => {
        if (JSON.stringify(fields) === JSON.stringify(initialStates)) {
            setDisableBackButton(false);
        } else {
            setDisableBackButton(true);
        }
    }, [fields]);

    const handleInputChange = (inputValue, inputName) => {
        if (inputName === 'triggerType' || inputName === 'offerTriggerType') {
            inputValue = inputValue[0];
        }
        setFields({
            ...fields,
            [inputName]: inputValue
        })
    };

    const handleColorChange = (color, inputName) => {
        const value = color.search('#') >= 0 ? color : '#' + color;
        setFields({
            ...fields,
            [inputName]: value
        });
    };

    const handleCollapseActive = (inputName) => {
        setCollapseActive(prevState => ({ ...prevState, [inputName]: !prevState[inputName] }));
    };

    const handleItemSelection = (resources) => {
        const idFromResources = resources.selection.map((item) => item.id);
        if (idFromResources.length) {
            if (selectedItem === 'required-product') {
                /* setRequiredProductId([{
                    id: idFromResources[0]
                }]);
                setRequiredProduct(resources.selection[0]); */
                const ids = [];
                idFromResources.forEach(item => {
                    ids.push({
                        id: item
                    })
                })
                setRequiredProductId(ids);
                setRequiredProduct(resources.selection);
                setInputErrors({
                    ...inputErrors,
                    productError: null
                });
            } else if (selectedItem === 'offer-product') {
                setOfferProductId([{
                    id: idFromResources[0]
                }]);
                setOfferProduct(resources.selection[0]);
                setInputErrors({
                    ...inputErrors,
                    offerProductError: null
                })
            } else if (selectedItem === 'required-collection') {
                setRequiredCollectionId([{
                    id: idFromResources[0]
                }]);
                setRequiredCollection(resources.selection[0]);
                setInputErrors({
                    ...inputErrors,
                    collectionError: null
                })

            }
            if (disableBackButton === false) {
                setDisableBackButton(true);
            }
        } else {
            if (selectedItem === 'required-product') {
                setRequiredProductId(null);
                setRequiredProduct(null);
                setInputErrors({
                    ...inputErrors,
                    productError: null
                });
            } else if (selectedItem === 'offer-product') {
                setOfferProductId(null);
                setOfferProduct(null);
                setInputErrors({
                    ...inputErrors,
                    offerProductError: null
                })
            } else if (selectedItem === 'required-collection') {
                setRequiredCollectionId(null);
                setRequiredCollection(null);
                setInputErrors({
                    ...inputErrors,
                    collectionError: null
                })
            }
            if (JSON.stringify(fields) === JSON.stringify(initialStates)) {
                setDisableBackButton(false);
            }
        }
        setOpenPicker(false);
    };

    const handleSelectedItemPicker = (name) => {
        setSelectedItem(name);
        setOpenPicker(true);
    };

    const getSelectedIds = () => {
        if (selectedItem === 'required-product') {
            return requiredProductId ? requiredProductId : [];
        } else if (selectedItem === 'offer-product') {
            return offerProductId ? offerProductId : [];
        } else if (selectedItem === 'required-collection') {
            return requiredCollectionId ? requiredCollectionId : [];
        }
    };

    const handleValidation = (data) => {
        let errors = {};
        if (data.name.trim() === "") {
            errors.nameError = "This is required field";
        }
        if (Number(data.cartMax) < 0 && data.offerType === 'cart-limit') {
            errors.cartMaxError = "This value can not be less than 0";
        }
        if (Number(data.cartMin) < 0 && data.offerType === 'cart-limit') {
            errors.cartMinError = "This value can not be less than 0";
        }
        if (data.cartMin === "" && data.offerType === 'cart-limit') {
            errors.cartMinError = "This is required field";
        }
        if (data.cartMax === "" && data.offerType === 'cart-limit') {
            errors.cartMaxError = "This is required field";
        } else if (Number(data.cartMax) <= Number(data.cartMin) && data.offerType === 'cart-limit') {
            errors.cartMaxError = "This value can not be equal or less than cart min value";
        }
        if (data.offerTriggerType === 'discount-code' && (!data.discountCode || data.discountCode.trim() === '')) {
            errors.discountError = "This is required field";
        }
        if (requiredProduct.length === 0 && data.offerType === 'specific-product') {
            errors.productError = "This is required field";
        }
        if (!offerProduct && data.offerTriggerType === 'product') {
            errors.offerProductError = "This is required field";
        }
        if (!requiredCollection && data.offerType === 'collection-only') {
            errors.collectionError = "This is required field";
        }
        if (!data.offerButtonText || data.offerButtonText.trim() === '') {
            errors.offerButtonTextError = "This is required field";
        }
        if (!data.continueButtonText || data.continueButtonText.trim() === '') {
            errors.continueButtonTextError = "This is required field";
        }
        if (!data.offerButtonMobileText || data.offerButtonMobileText.trim() === '') {
            errors.offerButtonMobileTextError = "This is required field";
        }
        if (!data.continueButtonMobileText || data.continueButtonMobileText.trim() === '') {
            errors.continueButtonMobileTextError = "This is required field";
        }
        setInputErrors(errors);
        return errors;
    };

    const showInputError = () => {
        return (
            <RequiredError />
        );
    };

    const handleSubmit = () => {
        let checkValidation = handleValidation(fields);
        if (Object.keys(checkValidation).length === 0) {
            setLoading(true);
            let payload = getPayload(fields);
            payload.shop = shop;
            payload.triggerProduct_meta = requiredProduct;
            payload.triggerCollection_meta = requiredCollection;
            payload.offerProduct_meta = offerProduct;
            const url = id ? `popup-upsell/${id}` : `popup-upsell/create`;
            API.post(url, payload).then(response => {
                setLoading(false);
                setTooltip({
                    content: `Popup Upsell ${id ? 'Updated' : 'Created'} Successfully`,
                    active: true,
                    error: false
                });
                redirectPage('popup-upsells');
            }).catch(err => {
                setLoading(false);
                setTooltip({
                    content: err.response.data.message,
                    active: true,
                    error: true
                });
            });
        } else {
            setCollapseActive({
                upsellTriggerActive: true,
                upsellOfferActive: true
            });
            setTooltip({
                content: "Please fill required fields",
                active: true,
                error: true
            });
        }
    };

    const deleteOffer = () => {
        setDeleteLoading(true);
        API.delete(`popup-upsell/${id}`).then(response => {
            setDeleteLoading(false);
            setTooltip({
                content: "Popup Upsell Deleted Successfully",
                active: true,
                error: false
            });
            redirectPage('popup-upsells');
        }).catch(err => {
            setDeleteLoading(false);
            setTooltip({
                content: err.response.data.message,
                active: true,
                error: true
            });
        });
    };

    const handlePreviewMode = (mode) => {
        setPreviewMode(mode);
        handleModalChange();
    };

    const handleModalChange = useCallback(() => setPreview(!preview), [preview]);

    const handleTabIndex = (index) => {
        setTabIndex(index);
    };

    return (
        <div className="popup-manage">
            <TabHeader />
            <Page
                title={title}
                primaryAction={<BackButton disable={disableBackButton} url="popup-upsells" />}
            >
                <Form noValidate onSubmit={handleSubmit}>
                    <div className="separation-line"></div>
                    <TabSettings handleTabIndex={handleTabIndex}>
                        {tabIndex === 0 &&
                            <FormLayout>
                                <Heading>Basic Information</Heading>
                                <Card sectioned>
                                    <FormLayout>
                                        <TextField
                                            label="Give it a name"
                                            helpText="Your customers won't see this name. It's only for you to find it later. Maximum 64 characters"
                                            value={fields.name}
                                            maxLength={64}
                                            onChange={(e) => handleInputChange(e, 'name')}
                                            error={inputErrors.nameError}
                                        />
                                    </FormLayout>
                                </Card>
                                <Heading>Upsell offer flow</Heading>
                                <TextStyle variation="subdued">Design and buiLd the offer you want to provide to your customers.</TextStyle>
                                <div className="card-border-color">
                                    <Card>
                                        <Card.Section>
                                            <div className="up-trigger">
                                                <Stack alignment="left">
                                                    <Stack.Item fill>
                                                        <TextStyle variation="strong">Upsell trigger</TextStyle></Stack.Item>
                                                    <Stack.Item>
                                                        <Button secondary onClick={e => handleCollapseActive('upsellTriggerActive')}>Set a trigger</Button>
                                                    </Stack.Item>
                                                </Stack>
                                            </div>
                                        </Card.Section>
                                        <Collapsible
                                            open={collpaseActive.upsellTriggerActive}
                                            id="upsell-trigger-collapsible"
                                            transition={{ duration: '150ms', timingFunction: 'ease' }}
                                        >
                                            <div className="separation-line"></div>
                                            <Card.Section>
                                                <FormLayout>
                                                    <div className="upsell-trigger-wrapper">
                                                        <FormLayout.Group condensed>
                                                            <div className="up-trigger-types">
                                                                <ChoiceList
                                                                    choices={[
                                                                        { label: 'Add to Cart', value: 'add-to-cart' },
                                                                    ]}
                                                                    selected={fields.triggerType}
                                                                    onChange={(e) => handleInputChange(e, 'triggerType')}
                                                                />
                                                            </div>
                                                            <div className="up-trigger-types checkout-type">
                                                                <ChoiceList
                                                                    choices={[
                                                                        { label: 'Checkout', value: 'checkout' },
                                                                    ]}
                                                                    selected={fields.triggerType}
                                                                    onChange={(e) => handleInputChange(e, 'triggerType')}
                                                                />
                                                            </div>
                                                            <Select
                                                                label="Offer Type"
                                                                options={offerTypeOptions}
                                                                onChange={e => handleInputChange(e, 'offerType')}
                                                                value={fields.offerType}
                                                                disabled={id ? true : false}
                                                            />
                                                        </FormLayout.Group>
                                                    </div>
                                                    {fields.offerType === 'cart-limit' &&
                                                        <FormLayout.Group>
                                                            <TextField
                                                                type="number"
                                                                value={fields.cartMin}
                                                                label="Cart Min Value"
                                                                min={0}
                                                                onChange={(e) => handleInputChange(e, 'cartMin')}
                                                                error={inputErrors.cartMinError}
                                                            />
                                                            <TextField
                                                                type="number"
                                                                value={fields.cartMax}
                                                                label="Cart Max Value"
                                                                min={0}
                                                                onChange={(e) => handleInputChange(e, 'cartMax')}
                                                                error={inputErrors.cartMaxError}
                                                            />
                                                        </FormLayout.Group>
                                                    }
                                                    {fields.offerType === 'specific-product' &&
                                                        <FormLayout.Group>
                                                            <div className="recommendation-offer-products">
                                                                <Card title="Trigger Product">
                                                                    <div className="trigger-description">
                                                                        <TextStyle variation="subdued">You can restrict this offer to certain products. Without choosing products your upsell will happen every time the above event occurs.</TextStyle>
                                                                    </div>
                                                                    <ResourceList
                                                                        resourceName={{ singular: 'sale', plural: 'pname' }}
                                                                        items={requiredProduct}
                                                                        renderItem={(item) => {
                                                                            const { title, images, variants } = item;
                                                                            const image = images[0]?.originalSrc;
                                                                            const media = <Thumbnail
                                                                                source={image ? image : dummyImage}
                                                                                size="large"
                                                                                alt={title}
                                                                            />;
                                                                            return (
                                                                                <div className="myprdctlist">
                                                                                    { requiredProduct &&
                                                                                        <ResourceList.Item
                                                                                            media={media}
                                                                                            accessibilityLabel={`View pname for ${title}`}
                                                                                        >
                                                                                            <Stack>
                                                                                                <div className="pdesc">
                                                                                                    <Stack.Item fill>{title}</Stack.Item>
                                                                                                    <Stack.Item fill>{variants[0]?.title}</Stack.Item>
                                                                                                    <Stack.Item>{variants[0]?.sku}</Stack.Item>
                                                                                                </div>
                                                                                                <div className="ptamount">
                                                                                                    {variants[0]?.price && <Stack.Item>${variants[0]?.price}</Stack.Item>}
                                                                                                </div>
                                                                                            </Stack>
                                                                                        </ResourceList.Item>
                                                                                    }
                                                                                </div>
                                                                            );
                                                                        }}
                                                                    />

                                                                    <Button primary onClick={e => handleSelectedItemPicker('required-product')}>Select Product</Button>
                                                                    {inputErrors.productError &&
                                                                        showInputError()
                                                                    }
                                                                </Card>
                                                            </div>
                                                        </FormLayout.Group>
                                                    }
                                                    {fields.offerType === 'collection-only' &&
                                                        <FormLayout.Group>
                                                            <Card title="Trigger Collection">
                                                                <div className="trigger-description">
                                                                    <TextStyle variation="subdued">You can restrict this offer to certain collections. Without choosing products your upsell will happen every time the above event occurs.</TextStyle>
                                                                </div>
                                                                <ResourceList
                                                                    resourceName={{ singular: 'sale', plural: 'pname' }}
                                                                    items={[
                                                                        {
                                                                            title: requiredCollection?.title,
                                                                            image: requiredCollection?.image?.originalSrc
                                                                        }
                                                                    ]}
                                                                    renderItem={(item) => {
                                                                        const { title, image } = item;
                                                                        const media = <Thumbnail
                                                                            source={image ? image : dummyImage}
                                                                            size="large"
                                                                            alt={title}
                                                                        />;
                                                                        return (
                                                                            <div className="myprdctlist">
                                                                                { requiredCollection &&
                                                                                    <ResourceList.Item
                                                                                        media={media}
                                                                                        accessibilityLabel={`View pname for ${title}`}
                                                                                    >
                                                                                        <div className="pdesc">
                                                                                            <Stack.Item fill>{title}</Stack.Item>
                                                                                        </div>
                                                                                    </ResourceList.Item>
                                                                                }
                                                                                <Button primary onClick={e => handleSelectedItemPicker('required-collection')}>Select Collection</Button>
                                                                            </div>
                                                                        );
                                                                    }}
                                                                />
                                                                {inputErrors.collectionError &&
                                                                    showInputError()
                                                                }
                                                            </Card>
                                                        </FormLayout.Group>
                                                    }
                                                </FormLayout>
                                            </Card.Section>
                                        </Collapsible>
                                    </Card>
                                </div>
                                <div className="card-border-color m-t-30">
                                    <Card>
                                        <Card.Section>
                                            <div className="up-offer">
                                                <Stack alignment="left">
                                                    <Stack.Item fill>
                                                        <TextStyle variation="strong">Upsell offer</TextStyle></Stack.Item>
                                                    <Stack.Item>
                                                        <Button secondary onClick={e => handleCollapseActive('upsellOfferActive')}>Set an offer</Button>
                                                    </Stack.Item>
                                                </Stack>
                                            </div>
                                        </Card.Section>
                                        <Collapsible
                                            open={collpaseActive.upsellOfferActive}
                                            id="upsell-offer-collapsible"
                                            transition={{ duration: '150ms', timingFunction: 'ease' }}
                                        >
                                            <div className="separation-line"></div>
                                            <Card.Section title="Offer popup details">
                                                <FormLayout>
                                                    <FormLayout.Group>
                                                        <div className="up-trigger-types discount-type">
                                                            <ChoiceList
                                                                choices={[
                                                                    { label: 'Discount', value: 'discount-code' },
                                                                ]}
                                                                selected={fields.offerTriggerType}
                                                                onChange={(e) => handleInputChange(e, 'offerTriggerType')}
                                                            />
                                                        </div>
                                                        <div className="up-trigger-types product-type">
                                                            <ChoiceList
                                                                choices={[
                                                                    { label: 'Product', value: 'product' },
                                                                ]}
                                                                selected={fields.offerTriggerType}
                                                                onChange={(e) => handleInputChange(e, 'offerTriggerType')}
                                                            />
                                                        </div>
                                                    </FormLayout.Group>
                                                    <TextField
                                                        label="Title (Optional)"
                                                        value={fields.offerTitle}
                                                        onChange={(e) => handleInputChange(e, 'offerTitle')}
                                                        error={inputErrors.offerTitleError}
                                                    />
                                                    <TextField
                                                        label="Desktop Description (Optional)"
                                                        value={fields.offerDescriptionDesktop}
                                                        onChange={(e) => handleInputChange(e, 'offerDescriptionDesktop')}
                                                        multiline={2}
                                                    />
                                                    <TextField
                                                        label="Mobile Description (Optional)"
                                                        value={fields.offerDescriptionMobile}
                                                        onChange={(e) => handleInputChange(e, 'offerDescriptionMobile')}
                                                        multiline={2}
                                                    />
                                                    <FormLayout.Group>
                                                        <Select
                                                            label="Title Alignment"
                                                            options={textAlignmentOptions}
                                                            onChange={(e) => handleInputChange(e, 'titleTextAlign')}
                                                            value={fields.titleTextAlign}
                                                        />
                                                        <Select
                                                            label="Description Alignment"
                                                            options={textAlignmentOptions}
                                                            onChange={(e) => handleInputChange(e, 'descpTextAlign')}
                                                            value={fields.descpTextAlign}
                                                        />
                                                    </FormLayout.Group>
                                                    {fields.offerTriggerType === 'discount-code' &&
                                                        <TextField
                                                            label="Discount Code"
                                                            value={fields.discountCode}
                                                            onChange={(e) => handleInputChange(e, 'discountCode')}
                                                            error={inputErrors.discountError}
                                                        />
                                                    }
                                                </FormLayout>
                                                {fields.offerTriggerType === 'product' &&
                                                    <FormLayout>
                                                        <Checkbox
                                                            label="Show Product Price"
                                                            checked={fields.showProductPrice}
                                                            onChange={e => handleInputChange(e, 'showProductPrice')}
                                                        />
                                                        <Checkbox
                                                            label="Show Product Title"
                                                            checked={fields.showProductTitle}
                                                            onChange={e => handleInputChange(e, 'showProductTitle')}
                                                        />
                                                        <Card title="Offer Product">
                                                            <ResourceList
                                                                resourceName={{ singular: 'sale', plural: 'pname' }}
                                                                items={[
                                                                    {
                                                                        title: offerProduct?.title,
                                                                        amount: '$' + offerProduct?.variants[0]?.price,
                                                                        pcate: offerProduct?.variants[0]?.title,
                                                                        psku: offerProduct?.variants[0]?.sku,
                                                                        qty: 1,
                                                                        tamount: '$' + offerProduct?.variants[0]?.price,
                                                                    }
                                                                ]}
                                                                renderItem={(item) => {
                                                                    const { title, pcate, psku, tamount } = item;
                                                                    const image = offerProduct?.images[0]?.originalSrc;
                                                                    const media = <Thumbnail
                                                                        source={image ? image : dummyImage}
                                                                        size="large"
                                                                        alt={offerProduct?.title}
                                                                    />;
                                                                    return (
                                                                        <div className="myprdctlist">
                                                                            { offerProduct &&
                                                                                <ResourceList.Item
                                                                                    media={media}
                                                                                    accessibilityLabel={`View pname for ${title}`}
                                                                                >
                                                                                    <Stack>
                                                                                        <div className="pdesc">
                                                                                            <Stack.Item fill>{title}</Stack.Item>
                                                                                            <Stack.Item fill>{pcate}</Stack.Item>
                                                                                            <Stack.Item>{psku}</Stack.Item>
                                                                                        </div>
                                                                                        <div className="ptamount">
                                                                                            <Stack.Item>{tamount}</Stack.Item>
                                                                                        </div>
                                                                                    </Stack>
                                                                                </ResourceList.Item>
                                                                            }
                                                                            <Button primary onClick={e => handleSelectedItemPicker('offer-product')}>Select Product</Button>
                                                                        </div>
                                                                    );
                                                                }}
                                                            />
                                                            {inputErrors.offerProductError &&
                                                                showInputError()
                                                            }
                                                        </Card>
                                                    </FormLayout>
                                                }
                                            </Card.Section>
                                        </Collapsible>
                                    </Card>
                                </div>
                            </FormLayout>
                        }
                        {tabIndex === 1 &&
                            <FormLayout>
                                <Subheading>Title Settings:</Subheading>
                                <FormLayout.Group>
                                    <TextField
                                        prefix="#"
                                        label="Offer Title Color"
                                        onChange={e => handleColorChange(e, 'offerTitleColor')}
                                        value={colorWithoutHash(fields.offerTitleColor)}
                                        suffix={<ColorChoser
                                            field="offerTitleColor"
                                            handleColorInput={handleInputChange}
                                            color={fields.offerTitleColor}
                                        />
                                        }
                                    />
                                    <FontFamilyPicker
                                        pickerId="pcTitleFont"
                                        label="Offer Title Font Family"
                                        activeFont={fields.offerTitleFontFamily}
                                        field="offerTitleFontFamily"
                                        handleFontFamilyInput={handleInputChange}
                                    />
                                    <RangeSlider
                                        output
                                        label="Offer Title Font size"
                                        min={10}
                                        max={30}
                                        value={fields.offerTitleFontSize}
                                        onChange={(e) => handleInputChange(e, 'offerTitleFontSize')}
                                        prefix={<p>10px</p>}
                                        suffix={<p>30px </p>}
                                    />
                                </FormLayout.Group>
                                <Subheading>Description Settings:</Subheading>
                                <FormLayout.Group>
                                    <TextField
                                        prefix="#"
                                        label="Offer Description Color"
                                        onChange={e => handleColorChange(e, 'offerDescriptionColor')}
                                        value={colorWithoutHash(fields.offerDescriptionColor)}
                                        suffix={<ColorChoser
                                            field="offerDescriptionColor"
                                            handleColorInput={handleInputChange}
                                            color={fields.offerDescriptionColor}
                                        />
                                        }
                                    />
                                    <FontFamilyPicker
                                        pickerId="pcDespFont"
                                        label="Offer Description Font Family"
                                        activeFont={fields.offerDescriptionFontFamily}
                                        field="offerDescriptionFontFamily"
                                        handleFontFamilyInput={handleInputChange}
                                    />
                                    <RangeSlider
                                        output
                                        label="Offer Description Font size"
                                        min={10}
                                        max={30}
                                        value={fields.offerDescriptionFontSize}
                                        onChange={(e) => handleInputChange(e, 'offerDescriptionFontSize')}
                                        prefix={<p>10px</p>}
                                        suffix={<p>30px </p>}
                                    />
                                </FormLayout.Group>
                                <Subheading>Button Settings:</Subheading>
                                <FormLayout.Group>
                                    <TextField
                                        label="Offer Button Text"
                                        value={fields.offerButtonText}
                                        onChange={(e) => handleInputChange(e, 'offerButtonText')}
                                        maxLength={50}
                                        error={inputErrors.offerButtonTextError}
                                    />
                                    <FontFamilyPicker
                                        pickerId="pcBtnFont"
                                        label="Offer Button Font Family"
                                        activeFont={fields.offerButtonFontFamily}
                                        field="offerButtonFontFamily"
                                        handleFontFamilyInput={handleInputChange}
                                    />
                                    <RangeSlider
                                        output
                                        label="Offer Button Font size"
                                        min={10}
                                        max={30}
                                        value={fields.offerButtonFontSize}
                                        onChange={(e) => handleInputChange(e, 'offerButtonFontSize')}
                                        prefix={<p>10px</p>}
                                        suffix={<p>30px </p>}
                                    />
                                </FormLayout.Group>
                                <FormLayout.Group>
                                    <TextField
                                        prefix="#"
                                        label="Offer Button Text Color"
                                        onChange={e => handleColorChange(e, 'offerButtonTextColor')}
                                        value={colorWithoutHash(fields.offerButtonTextColor)}
                                        suffix={<ColorChoser
                                            field="offerButtonTextColor"
                                            handleColorInput={handleInputChange}
                                            color={fields.offerButtonTextColor}
                                        />
                                        }
                                    />
                                    <TextField
                                        prefix="#"
                                        label="Offer Button Color"
                                        onChange={e => handleColorChange(e, 'offerButtonColor')}
                                        value={colorWithoutHash(fields.offerButtonColor)}
                                        suffix={<ColorChoser
                                            field="offerButtonColor"
                                            handleColorInput={handleInputChange}
                                            color={fields.offerButtonColor}
                                        />
                                        }
                                    />
                                    <></>
                                </FormLayout.Group>
                                <Subheading>Continue Button Settings:</Subheading>
                                <FormLayout.Group>
                                    <TextField
                                        label="Continue Button Text"
                                        onChange={e => handleInputChange(e, 'continueButtonText')}
                                        value={fields.continueButtonText}
                                        maxLength={50}
                                        error={inputErrors.continueButtonTextError}
                                    />
                                    <FontFamilyPicker
                                        pickerId="pcFinalBtnFont"
                                        label="Continue Button Font Family"
                                        activeFont={fields.continueButtonFontFamily}
                                        field="continueButtonFontFamily"
                                        handleFontFamilyInput={handleInputChange}
                                    />
                                    <RangeSlider
                                        output
                                        label="Continue Button Font size"
                                        min={10}
                                        max={30}
                                        value={fields.continueButtonFontSize}
                                        onChange={(e) => handleInputChange(e, 'continueButtonFontSize')}
                                        prefix={<p>10px</p>}
                                        suffix={<p>30px </p>}
                                    />
                                </FormLayout.Group>
                                <FormLayout.Group>
                                    <TextField
                                        prefix="#"
                                        label="Continue Button Text Color"
                                        onChange={e => handleColorChange(e, 'continueButtonTextColor')}
                                        value={colorWithoutHash(fields.continueButtonTextColor)}
                                        suffix={<ColorChoser
                                            field="continueButtonTextColor"
                                            handleColorInput={handleInputChange}
                                            color={fields.continueButtonTextColor}
                                        />
                                        }
                                    />
                                    <TextField
                                        prefix="#"
                                        label="Continue Button Color"
                                        onChange={e => handleColorChange(e, 'conitnueButtonColor')}
                                        value={colorWithoutHash(fields.conitnueButtonColor)}
                                        suffix={<ColorChoser
                                            field="conitnueButtonColor"
                                            handleColorInput={handleInputChange}
                                            color={fields.conitnueButtonColor}
                                        />
                                        }
                                    />
                                    <></>
                                </FormLayout.Group>
                                <Checkbox
                                    label="Continue Button Box Shadow"
                                    checked={fields.continueShadow}
                                    onChange={e => handleInputChange(e, 'continueShadow')}
                                />
                            </FormLayout>
                        }
                        {tabIndex === 2 &&
                            <FormLayout>
                                <Subheading>Title Settings:</Subheading>
                                <FormLayout.Group>
                                    <TextField
                                        prefix="#"
                                        label="Offer Title Color"
                                        onChange={e => handleColorChange(e, 'offerTitleMobileColor')}
                                        value={colorWithoutHash(fields.offerTitleMobileColor)}
                                        suffix={<ColorChoser
                                            field="offerTitleMobileColor"
                                            handleColorInput={handleInputChange}
                                            color={fields.offerTitleMobileColor}
                                        />
                                        }
                                    />
                                    <FontFamilyPicker
                                        pickerId="mobileTitleFont"
                                        label="Offer Title Font Family"
                                        activeFont={fields.offerTitleMobileFontFamily}
                                        field="offerTitleMobileFontFamily"
                                        handleFontFamilyInput={handleInputChange}
                                    />
                                    <RangeSlider
                                        output
                                        label="Offer Title Font size"
                                        min={10}
                                        max={30}
                                        value={fields.offerTitleMobileFontSize}
                                        onChange={(e) => handleInputChange(e, 'offerTitleMobileFontSize')}
                                        prefix={<p>10px</p>}
                                        suffix={<p>30px </p>}
                                    />
                                </FormLayout.Group>
                                <Subheading>Description Settings:</Subheading>
                                <FormLayout.Group>
                                    <TextField
                                        prefix="#"
                                        label="Offer Description Color"
                                        onChange={e => handleColorChange(e, 'offerDescriptionMobileColor')}
                                        value={colorWithoutHash(fields.offerDescriptionMobileColor)}
                                        suffix={<ColorChoser
                                            field="offerDescriptionMobileColor"
                                            handleColorInput={handleInputChange}
                                            color={fields.offerDescriptionMobileColor}
                                        />
                                        }
                                    />
                                    <FontFamilyPicker
                                        pickerId="mobileDespFont"
                                        label="Offer Description Font Family"
                                        activeFont={fields.offerDescriptionMobileFontFamily}
                                        field="offerDescriptionMobileFontFamily"
                                        handleFontFamilyInput={handleInputChange}
                                    />
                                    <RangeSlider
                                        output
                                        label="Offer Description Font size"
                                        min={10}
                                        max={30}
                                        value={fields.offerDescriptionMobileFontSize}
                                        onChange={(e) => handleInputChange(e, 'offerDescriptionMobileFontSize')}
                                        prefix={<p>10px</p>}
                                        suffix={<p>30px </p>}
                                    />
                                </FormLayout.Group>
                                <Subheading>Button Settings:</Subheading>
                                <FormLayout.Group>
                                    <TextField
                                        label="Offer Button Text"
                                        value={fields.offerButtonMobileText}
                                        onChange={(e) => handleInputChange(e, 'offerButtonMobileText')}
                                        maxLength={50}
                                        error={inputErrors.offerButtonMobileTextError}
                                    />
                                    <FontFamilyPicker
                                        pickerId="mobileBtnFont"
                                        label="Offer Button Font Family"
                                        activeFont={fields.offerButtonMobileFontFamily}
                                        field="offerButtonMobileFontFamily"
                                        handleFontFamilyInput={handleInputChange}
                                    />
                                    <RangeSlider
                                        output
                                        label="Offer Button Font size"
                                        min={10}
                                        max={30}
                                        value={fields.offerButtonMobileFontSize}
                                        onChange={(e) => handleInputChange(e, 'offerButtonMobileFontSize')}
                                        prefix={<p>10px</p>}
                                        suffix={<p>30px </p>}
                                    />
                                </FormLayout.Group>
                                <FormLayout.Group>
                                    <TextField
                                        prefix="#"
                                        label="Offer Button Text Color"
                                        onChange={e => handleColorChange(e, 'offerButtonMobileTextColor')}
                                        value={colorWithoutHash(fields.offerButtonMobileTextColor)}
                                        suffix={<ColorChoser
                                            field="offerButtonMobileTextColor"
                                            handleColorInput={handleInputChange}
                                            color={fields.offerButtonMobileTextColor}
                                        />
                                        }
                                    />
                                    <TextField
                                        prefix="#"
                                        label="Offer Button Color"
                                        onChange={e => handleColorChange(e, 'offerButtonMobileColor')}
                                        value={colorWithoutHash(fields.offerButtonMobileColor)}
                                        suffix={<ColorChoser
                                            field="offerButtonMobileColor"
                                            handleColorInput={handleInputChange}
                                            color={fields.offerButtonMobileColor}
                                        />
                                        }
                                    />
                                    <></>
                                </FormLayout.Group>
                                <Subheading>Continue Button Settings:</Subheading>
                                <FormLayout.Group>
                                    <TextField
                                        label="Continue Button Text"
                                        onChange={e => handleInputChange(e, 'continueButtonMobileText')}
                                        value={fields.continueButtonMobileText}
                                        maxLength={50}
                                        error={inputErrors.continueButtonMobileTextError}
                                    />
                                    <FontFamilyPicker
                                        pickerId="mobileFinalBtnFont"
                                        label="Continue Button Font Family"
                                        activeFont={fields.continueButtonMobileFontFamily}
                                        field="continueButtonMobileFontFamily"
                                        handleFontFamilyInput={handleInputChange}
                                    />
                                    <RangeSlider
                                        output
                                        label="Continue Button Font size"
                                        min={10}
                                        max={30}
                                        value={fields.continueButtonMobileFontSize}
                                        onChange={(e) => handleInputChange(e, 'continueButtonMobileFontSize')}
                                        prefix={<p>10px</p>}
                                        suffix={<p>30px </p>}
                                    />
                                </FormLayout.Group>
                                <FormLayout.Group>
                                    <TextField
                                        prefix="#"
                                        label="Continue Button Text Color"
                                        onChange={e => handleColorChange(e, 'continueButtonMobileTextColor')}
                                        value={colorWithoutHash(fields.continueButtonMobileTextColor)}
                                        suffix={<ColorChoser
                                            field="continueButtonMobileTextColor"
                                            handleColorInput={handleInputChange}
                                            color={fields.continueButtonMobileTextColor}
                                        />
                                        }
                                    />
                                    <TextField
                                        prefix="#"
                                        label="Continue Button Color"
                                        onChange={e => handleColorChange(e, 'conitnueButtonMobileColor')}
                                        value={colorWithoutHash(fields.conitnueButtonMobileColor)}
                                        suffix={<ColorChoser
                                            field="conitnueButtonMobileColor"
                                            handleColorInput={handleInputChange}
                                            color={fields.conitnueButtonMobileColor}
                                        />
                                        }
                                    />
                                    <></>
                                </FormLayout.Group>
                                <Checkbox
                                    label="Continue Button Box Shadow"
                                    checked={fields.continueShadowMobile}
                                    onChange={e => handleInputChange(e, 'continueShadowMobile')}
                                />
                            </FormLayout>
                        }
                        {tabIndex === 3 &&
                            <FormLayout>
                                <Checkbox
                                    label="Enable Custom CSS"
                                    checked={fields.enableCustomCss}
                                    onChange={e => handleInputChange(e, 'enableCustomCss')}
                                />
                                <TextField
                                    label="Custom CSS"
                                    value={fields.customCss}
                                    onChange={(e) => handleInputChange(e, 'customCss')}
                                    multiline={6}
                                    disabled={fields.enableCustomCss ? false : true}
                                />
                                <CssHelper />
                            </FormLayout>
                        }
                    </TabSettings>
                    <div className="button_wrap">
                        <Button primary submit loading={isLoading}>Save</Button>
                        {id && <Button destructive onClick={deleteOffer} loading={deleteLoading}>Delete</Button>}
                        <Button onClick={e => handlePreviewMode('desktop')}>Preview (Desktop)</Button>
                        <Button onClick={e => handlePreviewMode('mobile')}>Preview (Mobile)</Button>
                    </div>
                    <PreviewModal
                        open={preview}
                        handleModalChange={handleModalChange}
                        mode={previewMode}
                        fields={fields}
                        product={offerProduct}
                    />
                    {shop &&
                        <>
                            {selectedItem === 'required-collection' ? (
                                <ResourcePicker
                                    resourceType="Collection"
                                    open={openPicker}
                                    allowMultiple={false}
                                    onSelection={(resources) => handleItemSelection(resources)}
                                    onCancel={() => setOpenPicker(false)}
                                    initialSelectionIds={getSelectedIds()}
                                />
                            ) : (
                                <ItemPicker
                                    resourceType="Product"
                                    allowMultiple={selectedItem === 'required-product' ? 8 : false}
                                    openPicker={openPicker}
                                    handleItemSelection={handleItemSelection}
                                    setOpenPicker={setOpenPicker}
                                    selectedItem={getSelectedIds()}
                                />
                            )}
                        </>
                    }
                    {/* Just added all fonts here so that all fonts can be loaded for preview */}
                    <div style={{ display: 'none' }}>
                        <FontFamilyPicker
                            pickerId="pcTitleFont"
                            label="Offer Title Font Family"
                            activeFont={fields.offerTitleFontFamily}
                            field="offerTitleFontFamily"
                            handleFontFamilyInput={handleInputChange}
                        />
                        <FontFamilyPicker
                            pickerId="pcDespFont"
                            label="Offer Description Font Family"
                            activeFont={fields.offerDescriptionFontFamily}
                            field="offerDescriptionFontFamily"
                            handleFontFamilyInput={handleInputChange}
                        />

                        <FontFamilyPicker
                            pickerId="pcBtnFont"
                            label="Offer Button Font Family"
                            activeFont={fields.offerButtonFontFamily}
                            field="offerButtonFontFamily"
                            handleFontFamilyInput={handleInputChange}
                        />

                        <FontFamilyPicker
                            pickerId="pcFinalBtnFont"
                            label="Continue Button Font Family"
                            activeFont={fields.continueButtonFontFamily}
                            field="continueButtonFontFamily"
                            handleFontFamilyInput={handleInputChange}
                        />
                        <FontFamilyPicker
                            pickerId="mobileTitleFont"
                            label="Offer Title Font Family"
                            activeFont={fields.offerTitleMobileFontFamily}
                            field="offerTitleMobileFontFamily"
                            handleFontFamilyInput={handleInputChange}
                        />
                        <FontFamilyPicker
                            pickerId="mobileDespFont"
                            label="Offer Description Font Family"
                            activeFont={fields.offerDescriptionMobileFontFamily}
                            field="offerDescriptionMobileFontFamily"
                            handleFontFamilyInput={handleInputChange}
                        />
                        <FontFamilyPicker
                            pickerId="mobileBtnFont"
                            label="Offer Button Font Family"
                            activeFont={fields.offerButtonMobileFontFamily}
                            field="offerButtonMobileFontFamily"
                            handleFontFamilyInput={handleInputChange}
                        />
                        <FontFamilyPicker
                            pickerId="mobileFinalBtnFont"
                            label="Continue Button Font Family"
                            activeFont={fields.continueButtonMobileFontFamily}
                            field="continueButtonMobileFontFamily"
                            handleFontFamilyInput={handleInputChange}
                        />
                    </div>
                </Form>
                <Tooltip tooltip={toolTip} />
            </Page>
        </div>
    );
}



